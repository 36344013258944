import React, {useEffect, useState} from 'react';
import Switch from 'react-switch';
import classNames from 'classnames';
import {observer} from 'mobx-react';
import {useNavigate} from 'react-router-dom';
import styles from './styles.module.scss';
import {useStores} from '../../../../stores';
import {getBalanceString, roundingBalance} from '../../../../utils/formats';
import HeaderCards from '../common/HeaderCards';
import {TAsset} from '../../../../services/AssetsService';
import StarComponent from './StarComponent';
import {EVENT_NAMES, useAnalytics} from '../../../../services/Analytics';
import {APP_ROUTES} from '../../../Router/constants';
import {Pages} from '../../../Router/types';
import {themeGlobalColor} from '../../../../globalConstants';
import {MODAL_TYPES} from '../../Modals/modalConstants';
import {useGlobalModalContext} from '../../Modals';

const Portfolio: React.FC = () => {
  const {assets, currency, ratesCeFi} = useStores();
  const {myLogEvent} = useAnalytics();
  const navigate = useNavigate();
  const {showModal} = useGlobalModalContext();

  const [isHideZeroBalance, setIsHideZeroBalance] = useState(false);

  useEffect(() => {
    myLogEvent(EVENT_NAMES.WEB_PORTFOLIO_OPENED);
  }, []);

  const assetsList = [
    ...assets.filter(asset => asset.id === 'BBANK'),
    ...assets
      .filter(asset => asset.isFavourite && asset.id !== 'BBANK')
      .sort((a, b) => +b.balance.availableCash - +a.balance.availableCash),
    ...assets
      .filter(asset => !asset.isFavourite && asset.id !== 'BBANK')
      .sort((a, b) => +b.balance.availableCash - +a.balance.availableCash),
  ];

  const goToAssetPage = (asset: TAsset): void => {
    navigate(`${APP_ROUTES[Pages.ASSET]}/${asset.assetId}`);
  };

  const goToModal = (asset: TAsset, type: string): void => {
    const options = {
      asset,
      title: Pages.PORTFOLIO,
    };
    showModal(type, options);
  };

  return (
    <div className={styles.portfolioWrap}>
      <HeaderCards headerTitle='Portfolio' />

      <div className={styles.tableTitleRow}>
        <div className={styles.tableTitle}>Assets</div>

        <div className={styles.tableHideZeroWrap}>
          <div className={styles.tableHideZero}>Hide Zero Balances</div>
          <Switch
            uncheckedIcon={false}
            checkedIcon={false}
            offColor='#DADDE3'
            onColor={themeGlobalColor()}
            offHandleColor='#445374'
            onHandleColor='#FFFFFF'
            height={23}
            width={42}
            handleDiameter={19}
            checked={isHideZeroBalance}
            onChange={() => setIsHideZeroBalance(prev => !prev)}
          />
        </div>
      </div>

      <div className={styles.headerWrap}>
        <div className={classNames(styles.row, styles.header)}>
          <div className={classNames(styles.cell)}>Name</div>
          {/* <div className={styles.cell}>Tag</div> */}
          <div className={classNames(styles.cell)}>Price</div>
          <div className={styles.cell}>Amount</div>
          <div className={classNames(styles.cell)}>Value</div>
          {/* <div className={styles.cell} /> */}
          <div className={classNames(styles.cell, styles.cell2)}>Actions</div>
        </div>
      </div>

      <div className={styles.tableContainer}>
        <div className={styles.table}>
          {assetsList
            .filter(asset => !asset.isFiat)
            .filter(asset => {
              if (isHideZeroBalance) {
                return +asset.availableBalance > 0;
              }
              return true;
            })
            .map(asset => {
              const tradeEnabled = ratesCeFi.filter(assetItem => {
                if (assetItem.toAssetId === asset.assetId) return true;
                if (assetItem.fromAssetId === asset.assetId) return true;
                return false;
              });
              return (
                <div key={asset.assetId} className={styles.row} onClick={() => goToAssetPage(asset)}>
                  <div className={classNames(styles.cell)}>
                    <img className={styles.assetIcon} src={asset.icon} alt='' />
                    <div className={styles.assetName}>
                      <div className={styles.assetTitle}>{asset.assetName}</div>
                      <div className={styles.assetSymbol}>{asset.assetId}</div>
                    </div>
                  </div>
                  <div className={classNames(styles.cell, styles.assetPrice, styles.hideCell)}>
                    <div>
                      {currency}
                      {asset.rate?.value || 0}
                    </div>
                    <div
                      className={styles.assetChangeRateWrap}
                      style={{
                        color: asset.rate.change24Percent.toString()[0] === '-' ? '#FF0000' : '#2B9A18',
                        backgroundColor: asset.rate.change24Percent.toString()[0] === '-' ? '#FFCCCC' : '#D5EBD1',
                      }}
                    >
                      {asset.rate.change24Percent.toString()[0] === '-' ? '' : '+'}
                      {asset.rate?.change24Percent === 0 || asset.rate?.change24Percent
                        ? getBalanceString(+asset.rate.change24Percent, 2)
                        : '--'}
                      %
                    </div>
                  </div>
                  <div className={classNames(styles.cell, styles.hideCell)}>
                    {getBalanceString(+asset.availableBalance, 8)}
                  </div>
                  <div className={classNames(styles.cell, styles.assetPrice, styles.hideCell)}>
                    <div>
                      {currency}
                      {getBalanceString(
                        +roundingBalance((+asset.availableBalance * asset.rate.value).toString(), 8),
                        4
                      )}
                    </div>
                  </div>
                  <div className={classNames(styles.cell, styles.cell2, styles.hideCell)}>
                    <div
                      className={styles.actionBtn}
                      style={asset.depositEnabled ? {} : {cursor: 'default', opacity: 0.2}}
                      onClick={event => {
                        event.stopPropagation();
                        if (!asset.depositEnabled) return;
                        goToModal(asset, MODAL_TYPES.DEPOSIT_ASSET);
                      }}
                    >
                      Deposit
                    </div>
                    <div
                      style={asset.withdrawalEnabled ? {} : {cursor: 'default', opacity: 0.2}}
                      onClick={event => {
                        event.stopPropagation();
                        if (!asset.withdrawalEnabled) return;
                        goToModal(asset, MODAL_TYPES.WITHDRAW_ASSET);
                      }}
                      className={styles.actionBtn}
                    >
                      Withdraw
                    </div>
                    <div
                      style={tradeEnabled?.length ? {} : {cursor: 'default', opacity: 0.2}}
                      onClick={event => {
                        event.stopPropagation();
                        if (!tradeEnabled?.length) return;
                        goToModal(asset, MODAL_TYPES.TRADE_ASSET);
                      }}
                      className={styles.actionBtn}
                    >
                      Trade
                    </div>

                    <StarComponent asset={asset} />
                  </div>

                  <div className={styles.mobileAmount}>
                    <div className={styles.mobAvailableBalance}>{getBalanceString(+asset.availableBalance, 8)}</div>
                    <div style={{display: 'flex'}}>
                      <div
                        style={{
                          color: asset.rate.change24Percent.toString()[0] === '-' ? '#FF0000' : '#2B9A18',
                        }}
                      >
                        {asset.rate.change24Percent.toString()[0] === '-' ? '' : '+'}
                        {asset.rate?.change24Percent === 0 || asset.rate?.change24Percent
                          ? getBalanceString(+asset.rate.change24Percent, 2)
                          : '--'}
                        %
                      </div>
                      <div style={{width: 8}} />
                      <div style={{opacity: 0.5}}>
                        {currency}
                        {asset.rate?.value ? getBalanceString(asset.rate.value * +asset.availableBalance, 8) : '--'}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default observer(Portfolio);
