import React, {useEffect, useRef} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Chart from 'chart.js/auto';
import moment from 'moment';
import {useStores} from '../../../../stores';
import {themeGlobalColor} from '../../../../globalConstants';

const PriceChart: React.FC<any> = ({data}) => {
  const chartRef = useRef<any>(null);
  const {currency} = useStores();

  const isMobile = window.matchMedia('(max-width: 767px)').matches;

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');

      // HH:mm:ss
      let formatDate = 'YYYY-MM-DD';

      const startDate = moment.unix(data[0][0] / 1000);
      const endDate = moment.unix(data[data.length - 1][0] / 1000);
      const duration = moment.duration(endDate.diff(startDate));
      const days = duration.asDays();

      if (days <= 1) formatDate = 'HH:mm:ss';

      // eslint-disable-next-line no-new
      new Chart(ctx, {
        type: 'line',
        data: {
          labels: data.map((item: any[]) => moment.unix(item[0] / 1000).format(formatDate)),
          datasets: [
            {
              label: `${currency} price`,
              data: data.map((item: any[]) => item[1]),
              // backgroundColor: 'rgba(75, 192, 192, 0.2)',
              borderColor: themeGlobalColor(),
              borderWidth: 3,

              parsing: {
                yAxisKey: 'price',
              },
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              // borderColor: 'yellow',
              fill: 'start',
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              display: !isMobile,
              grid: {
                display: false,
              },
            },
            x: {
              display: false,
              grid: {
                display: false,
              },
            },
          },
          elements: {
            point: {
              radius: 1,
            },
          },
        },
      });
    }
  }, [data]);

  return <canvas width='900' ref={chartRef} />;
};

export default PriceChart;
