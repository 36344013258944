import React, {useState} from 'react';
import classNames from 'classnames';
import {useNavigate} from 'react-router-dom';
import styles from './styles.module.scss';
import close from '../../../../assets/icons/close.svg';
import SummaryBlockStake from '../CommonModalComponents/SummaryBlockStake';
import dangerOrange from '../../../../assets/icons/danger-orange.svg';
import {StakingServices} from '../../../../services/StakingService';
import {stakingContracts} from '../../../../stores/actions/stacking';
import {EVENT_NAMES, useAnalytics} from '../../../../services/Analytics';
import {Currencies, useStores} from '../../../../stores';
import {useGlobalModalContext} from '../index';
import RequestError from '../CommonModalComponents/RequestError';
import EarningSuccessfully from '../CommonModalComponents/EarningSuccessfully';

const LockBbankModal: React.FC = () => {
  const {stackingList, stakingPlans, currency, ratesRaw} = useStores();
  const {myLogEvent} = useAnalytics();

  const {hideModal, store} = useGlobalModalContext();
  const {amount, currentStakingApyPercent, maxOtherApiPercent} = store.modalProps;

  const [requestError, setRequestError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);

  const currencySymbol = currency === Currencies.USD ? 'USD' : 'EUR';
  const assetRate = ratesRaw.find(
    assetRateRaw => assetRateRaw.fromAssetId === 'BBANK' && assetRateRaw.toAssetId === currencySymbol
  );

  const bbankStakeAmount = stackingList
    .filter(stake => stake.assetId === 'BBANK')
    .reduce((acc, stake) => {
      return acc + +stake.amount;
    }, 0);

  const rollingLeveledPlans = stakingPlans.rollingLeveledPlans
    .slice()
    .sort((a, b) => +b.minimalTargetPlanAmount - +a.minimalTargetPlanAmount);
  const targetPlan = rollingLeveledPlans.find(plan => bbankStakeAmount + +amount >= +plan.minimalPlanAmount);
  const oldPlan = stackingList.find(stack => stack.isRollingLeveled);
  const oldPlanData = rollingLeveledPlans.find(plan => plan.id === oldPlan?.planId);

  const planIdForRequest =
    oldPlanData && targetPlan && +oldPlanData.minimalTargetPlanAmount >= +targetPlan.minimalTargetPlanAmount
      ? oldPlanData
      : targetPlan;

  const handleBBankStack = async (): Promise<void> => {
    setIsLoading(true);
    try {
      if (planIdForRequest?.id) {
        await StakingServices.rollingCreate({amount: +amount, planId: planIdForRequest.id});
        await stakingContracts();
        myLogEvent(EVENT_NAMES.WEB_EARN_BBANK, {amount});
        setIsSuccessful(true);
      } else {
        throw new Error('Not plan');
      }
    } catch (error: any) {
      console.log('ERROR-handleBankStack', error);
      setRequestError(error.code || error.message);
    }
    setIsLoading(false);
  };

  if (isSuccessful) {
    return <EarningSuccessfully text='Earning Successfully Setup' />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.closeWrap} onClick={hideModal}>
        <img className={styles.closeIcon} alt='' src={close} />
      </div>
      <div className={styles.title}>Lock BBANK</div>
      <div className={styles.content}>
        <SummaryBlockStake
          amount={amount}
          assetRate={assetRate}
          targetPlan={planIdForRequest}
          currentStakingApyPercent={currentStakingApyPercent}
          maxOtherApiPercent={maxOtherApiPercent}
        />
        <div className={styles.attentionText}>
          <img style={{height: 24, marginRight: 24}} alt='' src={dangerOrange} />
          <div>
            Locking additional BBANK tokens will accumulate in one group and reset the duration. Updated Tier Level will
            be assigned automatically.
          </div>
        </div>
        <button onClick={handleBBankStack} className={classNames('btn', 'btn-primary', styles.btnMobile)}>
          {isLoading ? <span className='spinner-border' /> : 'Confirm'}
        </button>

        {requestError ? <RequestError requestError={requestError} /> : null}
      </div>
    </div>
  );
};

export default LockBbankModal;
