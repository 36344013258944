import React, {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import classNames from 'classnames';
import styles from '../styles.module.scss';
import {ExchangeInputs} from '../Exchange/Exchange';
import AssetsDropdown from '../../CommonModalComponents/AssetsDropdown';
import AmountInput from '../AmountInput';
import {Currencies, useStores} from '../../../../../stores';
import {getBalanceString, roundingBalance} from '../../../../../utils/formats';
import {AssetsServices, ExchangeInfo} from '../../../../../services/AssetsService';
import TradeSummaryInfo from '../TradeSummaryInfo';
import Successfully from '../Successfull';
import {initApp} from '../../../../../stores/actions/init';
import {EVENT_NAMES, useAnalytics} from '../../../../../services/Analytics';
import RequestError from '../../CommonModalComponents/RequestError';

const defaultValues = {
  toAmountCrypto: '',
  toAmountCurrency: '',
};

type Props = {
  asset: any;
};

const Buy: React.FC<Props> = ({asset}) => {
  const {assets, ratesCeFi} = useStores();
  const {myLogEvent} = useAnalytics();

  const methods = useForm<ExchangeInputs>({defaultValues});
  const {
    formState: {errors},
    watch,
    setValue,
    clearErrors,
    setError,
    reset,
  } = methods;

  const eurData = assets.find(assetItem => assetItem.assetId === 'EUR') || {totalBalance: 0};

  const [selectedFromAsset, setSelectedFromAsset] = useState(asset);
  const [isCurrency, setIsCurrency] = useState(false);
  const [focusName, setFocusName] = useState('');
  const [requestError, setRequestError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [exchangeInfo, setExchangeInfo] = useState<ExchangeInfo | null>(null);
  const [isSuccessfully, setIsSuccessfully] = useState(false);

  const watchToAmountCrypto = watch('toAmountCrypto');
  const watchToAmountCurrency = watch('toAmountCurrency');

  useEffect(() => {
    reset();
    setIsCurrency(false);
  }, [selectedFromAsset]);

  const changeAmountCurrency = (): void => {
    setIsCurrency(prev => !prev);
  };

  const eurAssetRate = ratesCeFi.find(
    rate => rate.fromAssetId === 'EUR' && rate.toAssetId === selectedFromAsset.assetId
  );
  const eurAssetsList = ratesCeFi.filter(rate => rate.fromAssetId === 'EUR').map(assetItem => assetItem.toAssetId);

  const handleMax = (): void => {
    clearErrors();
    setValue('toAmountCrypto', roundingBalance((eurData.totalBalance * (eurAssetRate?.rate || 0)).toString(), 8));
    setValue('toAmountCurrency', roundingBalance(eurData.totalBalance.toString(), 2));
  };

  useEffect(() => {
    if (focusName === 'toAmountCrypto') {
      setValue(
        'toAmountCurrency',
        roundingBalance((+watchToAmountCrypto * (eurAssetRate ? 1 / eurAssetRate.rate : 0)).toString(), 2)
      );
      if (eurData.totalBalance * (eurAssetRate?.rate || 0) < +watchToAmountCrypto) {
        setError('toAmountCrypto', {type: 'manual', message: 'ERROR-Crypto'});
      } else clearErrors();
    }
    if (focusName === 'toAmountCurrency') {
      setValue(
        'toAmountCrypto',
        roundingBalance((+watchToAmountCurrency / (eurAssetRate ? 1 / eurAssetRate.rate : 0)).toString(), 8)
      );
      if (+eurData.totalBalance < +watchToAmountCurrency) {
        setError('toAmountCrypto', {type: 'manual', message: 'ERROR'});
      } else clearErrors();
    }

    setRequestError('');
  }, [watchToAmountCurrency, watchToAmountCrypto]);

  const handleExchange = async (): Promise<void> => {
    setIsLoading(true);

    try {
      if (exchangeInfo) {
        await AssetsServices.exchangeAsset({
          amount: +watchToAmountCrypto / (eurAssetRate?.rate || 1),
          info: exchangeInfo as ExchangeInfo,
        });

        myLogEvent(EVENT_NAMES.WEB_EXCHANGE, {...exchangeInfo});

        await initApp();

        setIsSuccessfully(true);
        setRequestError('');
      } else {
        const exchangeInfoData = await AssetsServices.exchangeInfoAsset({
          amount: +watchToAmountCrypto / (eurAssetRate?.rate || 1),
          from: 'EUR',
          to: selectedFromAsset.assetId,
        });
        setExchangeInfo(exchangeInfoData);
        setRequestError('');
      }
    } catch (e: any) {
      console.log('ERROR-handleExchange', e);
      setRequestError(e.code);
    }
    setIsLoading(false);
  };

  if (isSuccessfully) {
    return <Successfully />;
  }

  return (
    <FormProvider {...methods}>
      {exchangeInfo && eurAssetRate ? (
        <TradeSummaryInfo
          exchangeInfo={exchangeInfo}
          fromToRate={eurAssetRate}
          selectedFromAsset={eurData}
          selectedToAsset={selectedFromAsset}
          watchFromAmountCrypto={(+watchToAmountCrypto / (eurAssetRate?.rate || 1)).toString()}
        />
      ) : (
        <>
          <div className={styles.title}>Buy</div>
          <div className={styles.description}>
            Please enter the details for the <br />
            transaction to proceed.
          </div>

          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div style={{width: '50%', position: 'relative'}}>
              <div className={styles.enterAmount}>Buy</div>
              <AssetsDropdown
                assets={assets.filter(
                  item => item.assetId !== selectedFromAsset.assetId && eurAssetsList.includes(item.assetId)
                )}
                selectedData={selectedFromAsset}
                setSelectedData={setSelectedFromAsset}
              />
            </div>
            <div style={{width: 5}} />
            <div style={{width: '50%'}}>
              <div className={styles.enterAmount}>Amount</div>
              <AmountInput
                currencyAmount={watchToAmountCurrency}
                cryptoAmount={watchToAmountCrypto}
                asset={selectedFromAsset}
                changeAmountCurrency={changeAmountCurrency}
                isCurrency={isCurrency}
                direction='to'
                setFocusName={setFocusName}
                methods={methods}
                isEurFixed
              />
            </div>
          </div>
          <div style={{display: 'flex', alignItems: 'center', marginTop: 13, justifyContent: 'space-between'}}>
            <div className={styles.balance}>EUR Balance: € {getBalanceString(+eurData.totalBalance, 2)}</div>
            <div onClick={handleMax} className={styles.max}>
              MAX
            </div>
          </div>
          <div className={styles.enoughBalanceEur}>
            {errors.toAmountCrypto && errors.toAmountCrypto?.type === 'manual'
              ? 'Not enough fiat balance. Top up your balance to be able to buy.'
              : ' '}
          </div>
          <div style={{height: 58}} />
          <div className={styles.enterAmount}>Total</div>
          <div className={styles.totalAmount}>
            {Currencies.EUR} {watchToAmountCurrency || 0}
          </div>
          <div style={{width: '100%', borderTop: '1px solid rgba(38, 40, 50, 0.1)', margin: '25px 0'}} />
          <div className={styles.conversionRate}>
            Conversion Rate: 1 {selectedFromAsset.assetId} = {Currencies.EUR} {eurAssetRate ? 1 / eurAssetRate.rate : 0}
          </div>
        </>
      )}

      <div style={{height: 30}} />
      <button
        className='btn btn-primary'
        disabled={isLoading}
        style={errors.toAmountCrypto && errors.toAmountCrypto?.type === 'manual' ? {backgroundColor: 'red'} : {}}
        onClick={async () => {
          if (errors.toAmountCrypto && errors.toAmountCrypto?.type === 'manual') {
            handleMax();
          } else {
            await handleExchange();
          }
        }}
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        {isLoading ? (
          <span className='spinner-border' />
        ) : // eslint-disable-next-line no-nested-ternary
        errors.toAmountCrypto && errors.toAmountCrypto?.type === 'manual' ? (
          'Top Up Balance'
        ) : exchangeInfo ? (
          'Confirm Buy'
        ) : (
          'Buy'
        )}
      </button>

      {exchangeInfo && eurAssetRate && (
        <button
          className={classNames('btn', 'btn-primary', styles.backBtn)}
          onClick={e => {
            e.preventDefault();
            setExchangeInfo(null);
            setRequestError('');
          }}
        >
          Back
        </button>
      )}

      {requestError ? <RequestError requestError={requestError} /> : null}
    </FormProvider>
  );
};

export default Buy;
