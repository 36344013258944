import React from 'react';
import styles from './styles.module.scss';

const Copied: React.FC = () => {
  return (
    <div className={styles.secondBlock}>
      <div className={styles.snackWrap}>
        <p className={styles.title}>Copied</p>
      </div>
    </div>
  );
};

export default Copied;
