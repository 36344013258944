import React, {useEffect} from 'react';
import {observer} from 'mobx-react';
import {useNavigate} from 'react-router-dom';
import useLocalStorage from 'react-use-localstorage';
import {APP_ROUTES} from '../Router/constants';
import {Pages} from '../Router/types';

type Props = {
  children: JSX.Element;
};

const Layout: React.FC<Props> = ({children}) => {
  const navigate = useNavigate();

  const [lastActivityTime, setLastActivityTime] = useLocalStorage('lastActivityTime');
  const handleUserActivity = (): void => {
    setLastActivityTime(Date.now().toString());
  };

  useEffect(() => {
    window.addEventListener('click', handleUserActivity);

    return () => {
      window.removeEventListener('click', handleUserActivity);
    };
  }, []);

  // A function to check the idle time and delete the token from localStorage
  const checkInactivity = (): void => {
    const currentTime = Date.now();
    const timeSinceLastActivity = currentTime - Number(lastActivityTime);
    const inactivityTimeLimit = 120 * 60 * 1000; // 120 minutes in milliseconds

    if (timeSinceLastActivity >= inactivityTimeLimit) {
      navigate(APP_ROUTES[Pages.SignIn]);
    }
  };

  useEffect(() => {
    // Calling the idle time check function every 50 seconds
    const intervalId = setInterval(checkInactivity, 50000);

    return () => {
      clearInterval(intervalId);
    };
  }, [lastActivityTime]);

  useEffect(() => checkInactivity(), []);

  return <main>{children}</main>;
};

export default observer(Layout);
