import React from 'react';
import {makeAutoObservable} from 'mobx';
import UserStore from './user.store';
import {TUserResponse} from '../services/AuthService';
import {ExchangeRate, ExchangeRateRaw, TAsset} from '../services/AssetsService';
import {UDRecord} from '../services/UdService';
import {StakingContract, StakingPlansResponse} from '../services/StakingService';

export enum Currencies {
  USD = '$',
  EUR = '€',
}

class RootStore {
  // user
  user: TUserResponse | null = null;

  setUser = (data: TUserResponse | null): void => {
    this.user = data;
  };

  isBlockUpdate = false;

  setIsBlockUpdate = (data: boolean): void => {
    this.isBlockUpdate = data;
  };

  // SNACK BAR
  snackComponent: JSX.Element | null = null;

  setSnackComponent = (data: JSX.Element | null): void => {
    this.snackComponent = data;
  };

  // Rates raw
  ratesRaw: ExchangeRateRaw[] = [];

  setRatesRaw = (data: ExchangeRateRaw[]): void => {
    this.ratesRaw = data;
  };

  // Rates CeFi
  ratesCeFi: ExchangeRate[] = [];

  setRatesCeFi = (data: ExchangeRate[]): void => {
    this.ratesCeFi = data;
  };

  // earn
  stackingList: StakingContract[] = [];

  setStackingList = (data: StakingContract[]): void => {
    this.stackingList = data;
  };

  tierLevel = 0;

  setTierLevel = (data: number): void => {
    this.tierLevel = data;
  };

  stakingPlans: StakingPlansResponse = {simplePlans: [], rollingPlans: [], rollingLeveledPlans: []};

  setStakingPlans = (data: StakingPlansResponse): void => {
    this.stakingPlans = data;
  };

  // UD
  unstoppableDomains: UDRecord | null = null;

  setUnstoppableDomains = (data: UDRecord): void => {
    this.unstoppableDomains = data;
  };

  currency: Currencies = Currencies.USD;

  setCurrency = (data: Currencies): void => {
    this.currency = data;
  };

  assets: TAsset[] = [];

  setAssets = (data: TAsset[]): void => {
    this.assets = data;
  };

  mainLoader = true;

  setMainLoader = (data: boolean): void => {
    this.mainLoader = data;
  };

  isLoading = false;

  isError = false;

  userStore = {} as UserStore;

  isMobileNavPanel = false;

  // show|hide nav panel (mobile)
  setIsMobileNavPanel = (data: boolean): void => {
    this.isMobileNavPanel = data;
  };

  isMobileScreens = false;

  setIsMobileScreens = (data: boolean): void => {
    this.isMobileScreens = data;
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export const stores = new RootStore();

const StoresContext = React.createContext(stores);

export const useStores = (): RootStore => React.useContext(StoresContext);
