import React, {ReactElement, RefObject, useEffect, useState} from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import TriangleSvgComponent from '../../../../assets/icons/TriangleSvgComponent';

type DropDownProps = {
  data: any; // BankAddressResponse[] | TCountries[] | DepositInfo[] | string[];
  containerRef?: RefObject<HTMLInputElement>;
  setSelectedData?: React.Dispatch<any>;
  selectedData: any;
  itemComponent: (arg0: any) => ReactElement;
};

const CommonDropDown: React.FC<DropDownProps> = ({
  data,
  containerRef,
  itemComponent,
  setSelectedData,
  selectedData,
}) => {
  const [isShowContent, setIsShowContent] = useState(false);

  const hideContent = (event: MouseEvent): void => {
    if (containerRef && containerRef.current?.id === (event?.target as Element)?.id) {
      setIsShowContent(false);
    }
  };

  useEffect(() => {
    if (isShowContent) {
      containerRef?.current?.addEventListener('click', hideContent, true);
    }
    return containerRef?.current?.removeEventListener('click', hideContent);
  }, [isShowContent]);

  return (
    <>
      <div
        className={styles.networksDropdownMain}
        style={isShowContent ? {borderColor: 'var(--mainBlue)'} : {}} // height: 62 ??
        onClick={() => data.length > 1 && setIsShowContent(prev => !prev)}
      >
        <div className={styles.dropDownSelectedText}>{itemComponent(selectedData)}</div>
        {data.length > 1 ? (
          <div
            style={{width: 17, height: 20, marginRight: 12, transform: `rotate(${isShowContent ? '+90' : '-90'}deg)`}}
          >
            <TriangleSvgComponent fill='var(--mainBlue)' />
          </div>
        ) : null}
      </div>
      {isShowContent && (
        <div className={styles.networksDropdownContainer}>
          {data.map((item: any, index: React.Key | null | undefined) => {
            return (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                onClick={() => {
                  if (setSelectedData) {
                    setSelectedData(item);
                  }
                  setIsShowContent(false);
                }}
                className={classNames(styles.dropDownSelectedText, styles.networksDropdownItem)}
              >
                {itemComponent(item)}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default CommonDropDown;
