import {request} from './index';

export interface AssetInfo {
  assetId: string;
  symbol: string;
  assetName: string;
  precision: number;
  depositEnabled: boolean;
  withdrawalEnabled: boolean;
}

export interface UserAsset {
  assetId: string;
  availableBalance: number;
  reservedBalance: number;
  totalBalance: number;
}

export enum ECoinType {
  ETH = 'ETH',
  BNB = 'BNB',
  BTC = 'BTC',
  USD = 'USD',
  EUR = 'EUR',
  ADK = 'ADK',
  COMP = 'COMP',
  DOT = 'DOT',
  SOL = 'SOL',
  XRP = 'XRP',
  AVAX = 'AVAX',
  MATIC = 'MATIC',
}

export type TRateInfo = {
  value: number;
  change24Percent: number;
};

export type TBalance = {
  lastUpdateTime: number;
  available: number;
  availableCash: number; // USD
  total: number;
  reserved: number;
};

export type TAsset = {
  recerved: number;
  totalBalance: number;
  availableBalance: string;
  id: string;
  type: ECoinType;
  symbol: string;
  assetId: string;
  name: string;
  decimals: number;
  coinGeckoId: string;
  balance: TBalance;
  rate: TRateInfo;
  isNative: boolean;
  isFiat: boolean;
  loading: boolean;
  depositEnabled: boolean;
  withdrawalEnabled: boolean;
  exchangeEnabled: boolean;
  assetName: string;
  icon: string; // ImageSourcePropType;
  precision: number;
  contractAddress: string;
  isFavourite: boolean;
};

export interface ExchangeRateRaw {
  fromAssetId: string;
  toAssetId: string;
  data: CoinGeckoData;
}

export interface CoinGeckoData {
  id: string;
  symbol: string;
  name: string;
  image: string;
  currentPrice: number;
  marketCap: number;
  marketCapRank: number;
  fullyDilutedValuation: number;
  totalVolume: number;
  high24h: number;
  low24h: number;
  priceChange24h: number;
  priceChangePercentage24h: number;
  marketCapChange24h: number;
  marketCapChangePercentage_24h: number;
  circulatingSupply: number;
  totalSupply: number;
  maxSupply: number;
  ath: number;
  athChangePercentage: number;
  athDate: string;
  atl: number;
  atlChangePercentage: number;
  atlDate: string;
  lastUpdated: string;
}

export interface ExchangeRate {
  fromAssetId: string;
  toAssetId: string;
  rate: number;
}

export enum ECoinNetwork {
  ETH = 'Ethereum',
  BNB = 'Binance Smart Chain',
  AVAX = 'Avalanche',
  MATIC = 'Polygon',
  TRX = 'Tron',
}

export enum ECoinTypeNetworks {
  ETH = 'ETH',
  BNB = 'BNB',
  // BTC = 'BTC',
  AVAX = 'AVAX',
  MATIC = 'MATIC',
  TRX = 'TRX',
}

export interface DepositInfo {
  assetId: string;
  networkId: ECoinTypeNetworks;
  depositAddress: string | null;
}

export interface WithdrawalRequest {
  address: string;
  networkId: string;
  amount: number;
  assetId: string;
}

export interface WithdrawalInfo {
  assetId: string;
  networkId: string;
  remainingAmount: number;
  totalFeeAmount: number;
}

export interface WithdrawalRequestWithInfo {
  address: string;
  networkId: string;
  amount: number;
  info: WithdrawalInfo;
}

export interface ExchangeInfo {
  fromAssetId: string;
  toAssetId: string;
  rate: number;
  remainingAmount: number;
  totalFeeAmount: number;
}

export interface ExchangeRequest {
  amount: number;
  from: string;
  to: string;
}

export interface ExchangeRequestWithInfo {
  amount: number;
  info: ExchangeInfo;
}

export interface ReferralCodeResponse {
  code: string;
}

export interface FavouriteRequest {
  assetId: string;
  isFavorite: boolean;
}

const getAssets = (): Promise<AssetInfo[]> => request({url: '/v3/assets', method: 'GET'});

const getMyAssets = (): Promise<UserAsset[]> => request({url: '/v3/assets/my', method: 'GET'});

const getAssetsRatesUsdEur = (): Promise<ExchangeRateRaw[]> =>
  request({url: '/v3/assets/exchange/coin-gecko-rates-raw', method: 'GET'});

const getRatesList = (): Promise<ExchangeRate[]> => request({url: 'v3/assets/exchange/rates', method: 'GET'});

const getDepositInfo = (assetId: string): Promise<DepositInfo[]> =>
  request({
    url: `/v3/assets/my/${assetId}/deposit-address`,
    method: 'GET',
  });

const createDepositAddress = (assetId: string, networkId: string): Promise<DepositInfo> =>
  request({
    url: `/v3/assets/my/${assetId}/${networkId}/create-deposit-address`,
    method: 'GET',
  });

const getWithdrawalInfo = (data: WithdrawalRequest): Promise<WithdrawalInfo> =>
  request({url: `/v3/assets/my/${data.assetId}/withdraw/info`, data});

const withdrawalAsset = (data: WithdrawalRequestWithInfo): Promise<any> =>
  request({url: `/v3/assets/my/${data.info.assetId}/withdraw`, data});

const exchangeInfoAsset = (data: ExchangeRequest): Promise<ExchangeInfo> =>
  request({url: `/v3/assets/my/exchange/${data.from}/${data.to}/info`, data: {amount: data.amount}});

const exchangeAsset = (data: ExchangeRequestWithInfo): Promise<ExchangeInfo> =>
  request({
    url: `/v3/assets/my/exchange/${data.info.fromAssetId}/${data.info.toAssetId}`,
    data: {amount: data.amount, info: data.info},
  });

const getReferralCode = (): Promise<ReferralCodeResponse> => request({url: `/users/referral-code`, method: 'GET'});

const getFavouriteAssets = (): Promise<string[]> => request({url: `/v3/favourite-assets`, method: 'GET'});

const setFavouriteAssetAddRemove = (data: FavouriteRequest): Promise<void> =>
  request({
    url: `/v3/favourite-assets/add-or-remove?assetId=${data.assetId}&isFavorite=${data.isFavorite}`,
    method: 'GET',
  });

export const AssetsServices = {
  getAssets,
  getMyAssets,
  getAssetsRatesUsdEur,
  getRatesList,
  getDepositInfo,
  createDepositAddress,
  getWithdrawalInfo,
  withdrawalAsset,
  exchangeInfoAsset,
  exchangeAsset,
  getReferralCode,
  getFavouriteAssets,
  setFavouriteAssetAddRemove,
};
