import axios, {AxiosRequestConfig, AxiosError, Method} from 'axios';
import i18n from 'i18next';
import {getToken} from '../utils/localStorageUtils';
import {API_BASE_URL} from '../globalConstants';

interface IRequest {
  method?: Method;
  url: string;
  data?: any;
  contentType?: string;
  baseURL?: string;
  token?: string;
  customHeaders?: Record<string, string> | null;
}

export interface RequestError {
  status: number;
  message: string;
  data: any;
  code: string;
  codeMessage: string;
}

export const request = async ({
  method = 'POST',
  url = '',
  data = null,
  contentType = 'application/json',
  baseURL = API_BASE_URL,
  token = '',
  customHeaders = null,
}: IRequest): Promise<any> => {
  const {t} = i18n;

  const options: AxiosRequestConfig = {
    method,
    baseURL,
    url,
    headers: {'content-type': contentType},
    timeout: 20000,
  };

  const newToken = token || getToken();
  if (newToken && options.headers) options.headers.Authorization = `Bearer ${newToken}`;
  if (customHeaders) options.headers = customHeaders;

  if (data && method === 'GET') {
    options.params = data;
  } else if (data) {
    options.data = data;
  }

  const errorHandler = (axiosError: AxiosError): RequestError => {
    const error: RequestError = {status: 0, message: '', data: null, code: '', codeMessage: ''};
    if (axiosError.response) {
      error.data = axiosError.response.data || null;
      error.status = axiosError.response.status;
      const code = error.data?.errors ? error.data?.errors[0]?.code : '';
      if (code) {
        error.code = code;
      }
      error.codeMessage = t(`backendErrors:${code}`, {
        /* only test server info */
        defaultValue:
          // @ts-ignore
          API_BASE_URL === 'https://api2-v3-dev.blockbank.ai'
            ? `${t(`backendErrors:${code}`)} / traceid - ${axiosError.response.headers['X-B3-TraceId']}`
            : t(`backendErrors:${code}`),
      });
      if (error.status < 500) {
        error.message = error.data?.errors ? error.data?.errors[0]?.customMessage : error.data?.errors;
      }
    } else {
      error.status = 600;
    }
    return error;
  };

  return new Promise((resolve, reject) => {
    axios(options)
      .then(response => {
        // TODO в header ответе нету X-B3-spanid ??
        // console.log('response', response);
        // console.log(response.headers['X-B3-spanid']);
        resolve(response.data);
      })
      .catch(error => {
        console.log('reject-error', error);
        reject(errorHandler(error));
      });
  });
};
