// @ts-ignore
import {cloneDeep} from 'lodash';
import {clearToken, getCurrency, getToken} from '../../utils/localStorageUtils';
import AuthService from '../../services/AuthService';
import {AssetsServices, TAsset} from '../../services/AssetsService';
import {Currencies, stores} from '../index';
import {getReverseRecordUDAddress, walletAddressUD} from '../../services/UdService';
import {stakingContracts} from './stacking';
import eurLogo from '../../assets/icons/eur.png';

const isNativeSymbolAssetsList = ['USD', 'ADK', 'BNB', 'ETH', 'BTC', 'COMP', 'DOT', 'EUR', 'XRP', 'AVAX'];
const isFiatSymbolAssetsList = ['USD', 'EUR'];

export const initApp = async (): Promise<void> => {
  const token = getToken();
  const currency = getCurrency();

  if (currency) stores.setCurrency(currency as Currencies);

  if (token && !stores.isBlockUpdate) {
    try {
      const user = await AuthService.getSelf();
      stores.setUser(user);

      const {unstoppableDomains, setUnstoppableDomains} = stores;
      const availableAssets = await AssetsServices.getAssets();
      const myAssets = await AssetsServices.getMyAssets();
      const favouriteAssets = await AssetsServices.getFavouriteAssets();
      const balanceData = availableAssets.map(asset => {
        if (myAssets.find(myAsset => myAsset.assetId === asset.assetId)) {
          return {...asset, ...myAssets.find(myAsset => myAsset.assetId === asset.assetId)};
        }
        return asset;
      }) as TAsset[];

      const assetsRatesUsdEur = await AssetsServices.getAssetsRatesUsdEur();
      stores.setRatesRaw(assetsRatesUsdEur);
      const assetsRatesToUSD = assetsRatesUsdEur?.filter(asset => asset.toAssetId === 'USD');
      const assetsRatesToEUR = assetsRatesUsdEur?.filter(asset => asset.toAssetId === 'EUR');

      const exchangeRates = await AssetsServices.getRatesList();

      const exchangeRatesNoUSD = exchangeRates.filter(rateItem => {
        if (rateItem.toAssetId === 'USD') return false;
        if (rateItem.fromAssetId === 'USD') return false;

        // if (rateItem.toAssetId === 'EUR') return false;
        // if (rateItem.fromAssetId === 'EUR') return false;
        return true;
      });

      stores.setRatesCeFi(exchangeRatesNoUSD);

      if (balanceData && balanceData.length > 0) {
        const newAssets: TAsset[] = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const item of balanceData) {
          // TODO: organize the rejection of defaultAssets when symbol values appear from the server

          const currencyRate = stores.currency === Currencies.USD ? assetsRatesToUSD : assetsRatesToEUR;
          const coinGeckoAssetData = currencyRate.find(asset => asset.fromAssetId === item.assetId);
          const coinGeckoInfo = coinGeckoAssetData?.data;
          const newAsset: TAsset = cloneDeep(item);

          if (coinGeckoInfo) {
            newAsset.coinGeckoId = coinGeckoInfo.id;
            newAsset.icon = coinGeckoInfo.image;
            newAsset.rate = {
              value: coinGeckoInfo.currentPrice || 0,
              change24Percent: coinGeckoInfo.priceChangePercentage24h || 0,
            };
          } else {
            newAsset.rate = {
              value: 0,
              change24Percent: 0,
            };
            const eurRate = assetsRatesUsdEur.find(asset => asset.fromAssetId === 'USDT' && asset.toAssetId === 'EUR');
            const eurExchangeRates = exchangeRates.find(
              asset => asset.fromAssetId === 'USDT' && asset.toAssetId === 'EUR'
            );
            if (item.assetId === 'EUR' && (eurRate || eurExchangeRates)) {
              newAsset.rate = {
                value:
                  stores.currency === Currencies.USD
                    ? 1 / (eurRate?.data?.currentPrice || eurExchangeRates?.rate || 1)
                    : 1,
                change24Percent: eurRate?.data?.priceChangePercentage24h || 0,
              };
            } else if (item.assetId === 'USD' && (eurRate || eurExchangeRates)) {
              newAsset.rate = {
                value:
                  stores.currency === Currencies.EUR ? eurRate?.data?.currentPrice || eurExchangeRates?.rate || 1 : 1,
                change24Percent: eurRate?.data?.priceChangePercentage24h || 0,
              };
            } else {
              // TODO: you can make the data not from the coingecko from the internal exchange rates (CEFI rates)
              const toAssetIdRate = stores.currency === Currencies.EUR ? 'EUR' : 'USD';
              const itemRate =
                exchangeRates.find(asset => asset.fromAssetId === item.assetId && asset.toAssetId === toAssetIdRate) ||
                exchangeRates.find(asset => asset.fromAssetId === item.assetId && asset.toAssetId === 'EUR');

              newAsset.rate = {
                value: stores.currency === Currencies.EUR ? itemRate?.rate || 1 : 1,
                change24Percent: 0,
              };
            }
          }

          if (isNativeSymbolAssetsList.includes(item.symbol)) {
            newAsset.isNative = true;
            // it is true for ETH AVAX BNB
            newAsset.contractAddress = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee';
          }

          if (isFiatSymbolAssetsList.includes(item.symbol)) newAsset.isFiat = true;

          if (item.assetId === 'EUR') newAsset.icon = eurLogo;

          newAsset.decimals = item.precision;
          newAsset.balance = {
            lastUpdateTime: Date.now(),
            available: item?.availableBalance ? Number(item.availableBalance) : 0,
            total: item?.totalBalance ? Number(item.totalBalance) : 0,
            reserved: item?.recerved ? Number(item.recerved) : 0,
            availableCash: item.availableBalance ? +item.availableBalance * (newAsset.rate?.value || 0) : 0,
          };
          newAsset.name = item.assetName;
          newAsset.id = item.assetId;
          newAsset.isFavourite = favouriteAssets.includes(item.assetId);

          newAssets.push(newAsset);
        }

        stores.setAssets(newAssets);
      }

      if (!unstoppableDomains) {
        const address = await walletAddressUD();
        const ud = await getReverseRecordUDAddress(address.walletAddress);
        if (ud && ud.meta?.domain) setUnstoppableDomains(ud);
      }

      await stakingContracts();
    } catch (e) {
      console.log('ERROR-getSelf', e);
    }
  }
  stores.setMainLoader(false);
};
