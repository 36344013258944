import {request} from './index';

export enum StatusBankAddress {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  BLOCKED = 'BLOCKED',
  REJECTED = 'REJECTED ',
}

export type SortAddress = {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
};

export type PageableObject = {
  offset: number;
  sort: SortAddress;
  unpaged: boolean;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
};

export type BankAddressResponse = {
  addressUuid: string;
  iban: string;
  name: string;
  status: StatusBankAddress;
};

export type PageBankAddressResponse = {
  totalPages: number;
  totalElements: number;
  size: number;
  content: BankAddressResponse[];
  number: number;
  sort: SortAddress;
  numberOfElements: number;
  pageable: PageableObject;
  first: boolean;
  last: boolean;
  empty: boolean;
};

export type FiatWithdrawCreateRequest = {
  assetId: string;
  amount: number;
  depositAddressId: string;
};

export type FiatWithdrawResponse = {
  id: string;
  userUuid: string;
  assetId: string;
  amount: number;
  depositAddressId: string;
  withdrawalStatus: string;
  Enum: StatusBankAddress;
};

const getAllBankAddress = (): Promise<PageBankAddressResponse> =>
  request({url: `/v3/bank-address/all?status=APPROVED`, method: 'GET'});

const fiatWithdrawal = (data: FiatWithdrawCreateRequest): Promise<FiatWithdrawResponse> =>
  request({url: `/v3/fiat/new-withdrawal`, data});

export default {
  getAllBankAddress,
  fiatWithdrawal,
};
