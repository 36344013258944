import React from 'react';
import classNames from 'classnames';
import {useNavigate} from 'react-router-dom';
import styles from './styles.module.scss';
import close from '../../../../assets/icons/close.svg';
import CompleteIconBlurComponent from './CompleteIconBlurComponent';
import {useGlobalModalContext} from '../index';

type Props = {
  text: string;
};

const EarningSuccessfully: React.FC<Props> = ({text}) => {
  const {hideModal} = useGlobalModalContext();
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.closeWrap} onClick={hideModal}>
        <img className={styles.closeIcon} alt='' src={close} />
      </div>
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <CompleteIconBlurComponent />
        <div className={styles.completedText}>{text}</div>
        <div className={styles.closeBtn}>
          <button onClick={() => navigate(-1)} className={classNames('btn', 'btn-primary', styles.btnMobile)}>
            Close and Return
          </button>
        </div>
      </div>
      <div className={styles.closeWrap} onClick={hideModal} style={{visibility: 'hidden'}}>
        <img className={styles.closeIcon} alt='' src={close} />
      </div>
    </div>
  );
};

export default EarningSuccessfully;
