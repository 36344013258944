import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {useNavigate} from 'react-router-dom';
import styles from './styles.module.scss';
import {Currencies, useStores} from '../../../../stores';
import {getBalanceString, roundingBalance} from '../../../../utils/formats';
import {AssetsServices} from '../../../../services/AssetsService';
import {EVENT_NAMES, useAnalytics} from '../../../../services/Analytics';
import StartPageCard from './StartPageCard';
import HeaderTitle from '../common/HeaderTitle';
import FiatTransactions from './FiatTransactions';
import {useGlobalModalContext} from '../../Modals';
import {Pages} from '../../../Router/types';
import {APP_ROUTES} from '../../../Router/constants';
import {MODAL_TYPES} from '../../Modals/modalConstants';

const Banking: React.FC = () => {
  const {assets, isMobileScreens} = useStores();
  const {myLogEvent} = useAnalytics();
  const fiatAsset = assets.find(asset => asset.isFiat);

  const navigate = useNavigate();
  const {showModal, setModalStore} = useGlobalModalContext();

  const [codeForInvitation, setCodeForInvitation] = useState('');

  const goToModal = (type: string): void => {
    const options = {
      codeForInvitation,
      openDeposit: () => goToModal(MODAL_TYPES.TOP_UP_BANKING),
      title: Pages.BANKING,
    };
    if (isMobileScreens) {
      setModalStore(options);
      navigate(`${APP_ROUTES[Pages.BANKING]}/${type}`);
    } else {
      showModal(type, options);
    }
  };

  useEffect(() => {
    AssetsServices.getReferralCode()
      .then(res => {
        setCodeForInvitation(res.code);
      })
      .catch(() => setCodeForInvitation(''))
      .finally(() => {
        myLogEvent(EVENT_NAMES.WEB_BANKING_OPENED);
      });
  }, []);

  return (
    <div className={styles.bankingWrap}>
      <HeaderTitle hideUd headerTitle='Banking' />
      <div className={styles.mainContent}>
        <div className={styles.balanceWrap}>
          <div className={styles.fiatBalanceCard}>
            <div className={styles.balanceTitle}>Fiat Balance</div>
            <div className={styles.balance}>
              {Currencies.EUR}
              {getBalanceString(+roundingBalance(fiatAsset?.availableBalance || '0', 2))}
            </div>
            <div className='justify-row-center'>
              <button
                onClick={() => goToModal(MODAL_TYPES.TOP_UP_BANKING)}
                className={classNames('btn', 'btn-primary', styles.fiatBtn)}
              >
                <div className={styles.btnTitle}>Top Up</div>
              </button>
              <button
                onClick={() => goToModal(MODAL_TYPES.WITHDRAW_BANKING)}
                className={classNames('btn', 'btn-primary', styles.fiatBtn)}
                style={{backgroundColor: '#FFF', color: '#262832', border: '1px solid #262832', marginLeft: 12}}
              >
                <div className={styles.btnTitle}>Withdraw</div>
              </button>
            </div>
          </div>
          <div className={styles.fiatTransactionWeb}>
            <FiatTransactions />
          </div>
        </div>

        <StartPageCard goToTopUp={() => goToModal(MODAL_TYPES.TOP_UP_BANKING)} />

        {isMobileScreens ? (
          <div className={styles.fiatTransactionMobile}>
            <FiatTransactions />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Banking;
