import {request} from './index';

const getOrderStatus = (): Promise<any> => request({url: `/v3/card/order/status`, method: 'GET'});

const submitOrderStatus = (data: any): Promise<any> => request({url: `/v3/card/order/submit`, data});

export default {
  getOrderStatus,
  submitOrderStatus,
};
