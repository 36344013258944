import {Pages} from '../types';

export const APP_ROUTES = {
  [Pages.Base]: '/',
  [Pages.SignIn]: '/sign-in',
  [Pages.SignUp]: '/sign-up',
  [Pages.ConfirmationCode]: '/confirmation-code',
  [Pages.TwoFactorAuthentication]: '/two-factor-authentication',
  [Pages.ForgotPassword]: '/forgot-password',
  [Pages.KYC]: '/k-y-c',
  [Pages.EARN]: '/earn',
  [Pages.BANKING]: '/banking',
  [Pages.SUPPORT]: '/support',
  [Pages.SETTINGS]: '/settings',
  [Pages.ISMOBILE]: '/mobile',
  [Pages.ASSET]: '/asset',
  [Pages.OTC]: '/otc',
  [Pages.STAKING]: '/staking',
  [Pages.DELETE_ACCOUNT]: '/delete-account',
  [Pages.TRANSACTIONS]: '/transactions',
  [Pages.PORTFOLIO]: '/portfolio',
};
