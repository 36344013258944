import React, {useState} from 'react';
import {AssetsServices, TAsset} from '../../../../../services/AssetsService';
import {initApp} from '../../../../../stores/actions/init';
import StarSVG from '../StarSVG';

type StarComponentProps = {
  asset: TAsset;
};

const StarComponent: React.FC<StarComponentProps> = ({asset}) => {
  const [isLoading, setIsLoading] = useState(false);
  const updateFavourite = async (assetItem: TAsset): Promise<void> => {
    setIsLoading(true);
    try {
      await AssetsServices.setFavouriteAssetAddRemove({
        assetId: assetItem.assetId,
        isFavorite: !assetItem.isFavourite,
      });
      await initApp();
    } catch (e) {
      console.log('ERROR-setFavouriteAssetAddRemove', e);
    }

    setIsLoading(false);
  };

  return (
    <div
      style={{position: 'relative'}}
      onClick={async event => {
        event.stopPropagation();
        await updateFavourite(asset);
      }}
    >
      {isLoading && (
        <div style={{position: 'absolute', left: 12, top: 11}}>
          <span className='spinner-border' />
        </div>
      )}

      <StarSVG fill={asset.isFavourite ? '#E7A600' : 'white'} stroke={asset.isFavourite ? '#E7A600' : '#E1E1E1'} />
    </div>
  );
};

export default StarComponent;
