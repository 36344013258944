import React from 'react';
import styles from './styles.module.scss';

type Props = {
  value: number;
  isModal?: boolean;
};

const ProgressBar: React.FC<Props> = ({value, isModal}) => {
  return (
    <div className={styles.progressBar} style={isModal ? {height: 8} : {}}>
      <div className={styles.progressBarFill} style={{width: `${value}%`}} />
    </div>
  );
};

export default ProgressBar;
