import React from 'react';
import styles from './styles.module.scss';
import Currency from './Currency';
import TwoFactorAuthentication from './TwoFactorAuthentication';
import ChangePassword from './ChangePassword';
import Referral from './Referral';
import FAQ from './FAQ';
import UserEmail from './UserEmail';
import DeleteAccount from './DeleteAccount';
import Version from './Version';
import HeaderTitle from '../common/HeaderTitle';

const Settings: React.FC = () => {
  return (
    <div className={styles.settingsWrap}>
      <HeaderTitle hideUd headerTitle='Settings' />
      <div className={styles.settingsItemsWrap}>
        <Currency />
        <TwoFactorAuthentication />
        <ChangePassword />
        <Referral />
        {/* <FAQ /> */}
        <UserEmail />
        <DeleteAccount />
        <Version />
      </div>
    </div>
  );
};

export default Settings;
