import {StakingServices} from '../../services/StakingService';
import {stores} from '../index';

export const stakingContracts = async (): Promise<void> => {
  const allStakingContracts = await StakingServices.contracts();

  if (allStakingContracts) {
    const simpleContracts = allStakingContracts.simpleContracts.map(contract => ({...contract, isSimple: true}));
    const rollingLeveledContracts = allStakingContracts.rollingLeveledContracts.map(contract => ({
      ...contract,
      isRollingLeveled: true,
    }));
    const rollingResponses = allStakingContracts.rollingResponses.map(contract => ({
      ...contract,
      isRollingResponses: true,
    }));

    const contractsList = [...simpleContracts, ...rollingLeveledContracts, ...rollingResponses];

    stores.setStackingList(contractsList);
  }

  const tierLevel = await StakingServices.tierLevel();
  stores.setTierLevel(tierLevel);

  const stakingPlans = await StakingServices.stakingPlans();
  stores.setStakingPlans(stakingPlans);
};
