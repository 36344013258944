import React from 'react';
import classNames from 'classnames';
import {useNavigate} from 'react-router-dom';
import styles from './styles.module.scss';
import {useStores} from '../../../../stores';

type ModalWithdrawalProps = {
  closeModal: () => void;
  openDeposit: () => void;
};

const WithdrawalNotAvailable: React.FC<ModalWithdrawalProps> = ({closeModal, openDeposit}) => {
  const {isMobileScreens} = useStores();
  const navigate = useNavigate();

  return (
    <div className={styles.containerNotAvailable}>
      <div className={styles.transfer}>Withdrawal Not Available</div>
      <div className={styles.transferDescription}>
        According to the Terms & Conditions to be able to withdraw your funds to any bank account, you must first make a
        deposit with the desired bank account. You can withdraw your funds to the bank accounts from which deposits have
        been made.
      </div>
      <button
        onClick={() => {
          closeModal();
          openDeposit();
        }}
        className='btn btn-primary'
      >
        Deposit
      </button>
    </div>
  );
};

export default WithdrawalNotAvailable;
