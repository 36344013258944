// /* eslint-disable no-unused-vars */
import React from 'react';
import {observer} from 'mobx-react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import {Currencies, useStores} from '../../../../stores';
import {initApp} from '../../../../stores/actions/init';
import {saveCurrency} from '../../../../utils/localStorageUtils';

const Currency: React.FC = () => {
  const {currency, setCurrency} = useStores();

  return (
    <div className={styles.settingsItem}>
      <div className={styles.settingsItemContent}>
        <div style={{display: 'flex'}}>
          <div className={styles.settingsItemTitle}>Currency</div>
        </div>
        <div className={styles.settingsItemDescription}>
          You can easily switch your account currency easily. Just switch to the preferred currency
        </div>
        <div className={styles.currencySwitchBlock}>
          <div
            onClick={async () => {
              setCurrency(Currencies.EUR);
              saveCurrency(Currencies.EUR);
              await initApp();
            }}
            className={classNames(styles.currencyBtn, currency === Currencies.EUR ? styles.currencyBtnActive : '')}
          >
            EUR
          </div>
          <div
            onClick={async () => {
              setCurrency(Currencies.USD);
              saveCurrency(Currencies.USD);
              await initApp();
            }}
            className={classNames(styles.currencyBtn, currency === Currencies.USD ? styles.currencyBtnActive : '')}
          >
            USD
          </div>
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line mobx/no-anonymous-observer
export default observer(Currency);
