import React from 'react';
import {useNavigate} from 'react-router-dom';
import styles from './styles.module.scss';
import {useStores} from '../../../../stores';
import {useGlobalModalContext} from '../../Modals';
import {Pages} from '../../../Router/types';
import {APP_ROUTES} from '../../../Router/constants';
import {MODAL_TYPES} from '../../Modals/modalConstants';

const ChangePassword: React.FC = () => {
  const {isMobileScreens} = useStores();
  const {showModal, setModalStore} = useGlobalModalContext();
  const navigate = useNavigate();

  const handleBtn = (): void => {
    const options = {
      title: Pages.SETTINGS,
    };
    if (isMobileScreens) {
      setModalStore(options);
      navigate(`${APP_ROUTES[Pages.SETTINGS]}/${MODAL_TYPES.CHANGE_PASSWORD_MODAL}`);
    } else {
      showModal(MODAL_TYPES.CHANGE_PASSWORD_MODAL, options);
    }
  };

  return (
    <div className={styles.settingsItem}>
      <div className={styles.settingsItemContent}>
        <div className={styles.twoFaTitleWrap}>
          <div className={styles.settingsItemTitle}>Change Password</div>
        </div>

        <div className={styles.settingsItemDescription}>
          You can update your account password anytime. To change password follow our instructions.
        </div>

        <div onClick={handleBtn} className={styles.twoFaBtn}>
          Change Password
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
