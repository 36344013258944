import React, {ReactElement, RefObject, useState} from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import classNames from 'classnames';
import {useNavigate} from 'react-router-dom';
import styles from './styles.module.scss';
import CommonDropDown from '../CommonModalComponents/CommonDropDown';
import FiatService, {BankAddressResponse} from '../../../../services/FiatService';
import danger from '../../../../assets/icons/danger.svg';
import {theme} from '../../../../globalConstants';
import CompleteIconBlurComponent from '../CommonModalComponents/CompleteIconBlurComponent';
import {getBalanceString} from '../../../../utils/formats';
import {useStores} from '../../../../stores';
import {useGlobalModalContext} from '../index';

type Inputs = {
  amount: string;
};

const defaultValues = {
  amount: '',
};

type ModalWithdrawalProps = {
  bankAddresses: BankAddressResponse[];
  containerRef?: RefObject<HTMLInputElement>;
};

const WithdrawalFiat: React.FC<ModalWithdrawalProps> = ({bankAddresses, containerRef}) => {
  const {isMobileScreens} = useStores();
  const navigate = useNavigate();
  const {hideModal} = useGlobalModalContext();

  const methods = useForm<Inputs>({defaultValues});
  const {
    register,
    handleSubmit,
    formState: {errors},
    watch,
  } = methods;

  const watchAmount = watch('amount');

  const [selectedAddress, setSelectedAddress] = useState(bankAddresses[0]);
  const [isSummary, setIsSummary] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestError, setRequestError] = useState('');
  const [isSuccessful, setIsSuccessful] = useState(false);

  const itemComponentAddresses = (selectedItemAddress: BankAddressResponse): ReactElement => {
    const trimmedStr = selectedItemAddress?.iban.replace(/\s/g, '');
    const iban = `Account Ending in ****${trimmedStr?.substring(trimmedStr.length - 4)}`;
    return (
      <div>
        <div className={styles.bankName}>{selectedItemAddress?.name || '123'}</div>
        <div className={styles.bankIban}>{iban}</div>
      </div>
    );
  };

  const itemComponentCurrency = (currency: string): ReactElement => {
    return <div className={styles.bankName}>{currency}</div>;
  };

  const summaryName = (): string => {
    const trimmedStr = selectedAddress?.iban.replace(/\s/g, '');
    return `${selectedAddress?.name} ****${trimmedStr?.substring(trimmedStr.length - 4)}`;
  };

  const handleFiatWithdraw = async (): Promise<void> => {
    if (isSummary) {
      setLoading(true);
      try {
        await FiatService.fiatWithdrawal({
          assetId: 'EUR',
          amount: +watchAmount,
          depositAddressId: selectedAddress.addressUuid,
        });
        setIsSuccessful(true);
      } catch (e: any) {
        console.log('ERROR-fiatWithdrawal', e);
        setRequestError(e.code);
      }
      setLoading(false);
    } else {
      setIsSummary(true);
    }
  };

  const validateInput = (data: string): boolean => {
    return +data >= 50;
  };

  if (isSuccessful) {
    return (
      <div data-theme={theme} className={styles.container}>
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
            padding: '0 32px',
          }}
        >
          <div className={styles.transfer}>Withdrawal Requested</div>
          <div className={styles.transferDescription} style={{fontSize: 12}}>
            Please allow 24-72 hours for the withdrawal request to be processed.
          </div>
          <div>
            <CompleteIconBlurComponent />
          </div>
          <button
            onClick={() => {
              if (isMobileScreens) {
                navigate(-1);
              } else {
                hideModal();
              }
            }}
            className={classNames('btn', 'btn-primary', styles.btnMobile)}
          >
            Close and Return
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.containerAvailable}>
      <FormProvider {...methods}>
        <form style={{position: 'relative', margin: '0 32px'}} onSubmit={handleSubmit(handleFiatWithdraw)}>
          <div className={styles.transfer}>{isSummary ? 'Withdrawal Summary' : 'Withdraw'}</div>
          <div className={styles.transferDescription}>
            {isSummary
              ? 'Please review the information below and confirm.'
              : 'Please fill in the necessary information to withdraw.'}
          </div>
          <div className={styles.transferDescription}>
            The bank account from which you are withdrawing must be under your full name. The minimum withdrawal amount
            is €50 or it will not be processed. Please note that SWIFT withdrawals incur a €25 fee.
          </div>
          <div className={styles.transferDescription2} />
          <div className={styles.enterAmount}>Bank Account</div>
          {isSummary ? (
            <div className={styles.bankNameResult}>{summaryName()}</div>
          ) : (
            <CommonDropDown
              containerRef={containerRef}
              data={bankAddresses}
              itemComponent={itemComponentAddresses}
              setSelectedData={setSelectedAddress}
              selectedData={selectedAddress}
            />
          )}

          <div style={{height: 24}} />
          <div className={styles.enterAmount}>Currency</div>
          {isSummary ? (
            <div className={styles.bankNameResult}>Euro</div>
          ) : (
            <CommonDropDown
              containerRef={containerRef}
              data={['Euro']}
              itemComponent={itemComponentCurrency}
              selectedData='Euro'
            />
          )}
          <div style={{height: 24}} />
          <div className={styles.enterAmount} style={errors.amount ? {color: 'red'} : {}}>
            {errors.amount?.type === 'validate' ? 'Minimal withdrawal amount is 50 EUR' : 'Amount'}
          </div>
          {isSummary ? (
            <div className={styles.bankNameResult}>{getBalanceString(Number(watchAmount || '0'))} EUR</div>
          ) : (
            <div className={styles.inputBlock} style={errors.amount ? {border: '1px solid red'} : {}}>
              <input
                placeholder='00.00'
                className={styles.input}
                {...register('amount', {required: true, validate: validateInput})}
              />
              <div style={{marginRight: 17}}>EUR</div>
            </div>
          )}

          <button type='submit' className='btn btn-primary' style={{marginTop: 64}}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {loading ? <span className='spinner-border' /> : isSummary ? 'Confirm Withdraw Request' : 'Continue'}
          </button>

          {isSummary && (
            <button
              className={classNames('btn', 'btn-primary', styles.backBtn)}
              onClick={e => {
                e.preventDefault();
                setIsSummary(false);
              }}
            >
              Back
            </button>
          )}

          {isSummary ? null : (
            <div className={styles.transferDescription} style={{fontSize: 12, marginTop: 36}}>
              Please allow 24-72 hours for the withdrawal request to be processed.
            </div>
          )}

          {requestError ? (
            <div
              style={{
                height: 78,
                backgroundColor: 'rgba(255, 0, 0, 0.1)',
                margin: '24px 0',
                borderRadius: 10,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img style={{height: 24, margin: '0 24px'}} alt='' src={danger} />
              <div style={{marginRight: 24}}>{requestError}</div>
            </div>
          ) : (
            <div style={{height: 78, margin: '24px 0'}} />
          )}
        </form>
      </FormProvider>
    </div>
  );
};

export default WithdrawalFiat;
