import React, {useEffect, useState} from 'react';
import snsWebSdk from '@sumsub/websdk';
import classNames from 'classnames';
import {useLocation, useNavigate} from 'react-router-dom';
import styles from '../styles.module.scss';
import BackBtn from '../common/BackBtn';
import AuthTitle from '../common/AuthTitle';
import I18n from '../../../common/I18n';
import AuthService from '../../../../services/AuthService';
import AuthSnackComponent from '../common/AuthSnackComponent';
import {errorDescriptionHandler} from '../authUtils/errorDescriptionHandler';
import {useStores} from '../../../../stores';
import {APP_ROUTES} from '../../../Router/constants';
import {Pages} from '../../../Router/types';
import {getToken} from '../../../../utils/localStorageUtils';
import AuthLayout from '../AuthLayout';

const KYCScreen: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {setSnackComponent} = useStores();
  const [loading, setLoading] = useState<boolean>(false);
  const [hideBtn, setHideBtn] = useState(false);

  useEffect(() => {
    const token = getToken();
    if (!token) navigate(APP_ROUTES[Pages.SignIn]);
    setHideBtn(false);
  }, [location.pathname]);

  const getNewAccessToken = async (): Promise<string> => {
    try {
      const tokenResponse = await AuthService.getSumSubToken();
      return tokenResponse.token;
    } catch (error: any) {
      console.log('ERROR-getNewAccessToken', error);
      setSnackComponent(<AuthSnackComponent title='ERROR' description={errorDescriptionHandler(error.code || '')} />);
    }
    return '';
  };

  const launchWebSdk = (accessToken: string): void => {
    const snsWebSdkInstance = snsWebSdk
      .init(accessToken, () => getNewAccessToken())
      .withConf({
        lang: 'en',
      })
      .withOptions({addViewportTag: false, adaptIframeHeight: true})
      .on('idCheck.applicantStatus', payload => {
        // @ts-ignore
        if (payload?.reviewStatus === 'completed' && payload?.reviewResult?.reviewAnswer !== 'RED') {
          navigate(APP_ROUTES[Pages.Base]);
        }
      })
      .build();

    snsWebSdkInstance.launch('#sumsub-websdk-container');
  };

  const onSubmit = async (): Promise<void> => {
    setLoading(true);

    try {
      const result = await AuthService.getSumSubToken();
      setHideBtn(true);
      launchWebSdk(result.token);
    } catch (error: any) {
      console.log('ERROR-KYCScreen', error);
      // TODO: title ?
      setSnackComponent(<AuthSnackComponent title='ERROR' description={errorDescriptionHandler(error.code || '')} />);
    }

    setLoading(false);
  };

  return (
    <AuthLayout>
      <div className={styles.rightModule}>
        <BackBtn backFn={() => navigate(APP_ROUTES[Pages.SignIn])} />

        {!hideBtn && (
          <div className={classNames(styles.formWrap)}>
            <AuthTitle title='kyc.title' description='kyc.description' />

            <button onClick={onSubmit} className='btn btn-primary' disabled={loading}>
              {loading ? <span className='spinner-border' /> : <I18n tKey='kyc.identity' />}
            </button>
          </div>
        )}

        <div className={styles.sumsub} id='sumsub-websdk-container' />
      </div>
    </AuthLayout>
  );
};

export default KYCScreen;
