import {observer} from 'mobx-react';
import React from 'react';
import {Navigate} from 'react-router-dom';
import {Pages} from '../types';
import {ProtectionType} from './types';
import {useStores} from '../../../stores';
import {APP_ROUTES} from '../constants';

type Props = {
  element: JSX.Element;
  protectionType: (typeof ProtectionType)[keyof typeof ProtectionType];
};

const ProtectedRoute: React.FC<Props> = ({element, protectionType}) => {
  const stores = useStores();

  const {user} = stores;

  if (protectionType === ProtectionType.multiple) {
    return element;
  }

  if (!user && protectionType === ProtectionType.user) {
    return <Navigate to={APP_ROUTES[Pages.SignIn]} replace />;
  }

  return element;
};

export default observer(ProtectedRoute);
