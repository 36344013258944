export const getBalanceString = (value: any, minF = 2, maxF = 2): string => {
  // eslint-disable-next-line no-restricted-globals
  return !value || isNaN(value)
    ? '0'
    : value.toLocaleString('en-US', {
        minimumFractionDigits: minF,
        maximumFractionDigits: minF > maxF ? minF : maxF,
      });
};

// export const ellipseAddress = (address = '', width = 5): string => {
//   return `${address.slice(0, width)}...${address.slice(-width)}`;
// };
//
// export const localeStringToNumber = (value: string): number => {
//   if (!value) {
//     return 0;
//   }
//   return Number(value.replace(/,/g, ''));
// };
//
export const roundingBalance = (balance = '0', separator = 6): string => {
  if (balance === '.') return '0.';

  const separateValue = balance.toString().split('.');

  let decimal = separateValue[1];
  if (decimal) {
    decimal = decimal.slice(0, separator);
  }

  return decimal ? [separateValue[0], decimal].join('.') : balance;
};
