import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import {useStores} from '../../../../stores';

const UserEmail: React.FC = () => {
  const {user} = useStores();

  return (
    <div className={styles.settingsItem}>
      <div className={styles.settingsItemContent}>
        <div className={styles.twoFaTitleWrap}>
          <div className={styles.settingsItemTitle}>User Email</div>
        </div>

        <div className={styles.settingsItemDescription}>
          Your account is registered with the following email. You can not edit your account email.
        </div>

        <div className={classNames(styles.twoFaBtn, styles.copyBtn)} style={{color: '#445374'}}>
          {user?.credentials[0].value}
        </div>
      </div>
    </div>
  );
};

export default UserEmail;
