import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react';
import styles from './styles.module.scss';
import HeaderCards from '../common/HeaderCards';
import {useStores} from '../../../../stores';
import {getBalanceString} from '../../../../utils/formats';
import {EVENT_NAMES, useAnalytics} from '../../../../services/Analytics';
import EarnRow from './Row';
import {useGlobalModalContext} from '../../Modals';
import {APP_ROUTES} from '../../../Router/constants';
import {Pages} from '../../../Router/types';
import {MODAL_TYPES} from '../../Modals/modalConstants';

const Earn: React.FC = () => {
  const {stackingList, tierLevel, stakingPlans, assets, isMobileScreens} = useStores();

  const {myLogEvent} = useAnalytics();

  const {showModal, setModalStore} = useGlobalModalContext();
  const navigate = useNavigate();

  const [inputAmount, setInputAmount] = useState('');
  const [inputBbankError, setInputBbankError] = useState(false);
  const [isNotEnough, setIsNotEnough] = useState(false);

  useEffect(() => {
    myLogEvent(EVENT_NAMES.WEB_EARN_PAGE_OPENED);
  }, []);

  const availableBalance = assets.find(asset => asset.id === 'BBANK');
  const bbankStakeAmount = stackingList
    .filter(stake => stake.assetId === 'BBANK')
    .reduce((acc, stake) => {
      return acc + +stake.amount;
    }, 0);

  const rollingLeveledPlans = stakingPlans.rollingLeveledPlans
    .slice()
    .sort((a, b) => +a.minimalTargetPlanAmount - +b.minimalTargetPlanAmount);
  const nextPlan = rollingLeveledPlans.find(plan => bbankStakeAmount < +plan.minimalPlanAmount);
  const nextAmount = nextPlan ? nextPlan.minimalPlanAmount - bbankStakeAmount : 0;

  const bbankStakeRollingLeveled = stackingList.filter(stake => stake.isRollingLeveled);
  const currentStakingApyPercent = bbankStakeRollingLeveled.length ? bbankStakeRollingLeveled[0].stakingApyPercent : 0;

  const maxOtherApiPercent = stakingPlans.simplePlans.reduce(
    (max, plan) => (plan.stakingApyPercent > max ? +plan.stakingApyPercent : max),
    0
  );

  const handleLockBbank = (): void => {
    if (!Number(inputAmount)) {
      setInputBbankError(true);
    } else if (Number(availableBalance?.availableBalance || 0) < +inputAmount) {
      setIsNotEnough(true);
      setInputBbankError(true);
    } else {
      setInputBbankError(false);
      const options = {
        amount: inputAmount,
        currentStakingApyPercent,
        maxOtherApiPercent,
        title: Pages.EARN,
      };
      if (isMobileScreens) {
        setModalStore(options);
        navigate(`${APP_ROUTES[Pages.EARN]}/${MODAL_TYPES.LOCK_BBANK_MODAL}`);
      } else {
        showModal(MODAL_TYPES.LOCK_BBANK_MODAL, options);
      }
    }
  };

  const handleNewEarning = (): void => {
    if (!stakingPlans.simplePlans?.length) {
      window.open('https://blockbank.ai/announcements/new-tier-system-and-referral-program/');
    } else {
      const options = {
        amount: inputAmount,
        currentStakingApyPercent,
        maxOtherApiPercent,
        title: Pages.EARN,
      };
      if (isMobileScreens) {
        setModalStore(options);
        navigate(`${APP_ROUTES[Pages.EARN]}/${MODAL_TYPES.NEW_EARNING_MODAL}`);
      } else {
        showModal(MODAL_TYPES.NEW_EARNING_MODAL, options);
      }
    }
  };

  return (
    <div className={styles.earnWrap}>
      <HeaderCards headerTitle='Earn' hideUd />
      <div className={styles.tableContainer}>
        <div className={styles.tableTitleRow}>
          <div className={styles.tableTitle}>Earnings</div>
          <button onClick={handleNewEarning} className={classNames('btn', 'btn-primary', styles.newEarn)}>
            + New Earning
          </button>
        </div>
        <div className={styles.earnInfoBlock}>
          <div className={classNames(styles.earnInfoItem, styles.earnInfoItemOrder1)}>
            <div className={styles.earnInfoItemTitle}>Total Locked</div>
            <div className={styles.earnInfoData}>{getBalanceString(bbankStakeAmount, 4)} BBANK</div>
          </div>
          <div
            onClick={() => {
              window.open('https://blockbank.ai/announcements/new-tier-system-and-referral-program/');
            }}
            className={classNames(styles.earnInfoItem, styles.earnInfoItemOrder3)}
            style={{cursor: 'pointer'}}
          >
            <div className={styles.tierInfo}>
              <div className={styles.earnInfoItemTitle}>Assigned Tier</div>
              <div style={{display: 'flex'}}>
                <div className={styles.dot} />
                <div className={styles.dot} />
                <div className={styles.dot} />
              </div>
            </div>
            <div className={styles.earnInfoData}>
              <div>Tier {tierLevel}</div>
              <div className={styles.dot2} />
              <div>{+currentStakingApyPercent}% APY BBANK</div>
              <div className={styles.dot2} />
              <div>{maxOtherApiPercent}% APY Other</div>
            </div>
          </div>
          <div className={classNames(styles.earnInfoItem, styles.earnInfoItemOrder2)}>
            <div className={styles.earnInfoItemTitle}>Lock to Reach Next Tier</div>
            {tierLevel === 4 ? (
              <div className={styles.earnInfoData}>Max Level Reached</div>
            ) : (
              <div className={styles.earnInfoData}>{getBalanceString(nextAmount, 4)} BBANK</div>
            )}
          </div>

          <div className={styles.earnLastInfoItem}>
            <div className={styles.lockInputWrap}>
              <input
                id='amount'
                type='text'
                className={styles.lockInput}
                style={inputBbankError ? {border: '1px solid red'} : {}}
                placeholder='Enter Amount to Lock'
                value={inputAmount}
                onChange={value => {
                  setInputAmount(value.target?.value.replace(',', ''));
                  setIsNotEnough(false);
                  setInputBbankError(false);
                }}
              />
              <button onClick={handleLockBbank} className={classNames('btn', 'btn-primary', styles.lockInputBtn)}>
                Lock BBANK
              </button>
            </div>
            {isNotEnough ? (
              <div className={styles.reachNextTier} style={{color: 'red'}}>
                Not enough balance
              </div>
            ) : (
              <div className={styles.reachNextTier}>
                {tierLevel === 4 ? '' : `Lock ${getBalanceString(nextAmount, 8)} BBANK to Reach Next Tier`}
              </div>
            )}
          </div>
        </div>
        <div style={{height: 24}} />
        <div className={styles.table}>
          {stackingList.length ? (
            <>
              <div className={classNames(styles.row, styles.header)}>
                <div className={classNames(styles.cell, styles.cell3)}>Name</div>
                <div style={{flexGrow: 1}} />
                <div className={classNames(styles.cell, styles.cell1)}>Locked</div>
                <div style={{flexGrow: 1}} />
                <div className={classNames(styles.cell, styles.cell4)}>Value</div>
                <div style={{flexGrow: 1}} />
                <div className={classNames(styles.cell, styles.cell5)}>APY</div>
                <div style={{flexGrow: 1}} />
                <div className={classNames(styles.cell, styles.cell3)}>Paid Interest</div>
                <div style={{flexGrow: 1}} />
                <div className={classNames(styles.cell, styles.cell1)}>Total Duration</div>
                <div style={{flexGrow: 1}} />
                <div className={classNames(styles.cell, styles.cell4)}>Days Left</div>
                <div style={{flexGrow: 1}} />
                <div className={classNames(styles.cell, styles.cell2)}>Actions</div>
              </div>
              {stackingList
                .slice()
                .sort((a, b) => {
                  if (a.assetId === 'BBANK' && a.assetId !== b.assetId) {
                    return -1;
                  }
                  return 0;
                })
                .map(stake => {
                  return (
                    <div key={stake.id}>
                      <EarnRow stake={stake} />
                    </div>
                  );
                })}
            </>
          ) : (
            <div className={styles.clearStakeWrap}>
              <div className={styles.clearStake}>
                <div className={styles.clearStakeWrapTitle}>Lock $BBANK to Unlock Earning</div>
                <div className={styles.clearStakeWrapDescription}>
                  Lock $BBANK tokens to unlock earnings and get assigned a tier level to earn on your crypto.{' '}
                  <a
                    href='https://blockbank.ai/announcements/new-tier-system-and-referral-program/'
                    target='_blank'
                    style={{fontWeight: 600, cursor: 'pointer'}}
                    rel='noreferrer'
                  >
                    Learn More Here.
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(Earn);
