import {request} from './index';
import {kycLevel} from '../globalConstants';

export type TSignInDTO = {
  passwordHash: string;
  username: string;
  deviceType: string;
  appVersion: string;
};

export type TSignInResponse = {
  '2fa': boolean;
  temporaryPassword: boolean;
  token: string;
};

export type TSignUpDTO = {
  passwordHash: string;
  value: string;
  invitationCode: string | null;
  languageCode: string;
};

export type TSignUpResponse = {
  '2fa': boolean;
  temporaryPassword: boolean;
  token: string;
};

export type RegistrationConfirmRequest = {
  email: string;
  confirmationCode: string;
  passwordHash: string;
};

export type TCredentials = {
  type: string; // ENUM - EMail, Phone
  value: string;
  verified: boolean;
};

export enum UserStatus {
  Active = 'Active',
  KYCConfirmed = 'KYCConfirmed',
}

export type TUserResponse = {
  status: UserStatus; // ENUM - Active, KYCConfirmed
  hash: string;
  authenticationType: string; // ENUM - Simple, TwoFactor
  name: string;
  dateCreated: string;
  cardStatus: string; // ENUM -  ORDERED, REFUSED, UNKNOWN
  credentials: TCredentials[];
};

export type KycUserAccessTokenResponse = {
  token: string;
};

export type TPAuthenticatorResponse = {
  secret: string;
};

export type AuthenticationCodeRequest = {
  authenticationCode: string;
};

export interface TPValidTransportResponse {
  isValid: boolean;
}

export interface ChangeUserPasswordRequest {
  oldPasswordHash: string;
  newPasswordHash: string;
}

const getSelf = (): Promise<TUserResponse> => request({url: '/v2/authentication/get-self'});

const signIn = (data: TSignInDTO): Promise<TSignInResponse> => {
  return request({url: '/v2/authentication/sign-in', data});
};

const create = (data: TSignUpDTO): Promise<void> => request({url: '/users/create', data});

const sendRestorePasswordInstruction = (data: string): Promise<void> =>
  request({url: `/users/${data}/send-restore-password-instruction`});

const resendConfirmationCode = (): Promise<void> => request({url: '/users/confirmation/resend'});

const confirm = (data: RegistrationConfirmRequest): Promise<void> => request({url: '/credentials/confirm', data});

const twoFASignIn = (code: string): Promise<any> => request({url: `/v2/authentication/signIn-2FA/${code}`});

const getSumSubToken = (): Promise<KycUserAccessTokenResponse> =>
  request({url: `/v3/kyc/accessToken?level=${kycLevel() || 'basic-kyc-level'}`, method: 'GET'});

const sendMfaEmailCode = (): Promise<void> => request({url: '/users/send-mfa-email'});

const validateMfaEmailCode = (code: string): Promise<void> => request({url: `/users/validate-mfa-email?code=${code}`});

const generateTwoFASecret = (): Promise<TPAuthenticatorResponse> =>
  request({url: '/v2/authentication/generate-2fa-secret'});

const activateTwoFA = (data: AuthenticationCodeRequest): Promise<void> =>
  request({url: `/authentication/activate`, data});

const deactivateTwoFA = (data: AuthenticationCodeRequest): Promise<void> =>
  request({url: `/authentication/deactivate`, data});

const isValidTwoFACode = (code: string): Promise<TPValidTransportResponse> =>
  request({url: `/v2/authentication/is-valid-2fa-code/${code}`});

const changePassword = (data: ChangeUserPasswordRequest): Promise<void> =>
  request({url: '/users/user-change-password', data});

export default {
  signIn,
  create,
  sendRestorePasswordInstruction,
  resendConfirmationCode,
  confirm,
  getSelf,
  twoFASignIn,
  getSumSubToken,
  sendMfaEmailCode,
  validateMfaEmailCode,
  generateTwoFASecret,
  activateTwoFA,
  deactivateTwoFA,
  isValidTwoFACode,
  changePassword,
};
