import React from 'react';
import styles from './styles.module.scss';

type Props = {
  title: string;
  description: string;
};

const AuthSnackComponent: React.FC<Props> = ({title, description}) => {
  return (
    <>
      <div className={styles.firstBlock} />
      <div className={styles.secondBlock}>
        <div className={styles.snackWrap}>
          <p className={styles.title}>{title}</p>
          <p className={styles.description}>{description}</p>
        </div>
      </div>
    </>
  );
};

export default AuthSnackComponent;
