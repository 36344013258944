import React from 'react';
import classNames from 'classnames';
import {observer} from 'mobx-react';
import {useNavigate} from 'react-router-dom';
import styles from '../styles.module.scss';
import {theme, themeLogo} from '../../../../globalConstants';
import NavItem from '../NavItem';
import {useStores} from '../../../../stores';
import {Pages} from '../../../Router/types';
import {APP_ROUTES} from '../../../Router/constants';
import PortfolioSVG from '../NavItem/svg/PortfolioSVG';
import EarnSVG from '../NavItem/svg/EarnSVG';
import BankingSVG from '../NavItem/svg/BankingSVG';
import SupportSVG from '../NavItem/svg/SupportSVG';
import SettingSVG from '../NavItem/svg/SettingSVG';
import LogOutSVG from '../NavItem/svg/LogOutSVG';
import close from '../../../../assets/icons/close.svg';
import TransactionsSVG from '../NavItem/svg/TransactionsSVG';
import {useGlobalModalContext} from '../../Modals';
import {MODAL_TYPES} from '../../Modals/modalConstants';

const isMobile = window.matchMedia('(max-width: 767px)').matches;

const navItemsStartObject = {
  [Pages.PORTFOLIO]: {
    path: APP_ROUTES[Pages.Base],
    title: Pages.PORTFOLIO,
    icon: PortfolioSVG,
  },
  [Pages.EARN]: {
    path: APP_ROUTES[Pages.EARN],
    title: Pages.EARN,
    icon: EarnSVG,
    isSoon: theme === 'fideum',
  },
  [Pages.BANKING]: {
    path: APP_ROUTES[Pages.BANKING],
    title: Pages.BANKING,
    icon: BankingSVG,
  },
  [Pages.OTC]: {
    // OTC
    path: APP_ROUTES[Pages.OTC],
    title: Pages.OTC,
    icon: BankingSVG,
    isSoon: true,
  },
  [Pages.STAKING]: {
    // STAKING
    path: APP_ROUTES[Pages.EARN],
    title: Pages.STAKING,
    icon: BankingSVG,
    // isSoon: true,
  },
  [Pages.SUPPORT]: {
    path: APP_ROUTES[Pages.SUPPORT],
    title: Pages.SUPPORT,
    icon: SupportSVG,
  },
  [Pages.TRANSACTIONS]: {
    path: APP_ROUTES[Pages.TRANSACTIONS],
    title: Pages.TRANSACTIONS,
    icon: TransactionsSVG,
  },
};

const navItemsStart: any[] = [];

const BlockBankNavState = [Pages.PORTFOLIO, Pages.EARN, Pages.BANKING, Pages.SUPPORT];
const FideumNavState = [Pages.PORTFOLIO, Pages.EARN, Pages.BANKING, Pages.OTC, Pages.SUPPORT];
const KaizenNavState = [Pages.PORTFOLIO, Pages.BANKING, Pages.SUPPORT];

// TODO: change theme state
if (theme === 'kaizen') {
  KaizenNavState.forEach(item => {
    // @ts-ignore
    navItemsStart.push(navItemsStartObject[item]);
  });
}

if (theme === 'fideum') {
  FideumNavState.forEach(item => {
    // @ts-ignore
    navItemsStart.push(navItemsStartObject[item]);
  });
}

if (!theme) {
  BlockBankNavState.forEach(item => {
    // @ts-ignore
    navItemsStart.push(navItemsStartObject[item]);
  });
}

if (isMobile) {
  navItemsStart.push(navItemsStartObject[Pages.TRANSACTIONS]);
}

const navItemsEnd = [
  {
    path: APP_ROUTES[Pages.SETTINGS],
    title: Pages.SETTINGS,
    icon: SettingSVG,
  },
  {
    path: APP_ROUTES[Pages.SignIn],
    title: Pages.LOGOUT,
    icon: LogOutSVG,
  },
];

const NavPanel: React.FC = () => {
  const {unstoppableDomains, isMobileNavPanel, setIsMobileNavPanel, isMobileScreens} = useStores();
  const navigate = useNavigate();
  const {showModal, setModalStore} = useGlobalModalContext();

  const handleCloseMenu = (): void => {
    setIsMobileNavPanel(!isMobileNavPanel);
  };

  const goToModal = (type: string): void => {
    const options = {
      title: Pages.PORTFOLIO,
    };
    if (isMobileScreens) {
      setModalStore(options);
      navigate(`${APP_ROUTES[Pages.PORTFOLIO]}/${type}`);
    } else {
      showModal(type, options);
    }
  };

  return (
    <section className={classNames(styles.mainNavPanel, isMobileNavPanel ? {} : styles.deActivePanel)}>
      <div className={styles.closeNav} onClick={handleCloseMenu}>
        <img src={close} alt='' />
      </div>
      <div className={classNames(styles.navLinksContainer, styles.mobSecondContainer)}>
        <img src={themeLogo()} alt='' className={styles.bbLogo} />
        {navItemsStart.map(({path, title, icon, isSoon}) => {
          return <NavItem key={title} path={path} title={title} Icon={icon} isSoon={isSoon} />;
        })}
      </div>
      <div className={styles.navLinksContainer}>
        {theme ? null : (
          <div className={styles.udLink}>
            <div className={styles.udLinkContent}>
              <div className={styles.udLinkTitle}>
                {unstoppableDomains ? 'WEB3 Domain Details' : 'Get a Free WEB3 Domain'}
              </div>
              <div className={styles.udLinkDescription}>
                {unstoppableDomains
                  ? 'View your WEB3 domain settings and supported networks here.'
                  : 'In partnership with Unstoppable Domains we offer a free WEB3 domain.'}
              </div>
              <div
                onClick={() => {
                  goToModal(unstoppableDomains ? MODAL_TYPES.LIST_UD_MODAL : MODAL_TYPES.GET_UD_MODAL);
                  handleCloseMenu();
                }}
                className={styles.udLinkBtn}
              >
                {unstoppableDomains ? 'View Details' : 'Get Free WEB3 Domain'}
              </div>
            </div>
          </div>
        )}

        {navItemsEnd.map(({path, title, icon}) => {
          return <NavItem key={title} path={path} title={title} Icon={icon} />;
        })}
      </div>
    </section>
  );
};

export default observer(NavPanel);
