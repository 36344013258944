import React from 'react';
import danger from '../../../assets/icons/danger.svg';

type Props = {
  requestError: string;
};

const ErrorRedBlock: React.FC<Props> = ({requestError}) => {
  return (
    <div
      style={{
        height: 78,
        backgroundColor: 'rgba(255, 0, 0, 0.1)',
        margin: '24px 0',
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <img style={{height: 24, margin: '0 24px'}} alt='' src={danger} />
      <div style={{marginRight: 24}}>{requestError}</div>
    </div>
  );
};

export default ErrorRedBlock;
