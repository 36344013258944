import React, {useState} from 'react';
import styles from './styles.module.scss';
import {roundingBalance} from '../../../../utils/formats';
import {Currencies, useStores} from '../../../../stores';
import CardServices from '../../../../services/CardServices';
import ErrorRedBlock from '../../../common/ErrorRedBlock';

type Props = {
  goToTopUp: () => void;
  nextStep: () => void;
  setPermittedCountries: React.Dispatch<any>;
};

const StepOne: React.FC<Props> = ({nextStep, goToTopUp, setPermittedCountries}) => {
  const {assets, currency} = useStores();

  const [loading, setLoading] = useState(false);
  const [requestError, setRequestError] = useState('');

  const fiatAsset = assets.find(asset => asset.isFiat);

  return (
    <div className={styles.stepContentContainer}>
      <div className={styles.freeHistoryDescription} style={{textAlign: 'center', marginTop: 90}}>
        To continue with the application please complete the €50 deposit to your card balance.
      </div>
      <div className={styles.enterAmount}>Deposit Amount</div>
      <div className={styles.inputBlock}>
        <input placeholder='00.00' className={styles.input} value={50} disabled />
        <div style={{marginRight: 17}}>EUR</div>
      </div>
      <div className={styles.fiatBalanceAmount}>
        EUR Balance{' '}
        <span
          style={{
            color: Number(fiatAsset?.availableBalance || 0) >= 50 ? '#2B9A18' : 'red',
          }}
        >
          {Currencies.EUR}
          {roundingBalance(fiatAsset?.availableBalance || '0', 2)}
        </span>{' '}
      </div>

      {Number(fiatAsset?.availableBalance || 0) >= 50 ? (
        <div className={styles.freeHistoryDescription} style={{marginLeft: '.5rem', marginTop: 0, marginBottom: 64}}>
          Deposit will be deducted from your fiat balance.
        </div>
      ) : (
        <div
          className={styles.freeHistoryDescription}
          style={{marginLeft: '.5rem', marginTop: 0, marginBottom: 64, color: 'red', fontWeight: 500}}
        >
          Not enough fiat balance. Top up your balance to be able to buy.
        </div>
      )}

      <button
        onClick={async e => {
          e.preventDefault();
          setLoading(true);
          try {
            if (Number(fiatAsset?.availableBalance || 0) >= 50) {
              const step1 = await CardServices.submitOrderStatus({
                step: 'DEPOSIT',
                data: {
                  amount: '50',
                },
              });
              setPermittedCountries(step1?.additionalInfo?.countryCodes || []);
              nextStep();
            } else {
              goToTopUp();
            }
          } catch (error: any) {
            setRequestError(error.code || 'Deposit Error');
            console.log('submitOrderStatus(DEPOSIT)-ERROR', error);
          }
          setLoading(false);
        }}
        className='btn btn-primary'
        style={Number(fiatAsset?.availableBalance || 0) >= 50 ? {} : {backgroundColor: 'red'}}
      >
        {loading ? (
          <span className='spinner-border' />
        ) : (
          <div className={styles.btnTitle}>
            {Number(fiatAsset?.availableBalance || 0) >= 50 ? 'Deposit & Order Card' : 'Top Up Fiat Balance'}
          </div>
        )}
      </button>

      {requestError ? <ErrorRedBlock requestError={requestError} /> : null}
    </div>
  );
};

export default StepOne;
