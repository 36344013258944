import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import styles from './styles.module.scss';
import {useStores} from '../../../stores';
import {APP_ROUTES} from '../../Router/constants';
import {Pages} from '../../Router/types';
import {UserStatus} from '../../../services/AuthService';
import NavPanel from './NavPanel';

type Props = {
  component: JSX.Element;
};

const Main: React.FC<Props> = ({component}) => {
  const {user} = useStores();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || user?.status === null) {
      navigate(APP_ROUTES[Pages.ConfirmationCode]);
    }
    if (user?.status === UserStatus.Active) {
      navigate(APP_ROUTES[Pages.KYC]);
    }
  }, []);
  return (
    <div className={styles.main}>
      <NavPanel />
      {component}
    </div>
  );
};

export default Main;
