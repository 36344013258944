import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

type HintTextProps = {
  text: string;
  errors: string;
};

const HintPasswordText = ({text, errors}: HintTextProps): JSX.Element => {
  return (
    <div className={styles.passwordHintBlock}>
      <div className={styles.passwordHintContainer} />
      <p className={classNames(styles.text, errors.includes(text) ? '' : styles.textSuccess)}>{text}</p>
    </div>
  );
};

export default HintPasswordText;
