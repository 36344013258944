import React, {ReactElement, RefObject, useState} from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import hodlCard from './hodlCard.svg';
import CommonDropDown from '../../Modals/CommonModalComponents/CommonDropDown';
import CardServices from '../../../../services/CardServices';
import ErrorRedBlock from '../../../common/ErrorRedBlock';
import {countriesFormatted} from '../../../../globalConstants/countries';

export type TCountries = {
  name: string;
  code: string;
};

type Props = {
  containerRef: RefObject<HTMLInputElement>;
  selectedCountry: TCountries;
  setSelectedCountry: React.Dispatch<TCountries>;
  nextStep: () => void;
  permittedCountries: string[];
};

const StepTwo: React.FC<Props> = ({
  containerRef,
  selectedCountry,
  setSelectedCountry,
  nextStep,
  permittedCountries,
}) => {
  const [requestError, setRequestError] = useState('');
  const [loading, setLoading] = useState(false);

  const itemComponentCurrency = (currency: TCountries): ReactElement => {
    return <div className={styles.bankName}>{currency.name}</div>;
  };

  const countries: TCountries[] = permittedCountries
    .map(item => ({code: item, name: countriesFormatted[item]}))
    .sort((a, b) => {
      if (b.code === 'OTHER') return -1;
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

  return (
    <div className={styles.stepContentContainer}>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <img className={classNames(styles.cardImg, styles.cardImgTwo)} src={hodlCard} alt='' />
      </div>
      <div className={classNames(styles.enterAmount, styles.enterAmountTwo)}>Choose Your Country</div>

      <div className={styles.countryDropDownWrap}>
        <CommonDropDown
          containerRef={containerRef}
          data={countries}
          itemComponent={itemComponentCurrency}
          setSelectedData={setSelectedCountry}
          selectedData={selectedCountry}
        />
      </div>

      <button
        style={selectedCountry.name === 'Select Country' ? {opacity: 0.5} : {}}
        onClick={async e => {
          e.preventDefault();
          if (selectedCountry.name === 'Select Country') return;
          setLoading(true);
          try {
            await CardServices.submitOrderStatus({
              step: 'RESIDENCY',
              data: {
                countryCode: selectedCountry?.code || 'OTHER',
              },
            });

            nextStep();
          } catch (error: any) {
            setRequestError(error.code || 'RESIDENCY Error');
            console.log('submitOrderStatus(RESIDENCY)-ERROR', error);
          }
          setLoading(false);
        }}
        className='btn btn-primary'
      >
        {loading ? <span className='spinner-border' /> : <div>Next</div>}
      </button>

      {requestError ? <ErrorRedBlock requestError={requestError} /> : null}
    </div>
  );
};

export default StepTwo;
