import React, {useState, useCallback, useEffect, useRef, ReactElement} from 'react';
import close from '../../../../assets/icons/close.svg';
import styles from './styles.module.scss';
import {AssetsServices, DepositInfo} from '../../../../services/AssetsService';
import AuthSnackComponent from '../../Auth/common/AuthSnackComponent';
import {errorDescriptionHandler} from '../../Auth/authUtils/errorDescriptionHandler';
import {useStores} from '../../../../stores';
import Spinner from '../../../common/Spinner';
import QRCodeBlock from '../CommonModalComponents/QRCodeBlock';
import checked from '../../../../assets/icons/checked.svg';
import {EVENT_NAMES, useAnalytics} from '../../../../services/Analytics';
import CommonDropDown from '../CommonModalComponents/CommonDropDown';
import {useGlobalModalContext} from '../index';

const DepositModal: React.FC = (): JSX.Element => {
  const {setSnackComponent} = useStores();
  const {myLogEvent} = useAnalytics();
  const {hideModal, store} = useGlobalModalContext();
  const {asset} = store.modalProps;

  const containerRef = useRef<HTMLInputElement>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [availableNetworks, setAvailableNetworks] = useState<DepositInfo[]>([]);
  const [selectedNetwork, setSelectedNetwork] = useState<DepositInfo | null>(null);
  const [isCopied, setIsCopied] = useState(false);

  const getCoinAddress = useCallback(
    async (assetId: string) => {
      setIsLoading(true);

      try {
        const depositInfo = await AssetsServices.getDepositInfo(assetId);

        /* error for an empty array of addresses and networks */
        if (!depositInfo.length) throw new Error('backendErrors:FAILED_CODE_GENERATION');

        // eslint-disable-next-line no-restricted-syntax
        for (const depositInfoElement of depositInfo) {
          if (!depositInfoElement.depositAddress) {
            // eslint-disable-next-line no-await-in-loop
            const newAddress = await AssetsServices.createDepositAddress(assetId, depositInfoElement.networkId);
            depositInfoElement.depositAddress = newAddress.depositAddress;
          }
        }

        if (depositInfo && depositInfo.length === 1) setSelectedNetwork(depositInfo[0]);
        setAvailableNetworks(depositInfo);
      } catch (error: any) {
        console.log('ERROR-getCoinAddress', error);
        // TODO: title ?
        setSnackComponent(<AuthSnackComponent title='ERROR' description={errorDescriptionHandler(error.code || '')} />);
      }
      setIsLoading(false);
    },
    [asset]
  );

  useEffect(() => {
    if (asset) {
      getCoinAddress(asset.assetId).then(() => null);
    }
  }, [asset]);

  useEffect(() => {
    if (selectedNetwork) {
      myLogEvent(EVENT_NAMES.WEB_DEPOSIT, {
        asset: asset.assetId,
        address: selectedNetwork.depositAddress,
        networkId: selectedNetwork.networkId,
      });
    }
  }, [selectedNetwork]);

  const handleCopy = (): void => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };

  const itemComponentAddresses = (selectedItemAddress: DepositInfo): ReactElement => {
    if (!selectedItemAddress) return <div className={styles.bankName}>Choose Network</div>;
    return (
      <div>
        <div className={styles.bankName}>{selectedItemAddress?.networkId}</div>
      </div>
    );
  };

  return (
    <div ref={containerRef} className={styles.container} id='withdrawContainer'>
      <div className={styles.closeWrap} onClick={hideModal}>
        <img className={styles.closeIcon} alt='' src={close} />
      </div>
      <div className={styles.title}>Deposit {asset.name}</div>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className={styles.content}>
          {availableNetworks.length > 1 ? (
            <>
              <div className={styles.chooseNetwork}>Choose Network</div>
              <CommonDropDown
                containerRef={containerRef}
                data={availableNetworks}
                itemComponent={itemComponentAddresses}
                setSelectedData={setSelectedNetwork}
                selectedData={selectedNetwork}
              />
            </>
          ) : null}
          {selectedNetwork && (
            <QRCodeBlock
              copiedAction={handleCopy}
              dataString={selectedNetwork?.depositAddress || ''}
              isDeposit={selectedNetwork}
            />
          )}
          {isCopied && (
            <div
              style={{
                backgroundColor: '#262832',
                color: '#FFFFFF',
                height: 32,
                borderRadius: 100,
                display: 'flex',
                alignItems: 'center',
                padding: '0 11px',
                justifyContent: 'center',
                width: 136,
                margin: '30px auto',
              }}
            >
              <div style={{marginRight: 6, marginBottom: 4}}>Address Copied</div>
              <img alt='' src={checked} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DepositModal;
