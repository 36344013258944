import {FormProvider, useForm} from 'react-hook-form';
import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {Currencies, useStores} from '../../../../../stores';
import {ExchangeInputs} from '../Exchange/Exchange';
import {AssetsServices, ExchangeInfo} from '../../../../../services/AssetsService';
import {getBalanceString, roundingBalance} from '../../../../../utils/formats';
import Successfully from '../Successfull';
import TradeSummaryInfo from '../TradeSummaryInfo';
import styles from '../styles.module.scss';
import AssetsDropdown from '../../CommonModalComponents/AssetsDropdown';
import AmountInput from '../AmountInput';
import {initApp} from '../../../../../stores/actions/init';
import {EVENT_NAMES, useAnalytics} from '../../../../../services/Analytics';
import RequestError from '../../CommonModalComponents/RequestError';

const defaultValues = {
  fromAmountCrypto: '',
  fromAmountCurrency: '',
};

type Props = {
  asset: any;
};

const Sell: React.FC<Props> = ({asset}) => {
  const {assets, ratesCeFi} = useStores();
  const {myLogEvent} = useAnalytics();

  const methods = useForm<ExchangeInputs>({defaultValues});
  const {
    formState: {errors},
    watch,
    setValue,
    clearErrors,
    setError,
    reset,
  } = methods;

  const [selectedFromAsset, setSelectedFromAsset] = useState<any | null>(asset);
  const [isCurrency, setIsCurrency] = useState(false);
  const [focusName, setFocusName] = useState('');
  const [requestError, setRequestError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [exchangeInfo, setExchangeInfo] = useState<ExchangeInfo | null>(null);
  const [isSuccessfully, setIsSuccessfully] = useState(false);

  useEffect(() => {
    reset();
  }, [selectedFromAsset]);

  const eurData = assets.find((assetItem: any) => assetItem.assetId === 'EUR');
  const assetRateToEUR = ratesCeFi?.find(
    assetRaw => assetRaw.toAssetId === 'EUR' && assetRaw.fromAssetId === selectedFromAsset.assetId
  );
  const eurRate = assetRateToEUR ? assetRateToEUR.rate : 0;

  const watchFromAmountCrypto = watch('fromAmountCrypto');
  const watchFromAmountCurrency = watch('fromAmountCurrency');

  const changeAmountCurrency = (): void => {
    setIsCurrency(prev => !prev);
  };

  const assetEurRate = ratesCeFi.find(rate => rate.toAssetId === 'EUR' && rate.fromAssetId === asset.assetId);
  const eurAssetsList = ratesCeFi.filter(rate => rate.toAssetId === 'EUR').map(assetItem => assetItem.fromAssetId);

  const handleMax = (): void => {
    clearErrors();
    setValue('fromAmountCrypto', roundingBalance(selectedFromAsset.totalBalance, 8));
    setValue('fromAmountCurrency', roundingBalance((selectedFromAsset.totalBalance * (eurRate || 0)).toString(), 2));
  };

  useEffect(() => {
    if (focusName === 'fromAmountCrypto') {
      setValue('fromAmountCurrency', roundingBalance((+watchFromAmountCrypto * (eurRate || 1)).toString(), 2));
    }
    if (focusName === 'fromAmountCurrency') {
      setValue('fromAmountCrypto', roundingBalance((+watchFromAmountCurrency / (eurRate || 0)).toString(), 8));
    }

    if (+watchFromAmountCrypto > selectedFromAsset.totalBalance) {
      setError('fromAmountCrypto', {type: 'manual', message: 'ERROR-Crypto'});
    } else clearErrors();

    setRequestError('');
  }, [watchFromAmountCurrency, watchFromAmountCrypto]);

  const handleExchange = async (): Promise<void> => {
    setIsLoading(true);

    try {
      if (exchangeInfo) {
        await AssetsServices.exchangeAsset({
          amount: +watchFromAmountCrypto,
          info: exchangeInfo as ExchangeInfo,
        });

        myLogEvent(EVENT_NAMES.WEB_EXCHANGE, {...exchangeInfo});

        await initApp();

        setIsSuccessfully(true);
        setRequestError('');
      } else {
        const exchangeInfoData = await AssetsServices.exchangeInfoAsset({
          amount: +watchFromAmountCrypto,
          from: selectedFromAsset.assetId,
          to: 'EUR',
        });
        setExchangeInfo(exchangeInfoData);
        setRequestError('');
      }
    } catch (e: any) {
      console.log('ERROR-handleExchange', e);
      setRequestError(e.code);
    }
    setIsLoading(false);
  };

  if (isSuccessfully) {
    return <Successfully />;
  }

  return (
    <FormProvider {...methods}>
      {exchangeInfo && assetEurRate ? (
        <TradeSummaryInfo
          exchangeInfo={exchangeInfo}
          fromToRate={assetEurRate}
          selectedFromAsset={eurData}
          selectedToAsset={selectedFromAsset}
          watchFromAmountCrypto={(+watchFromAmountCrypto / (1 || 1)).toString()}
        />
      ) : (
        <>
          <div className={styles.title}>Sell</div>
          <div className={styles.description}>
            Please enter the details for the <br />
            transaction to proceed.
          </div>

          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div style={{width: '50%', position: 'relative'}}>
              <div className={styles.enterAmount}>Sell</div>
              <AssetsDropdown
                assets={assets.filter(
                  item => item.assetId !== selectedFromAsset.assetId && eurAssetsList.includes(item.assetId)
                )}
                selectedData={selectedFromAsset}
                setSelectedData={setSelectedFromAsset}
              />
            </div>
            <div style={{width: 5}} />
            <div style={{width: '50%'}}>
              <div className={styles.enterAmount}>Amount</div>
              <AmountInput
                currencyAmount={watchFromAmountCurrency}
                cryptoAmount={watchFromAmountCrypto}
                asset={selectedFromAsset}
                changeAmountCurrency={changeAmountCurrency}
                isCurrency={isCurrency}
                direction='from'
                setFocusName={setFocusName}
                methods={methods}
                isEurFixed
              />
            </div>
          </div>
          <div style={{display: 'flex', alignItems: 'center', marginTop: 13, justifyContent: 'space-between'}}>
            <div className={styles.balance}>Balance: {getBalanceString(+selectedFromAsset.totalBalance, 8)}</div>
            <div onClick={handleMax} className={styles.max}>
              MAX
            </div>
          </div>
          <div className={styles.enoughBalanceEur}>
            {errors.fromAmountCrypto && errors.fromAmountCrypto?.type === 'manual'
              ? 'Not enough fiat balance. Top up your balance to be able to buy.'
              : ' '}
          </div>
          <div style={{height: 58}} />
          <div className={styles.enterAmount}>Total</div>
          <div className={styles.totalAmount}>
            {Currencies.EUR} {watchFromAmountCurrency || 0}
          </div>
          <div style={{width: '100%', borderTop: '1px solid rgba(38, 40, 50, 0.1)', margin: '25px 0'}} />
          <div className={styles.conversionRate}>
            Conversion Rate: 1 {selectedFromAsset.assetId} = {Currencies.EUR} {eurRate}
          </div>
        </>
      )}

      <div style={{height: 30}} />
      <button
        className='btn btn-primary'
        disabled={isLoading}
        style={errors.fromAmountCrypto && errors.fromAmountCrypto?.type === 'manual' ? {backgroundColor: 'red'} : {}}
        onClick={async () => {
          if (errors.fromAmountCrypto && errors.fromAmountCrypto?.type === 'manual') {
            handleMax();
          } else {
            await handleExchange();
          }
        }}
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        {isLoading ? (
          <span className='spinner-border' />
        ) : // eslint-disable-next-line no-nested-ternary
        errors.fromAmountCrypto && errors.fromAmountCrypto?.type === 'manual' ? (
          'Top Up Balance'
        ) : exchangeInfo ? (
          'Confirm Sell'
        ) : (
          'Sell'
        )}
      </button>

      {exchangeInfo && assetEurRate && (
        <button
          className={classNames('btn', 'btn-primary', styles.backBtn)}
          onClick={e => {
            e.preventDefault();
            setExchangeInfo(null);
            setRequestError('');
          }}
        >
          Back
        </button>
      )}

      {requestError ? <RequestError requestError={requestError} /> : null}
    </FormProvider>
  );
};

export default Sell;
