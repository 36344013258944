import React, {useState} from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import close from '../../../../assets/icons/close.svg';
import {useStores} from '../../../../stores';
import copyWhite from '../../../../assets/icons/copy-white.svg';
import Copied from '../../../common/Copied/Copied';
import {useGlobalModalContext} from '../index';

const ListUDModal: React.FC = () => {
  const {unstoppableDomains} = useStores();
  const {hideModal} = useGlobalModalContext();

  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = (): void => {
    navigator.clipboard.writeText(unstoppableDomains?.meta.domain || '').then(() => null);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1000);
  };

  return (
    <div className={styles.container}>
      <div className={styles.closeWrap} onClick={hideModal}>
        <img className={styles.closeIcon} alt='' src={close} />
      </div>
      <div className={styles.title}>Your WEB3 Domain</div>
      <div className={styles.contentWrap}>
        <div onClick={handleCopy} className={styles.unstoppable}>
          {unstoppableDomains?.meta.domain}
          <img className={styles.copyWhiteIcon} alt='' src={copyWhite} />
        </div>
        <div className={styles.supportedTitle}>Supported Networks</div>
        <div style={{flexGrow: 1}}>
          <div className={styles.resultList}>
            {Object.entries(unstoppableDomains?.records || {}).map(item => {
              const networkName = (item[0] || '')
                .replace('crypto.', '')
                .replace('.address', '')
                .replace('.version.', ' ')
                .replace('BNB', 'BSC');

              if (networkName.includes('ipfs.html.value')) return null;
              return (
                <div key={item[0]} className={styles.resultItem}>
                  <div style={{fontFamily: 'Lexend', fontWeight: 600, minWidth: '30%'}}>Address {networkName}</div>
                  <div>{item[1]}</div>
                </div>
              );
            })}
          </div>
        </div>
        <button onClick={handleCopy} className={classNames('btn', 'btn-primary', styles.copyBtn)}>
          Copy WEB3 Domain
        </button>
        {isCopied ? <Copied /> : <div style={{height: 102}} />}
      </div>
    </div>
  );
};

export default ListUDModal;
