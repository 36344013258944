import React from 'react';
import {useNavigate} from 'react-router-dom';
import styles from '../../styles.module.scss';
import triangle from '../../../../../assets/icons/triangle.svg';
import TriangleSvgComponent from '../../../../../assets/icons/TriangleSvgComponent';

type Props = {
  backFn?: () => void;
};

const BackBtn: React.FC<Props> = ({backFn}) => {
  const navigate = useNavigate();

  const handleBack = (): void => {
    if (backFn) {
      backFn();
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <div className={styles.backBtnMobile} />
      <div onClick={handleBack} className={styles.backBtn}>
        <div className={styles.btnIcon}>
          <TriangleSvgComponent fill='var(--mainBlue)' />
        </div>
        <p className={styles.btnText}>Back</p>
      </div>
    </>
  );
};

export default BackBtn;
