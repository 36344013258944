import React, {useState} from 'react';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import i18n from '../../../../localize';
import styles from '../styles.module.scss';
import BackBtn from '../common/BackBtn';
import AuthTitle from '../common/AuthTitle';
import I18n from '../../../common/I18n';
import {APP_ROUTES} from '../../../Router/constants';
import {Pages} from '../../../Router/types';
import AuthService from '../../../../services/AuthService';
import {useStores} from '../../../../stores';
import {saveToken} from '../../../../utils/localStorageUtils';
import AuthSnackComponent from '../common/AuthSnackComponent';
import {errorDescriptionHandler} from '../authUtils/errorDescriptionHandler';
import {initApp} from '../../../../stores/actions/init';
import AuthLayout from '../AuthLayout';

type Inputs = {
  code: string;
};

const defaultValues = {
  code: '',
};

const TwoFactorAuthentication: React.FC = () => {
  const {setUser, setSnackComponent, setIsBlockUpdate} = useStores();
  const methods = useForm<Inputs>({defaultValues});
  const {t} = i18n;
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = methods;

  const onSubmit: SubmitHandler<Inputs> = async data => {
    setLoading(true);
    try {
      const {token, temporaryPassword} = await AuthService.twoFASignIn(data.code);
      if (temporaryPassword) {
        // TODO: temporaryPassword
      } else {
        setIsBlockUpdate(false);
        saveToken(token);
        const userSelf = await AuthService.getSelf();
        await setUser(userSelf);
        await initApp();

        navigate(APP_ROUTES[Pages.Base]);
      }
    } catch (error: any) {
      console.log('ERROR-twoFASignIn', error);
      // TODO: title ?
      setSnackComponent(
        <AuthSnackComponent title='Code is Incorrect.' description={errorDescriptionHandler(error.code || '')} />
      );
    }
    setLoading(false);
  };

  return (
    <AuthLayout>
      <div className={styles.rightModule}>
        <BackBtn />

        <div className={styles.formWrap}>
          <AuthTitle title='twoFactorAuthentication.title' description='twoFactorAuthentication.description' />
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='input-item-wrap'>
                <label htmlFor='code' className={`input-label ${errors.code ? 'text-error' : ''}`}>
                  {t('twoFactorAuthentication.code')}{' '}
                  {errors.code && errors.code.type === 'required' ? t('inputError.required') : ''}
                </label>
                <input
                  style={errors.code ? {outline: '1px solid red'} : {}}
                  id='code'
                  type='text'
                  aria-invalid={!!errors.code}
                  className='input-form'
                  placeholder={t('twoFactorAuthentication.placeholder') || ''}
                  {...register('code', {
                    required: true,
                  })}
                />
              </div>

              <button type='submit' className='btn btn-primary' disabled={loading}>
                {loading ? <span className='spinner-border' /> : <I18n tKey='signIn.title' />}
              </button>
            </form>
          </FormProvider>
        </div>
      </div>
    </AuthLayout>
  );
};

export default TwoFactorAuthentication;
