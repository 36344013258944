import React, {useState} from 'react';
import classNames from 'classnames';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {NavLink, useNavigate} from 'react-router-dom';
import i18n from '../../../../localize';
import styles from '../styles.module.scss';
import I18n from '../../../common/I18n';
import {REGEX, termsOfUseLink} from '../../../../globalConstants';
import eye from '../../../../assets/icons/eye.svg';
import AuthTitle from '../common/AuthTitle';
import {APP_ROUTES} from '../../../Router/constants';
import {Pages} from '../../../Router/types';
import AuthService from '../../../../services/AuthService';
import {save2faStatus, saveToken} from '../../../../utils/localStorageUtils';
import eyeOff from '../../../../assets/icons/eye-off.svg';
import {hash} from '../../../../utils/hash';
import BackBtn from '../common/BackBtn';
import HintPasswordText from './HintPasswordText';
import AuthSnackComponent from '../common/AuthSnackComponent';
import {errorDescriptionHandler} from '../authUtils/errorDescriptionHandler';
import {useStores} from '../../../../stores';
import {EVENT_NAMES, useAnalytics} from '../../../../services/Analytics';
import AuthLayout from '../AuthLayout';
import packageJson from '../../../../../package.json';

type Inputs = {
  email: string;
  password: string;
  repeatPassword: string;
  checkBox: boolean;
  referral: string;
};

const defaultValues = {
  email: '',
  password: '',
  repeatPassword: '',
  checkBox: false,
  referral: '',
};

const SignUp: React.FC = () => {
  const {setSnackComponent} = useStores();
  const {myLogEvent} = useAnalytics();
  const navigate = useNavigate();

  const {t} = i18n;
  const methods = useForm<Inputs>({defaultValues, mode: 'onChange'});
  const {
    register,
    handleSubmit,
    formState: {errors},
    getValues,
    trigger,
  } = methods;

  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordIsFocus, setPasswordIsFocus] = useState(false);

  const hints = [
    t('inputError.password.min'),
    t('inputError.password.uppercase'),
    t('inputError.password.lowercase'),
    t('inputError.password.number'),
    t('inputError.password.sign'),
  ];

  const toggleShowPassword = (): void => {
    setShowPassword(prevState => !prevState);
  };

  const toggleShowConfirmPassword = (): void => {
    setShowConfirmPassword(prevState => !prevState);
  };

  const onSubmit: SubmitHandler<Inputs> = async data => {
    setLoading(true);

    try {
      await AuthService.create({
        passwordHash: hash(data.password),
        value: data.email.trim(),
        invitationCode: !data.referral ? null : data.referral,
        languageCode: i18n.language,
      });
      const res = await AuthService.signIn({
        passwordHash: hash(data.password),
        username: data.email.trim(),
        deviceType: 'web',
        appVersion: packageJson.version,
      });

      saveToken(res.token);
      save2faStatus(res['2fa']);

      myLogEvent(EVENT_NAMES.WEB_SIGN_UP_SUCCESS);

      navigate(APP_ROUTES[Pages.ConfirmationCode], {
        state: {email: data.email.trim(), passwordHash: hash(data.password)},
      });
    } catch (error: any) {
      console.log('ERROR-signIn', error);
      // TODO: title ?
      setSnackComponent(
        <AuthSnackComponent title='Email Incorrect.' description={errorDescriptionHandler(error.code || '')} />
      );
    }

    setLoading(false);
  };

  const isSamePassword = (repeatPassword: string): boolean => {
    const password = getValues('password');
    return repeatPassword === password;
  };

  const passwordHintsClassName = (): string => {
    if (passwordIsFocus && errors.password) {
      return classNames(styles.hintPassword, errors.password?.message ? styles.fadeIn : styles.fadeOut);
    }
    return classNames(styles.hintPassword, styles.hidePasswordHint);
  };

  const validateHintPassword = (data: string): boolean | string => {
    const errorArray = [];

    const regex8Characters = /^.{8,}$/;
    const isMin8Characters = regex8Characters.test(data);
    if (!isMin8Characters) errorArray.push('Min 8 characters');

    const regexOneUppercase = /.*[A-Z].*/;
    const isOneUppercase = regexOneUppercase.test(data);
    if (!isOneUppercase) errorArray.push('One Uppercase');

    const regexOneLowercase = /.*[a-z].*/;
    const isOneLowercase = regexOneLowercase.test(data);
    if (!isOneLowercase) errorArray.push('One Lowercase');

    const regexOneNumber = /\d+/;
    const isOneNumber = regexOneNumber.test(data);
    if (!isOneNumber) errorArray.push('One Number');

    const regexOneSign = /[\W_]/;
    const isOneSign = regexOneSign.test(data);
    if (!isOneSign) errorArray.push('One Sign');

    const errorStrings = errorArray.join(',');

    return !errorArray.length ? true : errorStrings;
  };

  return (
    <AuthLayout>
      <div className={styles.rightModule}>
        <BackBtn />

        <div className={styles.formWrap}>
          <AuthTitle title='signUp.title' description='signUp.description' />
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='input-item-wrap'>
                <label htmlFor='email' className={`input-label ${errors.email ? 'text-error' : ''}`}>
                  {t('signIn.email')} {errors.email && errors.email.type === 'pattern' ? t('inputError.invalid') : ''}
                  {errors.email && errors.email.type === 'required' ? t('inputError.required') : ''}
                </label>
                <input
                  id='email'
                  type='text'
                  style={errors.email ? {outline: '1px solid red'} : {}}
                  className='input-form'
                  placeholder={t('signIn.email.placeholder') || ''}
                  {...register('email', {
                    required: true,
                    pattern: {
                      value: REGEX.EMAIL_VALIDATION,
                      message: 'Invalid email address',
                    },
                  })}
                />
              </div>

              <div className='input-item-wrap'>
                <label htmlFor='password' className={`input-label ${errors.password ? 'text-error' : ''}`}>
                  {t('signIn.password')}{' '}
                </label>
                <div className='input-password-wrap'>
                  <div className={passwordHintsClassName()}>
                    {hints.map(text => {
                      return <HintPasswordText key={text} text={text} errors={errors.password?.message || ''} />;
                    })}
                  </div>
                  <input
                    id='password'
                    type={showPassword ? 'text' : 'password'}
                    className='input-form'
                    style={errors.password ? {border: '1px solid red'} : {}}
                    placeholder={t('signUp.password.placeholder') || ''}
                    onFocus={async () => {
                      setPasswordIsFocus(true);
                      await trigger('password');
                    }}
                    {...register('password', {
                      required: hints.join(''),
                      validate: validateHintPassword,
                      onBlur: () => setPasswordIsFocus(false),
                    })}
                  />
                  <div onClick={toggleShowPassword}>
                    <img src={showPassword ? eyeOff : eye} alt='' className='icon-eye' />
                  </div>
                </div>
              </div>

              <div className='input-item-wrap'>
                <label htmlFor='repeat-password' className={`input-label ${errors.repeatPassword ? 'text-error' : ''}`}>
                  {t('signUp.password.repeat')}{' '}
                  {errors.repeatPassword && errors.repeatPassword.type === 'required' ? t('inputError.required') : ''}
                  {errors.repeatPassword && errors.repeatPassword.type === 'validate'
                    ? t('inputError.samePassword')
                    : ''}
                </label>
                <div className='input-password-wrap'>
                  <input
                    id='repeat-password'
                    type={showConfirmPassword ? 'text' : 'password'}
                    className='input-form'
                    style={errors.repeatPassword ? {outline: '1px solid red'} : {}}
                    placeholder={t('signUp.password.placeholder.repeat') || ''}
                    {...register('repeatPassword', {required: true, min: 0, validate: isSamePassword})}
                  />
                  <div onClick={toggleShowConfirmPassword}>
                    <img src={showConfirmPassword ? eyeOff : eye} alt='' className='icon-eye' />
                  </div>
                </div>
              </div>

              <div className='input-item-wrap'>
                <label htmlFor='referral' className='input-label'>
                  <I18n tKey='signUp.referral' />
                </label>
                <input
                  id='referral'
                  type='text'
                  className='input-form'
                  placeholder={t('signUp.referral.placeholder') || ''}
                  {...register('referral')}
                />
              </div>

              <label htmlFor='checkBox' className={classNames(styles.container)}>
                <I18n tKey='signUp.agree' />
                <input id='checkBox' type='checkbox' {...register('checkBox', {required: true})} />
                <span style={errors.checkBox ? {outline: '1px solid red'} : {}} className={styles.checkmark} />
                <NavLink to={termsOfUseLink()} target='_blank' className='input-label form-link'>
                  {t('signUp.termsConditions')}
                </NavLink>
              </label>

              <button type='submit' className='btn btn-primary' disabled={loading}>
                {loading ? <span className='spinner-border' /> : <I18n tKey='signUp.createAccount' />}
              </button>

              <div className='input-item-wrap text-under-button'>
                <p className='input-label text-center'>
                  {t('signUp.haveAccount')}
                  <NavLink to={APP_ROUTES[Pages.SignIn]} className='input-label form-link'>
                    {t('signIn.title')}
                  </NavLink>
                </p>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </AuthLayout>
  );
};

export default SignUp;
