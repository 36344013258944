import React, {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useStores} from '../../../../../stores';
import styles from './styles.module.scss';
import copyWhite from '../../../../../assets/icons/copy-white.svg';
import Copied from '../../../../common/Copied/Copied';
import transactions from '../../../../../assets/icons/transactions.svg';
import menu from '../../../../../assets/icons/menu.svg';
import {theme} from '../../../../../globalConstants';
import {Pages} from '../../../../Router/types';
import {useGlobalModalContext} from '../../../Modals';
import {MODAL_TYPES} from '../../../Modals/modalConstants';
import {APP_ROUTES} from '../../../../Router/constants';

type Props = {
  headerTitle: string;
  showBackBtn?: boolean;
  hideUd?: boolean;
};

const HeaderTitle: React.FC<Props> = ({headerTitle, showBackBtn, hideUd}) => {
  const {unstoppableDomains, isMobileNavPanel, setIsMobileNavPanel} = useStores();
  const navigate = useNavigate();
  const location = useLocation();
  // location.pathname !== path
  // APP_ROUTES[Pages.Base]
  const {showModal} = useGlobalModalContext();

  const [isCopied, setIsCopied] = useState(false);

  const goToModal = (type: string): void => {
    const options = {
      title: Pages.PORTFOLIO,
    };
    showModal(type, options);
  };

  const transactionsShow = [APP_ROUTES[Pages.Base], APP_ROUTES[Pages.EARN]];

  return (
    <div className={styles.headerTitleWrap}>
      {showBackBtn ? (
        <div onClick={() => navigate(-1)} className={styles.backBtnWrap}>
          <div className={styles.backBtn}>
            {`<`} Portfolio / <div className={styles.assetName}>&nbsp;{headerTitle}</div>
          </div>
        </div>
      ) : (
        <div className={styles.titleWrap}>
          <p className={styles.title}>{headerTitle}</p>

          {!hideUd && !theme && unstoppableDomains && unstoppableDomains.meta && unstoppableDomains.meta.domain && (
            <div
              onClick={() => {
                setIsCopied(true);
                navigator.clipboard.writeText(unstoppableDomains.meta.domain).then(() => null);
                setTimeout(() => setIsCopied(false), 1000);
              }}
              className={styles.unstoppable}
            >
              {isCopied ? (
                <div className={styles.copiedUd}>
                  <Copied />
                </div>
              ) : (
                <div className={styles.unstoppableName}>
                  <div>{unstoppableDomains.meta.domain}</div>
                  <div>
                    <img className={styles.copyWhiteIcon} alt='' src={copyWhite} />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {transactionsShow.includes(location.pathname) ? (
        <div style={{display: 'flex'}}>
          <div onClick={() => goToModal(MODAL_TYPES.TRANSACTIONS_MODAL)} className={styles.transactionsBtn}>
            <img style={{marginRight: 12}} src={transactions} alt='' />
            <div>Transactions</div>
          </div>
        </div>
      ) : null}

      <div onClick={() => setIsMobileNavPanel(!isMobileNavPanel)} className={styles.navMenu}>
        <img src={menu} alt='' />
      </div>
    </div>
  );
};

export default HeaderTitle;
