import {documentTitle, favicon} from '../globalConstants';

export const updateFavicon = (): void => {
  const head = document.getElementsByTagName('head')[0];
  const links = head.getElementsByTagName('link');
  const icon = favicon() || '';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < links.length; i++) {
    const lnk = links[i];
    if (lnk.rel === 'icon') {
      head.removeChild(lnk);
    }
    const link = document.createElement('link');
    link.setAttribute('href', icon);
    link.setAttribute('type', 'image/x-icon');
    link.setAttribute('rel', 'icon');
    head.appendChild(link);
  }
  document.title = documentTitle();
};
