import React, {useEffect, useRef, useState} from 'react';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import classNames from 'classnames';
import styles from './styles.module.scss';
import card from './hodlCard.svg';
import CardOrderInfoModal from '../../Modals/CardOrderInfoModal';
import StepContainer from './StepContainer';
import StepOne from './StepOne';
import StepTwo, {TCountries} from './StepTwo';
import i18n from '../../../../localize';
import StepFour from './StepFour';
import StepFive from './StepFive';
import PhoneService from '../../../../services/PhoneService';
import CardServices from '../../../../services/CardServices';
import Spinner from '../../../common/Spinner';
import ErrorRedBlock from '../../../common/ErrorRedBlock';
import {theme} from '../../../../globalConstants';
import FideumStartPage from './FideumStartPage';

type Inputs = {
  addressLine1: string;
  addressLine2: string;
  city: string;
  zipCode: string;
};

const defaultValues = {
  addressLine1: '',
  addressLine2: '',
  city: '',
  zipCode: '',
};

type Props = {
  goToTopUp: () => void;
};

const StartPageCard: React.FC<Props> = ({goToTopUp}) => {
  const containerRef = useRef<HTMLInputElement>(null);

  const {t} = i18n;
  const methods = useForm<Inputs>({defaultValues});
  const {
    register,
    handleSubmit,
    formState: {errors},
    watch,
  } = methods;

  const watchAddressLine1 = watch('addressLine1');
  const watchAddressLine2 = watch('addressLine2');
  const watchCity = watch('city');
  const watchZipCode = watch('zipCode');
  const [phone, setPhone] = useState('');
  const [blockPhone, setBlockPhone] = useState(false);
  const [submittedData, setSubmittedData] = useState<any>(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [isOpenInfo, setIsOpenInfo] = useState(false);
  const [activeStep, setActiveStep] = useState<number | null>(null);

  const [loading, setLoading] = useState(false);
  const [requestError, setRequestError] = useState('');

  const [selectedCountry, setSelectedCountry] = useState<TCountries>({name: 'Select Country', code: 'OTHER'});
  const [permittedCountries, setPermittedCountries] = useState([]);

  const getCardStep = async (): Promise<void> => {
    const cardData = await CardServices.getOrderStatus();
    if (cardData.currentStep === 'DEPOSIT') {
      setPermittedCountries(cardData?.additionalInfo?.countryCodes || []);
      setActiveStep(2);
    } else if (cardData.currentStep === 'RESIDENCY') {
      setActiveStep(3);
    } else if (cardData.currentStep === 'ADDRESS') {
      setActiveStep(4);
      if (cardData?.additionalInfo?.phone) {
        setPhone(cardData.additionalInfo.phone);
        setBlockPhone(true);
      }
    } else if (cardData.currentStep === 'PHONE') {
      setActiveStep(5);
    } else if (cardData.currentStep === 'TERMS') {
      setActiveStep(5);
      setSubmittedData(cardData.additionalInfo);
    } else if (cardData.currentStep === 'SUBMITTED') {
      setActiveStep(0);
      setIsSubmitted(true);
    } else if (cardData.currentStep === 'BLOCKED') {
      setActiveStep(0);
      setIsSubmitted(true);
    } else {
      setActiveStep(0);
    }
  };

  useEffect(() => {
    getCardStep().catch((e: any) => {
      console.log('getCardStep-ERROR', e);
      setActiveStep(0);
      if (e.code === 'UNKNOWN') {
        setRequestError('Card service is not available');
      } else {
        setRequestError(e.code);
      }
    });
  }, []);

  const nextStep = (): void => {
    setActiveStep((activeStep || 0) + 1);
  };

  const prevStep = (): void => {
    setActiveStep((activeStep || 0) - 1);
  };

  const onSubmit: SubmitHandler<Inputs> = async () => {
    setLoading(true);
    try {
      const addressStep = await CardServices.submitOrderStatus({
        step: 'ADDRESS',
        data: {
          address1: watchAddressLine1,
          address2: watchAddressLine2,
          city: watchCity,
          postalCode: watchZipCode,
        },
      });
      const usersPhone = await PhoneService.getUserPhone();
      if (usersPhone.phone) {
        setPhone(usersPhone.phone || '');
        setBlockPhone(true);
      }
    } catch (e: any) {
      console.log('ERROR-PhoneService. | ADDRESS', e);
      // setRequestError(e.code || 'submitOrderStatus(ADDRESS)-ERROR');
    }
    setLoading(false);
    nextStep();
  };

  if (activeStep === null) {
    return (
      <div className={styles.transferWrap} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Spinner />
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <form
        className={styles.transferWrap}
        onSubmit={handleSubmit(onSubmit)}
        style={
          isSubmitted
            ? {background: 'linear-gradient(to right top, #9A95CE, #F5F6F6)'}
            : // TODO: theme control
              {visibility: theme ? 'hidden' : 'visible'}
        }
      >
        <div
          className={classNames(styles.cardWrap, isSubmitted ? styles.cardWrapSubmitted : {})}
          ref={containerRef}
          id='withdrawContainer'
        >
          {isSubmitted ? (
            <div className={styles.cardTitleSubmitted} />
          ) : (
            <>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div className={classNames(styles.balanceTitle, styles.cardTitle)}>Card</div>
              </div>
              <div className={styles.divider} />
            </>
          )}

          {activeStep === 0 ? (
            <div className={styles.cardImageWrap}>
              <img className={styles.cardImg} src={card} alt='' />
              <div className={styles.freeHistory} style={isSubmitted ? {color: '#FFFFFF'} : {}}>
                {isSubmitted ? (
                  <div className={styles.freeHistorySubmitted}>Congrats! Your card order is submitted.</div>
                ) : (
                  <div>
                    Seamless Crypto Spending <br />
                    Starts Here
                  </div>
                )}
              </div>
              <div className={styles.freeHistoryDescription} style={isSubmitted ? {color: '#FFFFFF'} : {}}>
                {isSubmitted ? (
                  // eslint-disable-next-line max-len
                  <div className={styles.freeHistoryDescriptionSubmitted}>
                    We are processing your card order. You will receive a notification once your card is issued.
                  </div>
                ) : (
                  // eslint-disable-next-line max-len
                  'Unleash the power of borderless banking with the blockbank HODL card. Spend your crypto easily and securely all around the world.'
                )}
              </div>
              {isSubmitted ? null : (
                <button
                  onClick={e => {
                    e.preventDefault();
                    setIsOpenInfo(true);
                  }}
                  className={classNames('btn', 'btn-primary', styles.orderBtn)}
                  // style={{maxWidth: 440}}
                >
                  <div>Order My Card</div>
                </button>
              )}
              {requestError ? <ErrorRedBlock requestError={requestError} /> : null}
            </div>
          ) : null}

          {activeStep && activeStep >= 1 ? (
            <div style={{width: '100%'}}>
              <StepContainer activeStep={activeStep} />
            </div>
          ) : null}

          {activeStep === 1 ? (
            <StepOne goToTopUp={goToTopUp} nextStep={nextStep} setPermittedCountries={setPermittedCountries} />
          ) : null}

          {activeStep === 2 ? (
            <StepTwo
              containerRef={containerRef}
              setSelectedCountry={setSelectedCountry}
              selectedCountry={selectedCountry}
              nextStep={nextStep}
              permittedCountries={permittedCountries}
            />
          ) : null}

          {activeStep === 3 ? (
            <>
              <div className={classNames(styles.freeHistoryDescription, styles.freeHistoryDescriptionThree)}>
                Please fill in or confirm your address details below. This is important, as we&#39;ll use this
                information to securely send your HODL Card.
              </div>
              <div className={styles.stepContentContainer}>
                <div className='input-item-wrap'>
                  <label htmlFor='addressLine1' className={`input-label ${errors.addressLine1 ? 'text-error' : ''}`}>
                    Address Line 1{' '}
                    {errors.addressLine1 && errors.addressLine1.type === 'required' ? t('inputError.required') : ''}
                  </label>
                  <input
                    id='addressLine1'
                    type='text'
                    className='input-form'
                    style={errors.addressLine1 ? {outline: '1px solid red'} : {}}
                    placeholder='Address Line 1'
                    {...register('addressLine1', {required: true})}
                  />
                </div>
                <div className='input-item-wrap'>
                  <label htmlFor='addressLine1' className='input-label'>
                    Address Line 2{' '}
                  </label>
                  <input
                    id='addressLine2'
                    type='text'
                    className='input-form'
                    style={errors.addressLine2 ? {outline: '1px solid red'} : {}}
                    placeholder='Address Line 2'
                    {...register('addressLine2')}
                  />
                </div>
                <div className='input-item-wrap'>
                  <label htmlFor='city' className={`input-label ${errors.city ? 'text-error' : ''}`}>
                    City {errors.city && errors.city.type === 'required' ? t('inputError.required') : ''}
                  </label>
                  <input
                    id='city'
                    type='text'
                    className='input-form'
                    style={errors.city ? {outline: '1px solid red'} : {}}
                    placeholder='City'
                    {...register('city', {required: true})}
                  />
                </div>
                <div className='input-item-wrap'>
                  <label htmlFor='zipCode' className={`input-label ${errors.zipCode ? 'text-error' : ''}`}>
                    Zip Code {errors.zipCode && errors.zipCode.type === 'required' ? t('inputError.required') : ''}
                  </label>
                  <input
                    id='zipCode'
                    type='text'
                    className='input-form'
                    style={errors.zipCode ? {outline: '1px solid red'} : {}}
                    placeholder='Zip Code'
                    {...register('zipCode', {required: true})}
                  />
                </div>
                <div className={styles.stepThreeBtnWrap}>
                  <button type='submit' className='btn btn-primary'>
                    {loading ? <span className='spinner-border' /> : <div>Next</div>}
                  </button>

                  {requestError ? <ErrorRedBlock requestError={requestError} /> : null}
                </div>
              </div>
            </>
          ) : null}

          {activeStep === 4 ? (
            <StepFour nextStep={nextStep} phone={phone} setPhone={setPhone} blockPhone={blockPhone} />
          ) : null}

          {activeStep === 5 ? (
            <StepFive submittedData={submittedData} setActiveStep={setActiveStep} setIsSubmitted={setIsSubmitted} />
          ) : null}

          {isOpenInfo ? (
            <CardOrderInfoModal
              modalIsOpen={isOpenInfo}
              closeModal={() => setIsOpenInfo(false)}
              goNext={() => {
                setActiveStep(1);
                setIsOpenInfo(false);
              }}
            />
          ) : null}
        </div>
      </form>
    </FormProvider>
  );
};

export default StartPageCard;
