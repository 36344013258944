import React from 'react';
import {useNavigate} from 'react-router-dom';
import styles from './styles.module.scss';
import {APP_ROUTES} from '../../../Router/constants';
import {Pages} from '../../../Router/types';

const DeleteAccount: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.settingsItem}>
      <div className={styles.settingsItemContent}>
        <div className={styles.twoFaTitleWrap}>
          <div className={styles.settingsItemTitle}>Delete Account</div>
        </div>

        <div className={styles.settingsItemDescription}>
          We will be sad to see you go, but you always have the option to break our hearts....
        </div>

        <div
          onClick={() => {
            navigate(APP_ROUTES[Pages.DELETE_ACCOUNT]);
          }}
          className={styles.twoFaBtn}
        >
          Delete Account
        </div>
      </div>
    </div>
  );
};

export default DeleteAccount;
