import React, {ReactElement, useEffect, useRef, useState} from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import classNames from 'classnames';
import styles from './styles.module.scss';
import close from '../../../../assets/icons/close.svg';
import dangerOrange from '../../../../assets/icons/danger-orange.svg';
import {Currencies, useStores} from '../../../../stores';
import {SimpleStakingPlansResponse, StakingServices} from '../../../../services/StakingService';
import InputAmount from '../CommonModalComponents/InputAmount';
import {APP_ROUTES} from '../../../Router/constants';
import {Pages} from '../../../Router/types';
import SummaryBlockStake from '../CommonModalComponents/SummaryBlockStake';
import {stakingContracts} from '../../../../stores/actions/stacking';
import {EVENT_NAMES, useAnalytics} from '../../../../services/Analytics';
import {useGlobalModalContext} from '../index';
import CommonDropDown from '../CommonModalComponents/CommonDropDown';
import RequestError from '../CommonModalComponents/RequestError';
import EarningSuccessfully from '../CommonModalComponents/EarningSuccessfully';

type Inputs = {
  amount: string;
};

const defaultValues = {
  amount: '',
};

const NewEarningModal: React.FC = () => {
  const {stakingPlans, assets, currency, ratesRaw} = useStores();
  const {myLogEvent} = useAnalytics();
  const {simplePlans} = stakingPlans;
  const containerRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const {hideModal} = useGlobalModalContext();

  const methods = useForm<Inputs>({defaultValues});
  const {
    register,
    handleSubmit,
    formState: {errors},
    watch,
    setValue,
    clearErrors,
  } = methods;

  const watchAmount = watch('amount');

  const [contract, setContract] = useState<SimpleStakingPlansResponse | null>(null);
  const [isCurrency, setIsCurrency] = useState(false);
  const [isReadyStack, setIsReadyStack] = useState(false);
  const [requestError, setRequestError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);

  useEffect(() => {
    setValue('amount', '');
    clearErrors();
  }, [contract]);

  const asset = assets.find(token => token.assetId === contract?.assetId);
  const currencySymbol = currency === Currencies.USD ? 'USD' : 'EUR';
  const assetRate = ratesRaw.find(
    assetRateRaw => assetRateRaw.fromAssetId === asset?.id && assetRateRaw.toAssetId === currencySymbol
  );

  const validateAmount = (): boolean => {
    return !asset || !Number(asset.availableBalance) || errors.amount?.type === 'validate';
  };

  const handleConfirm = async (): Promise<void> => {
    // eslint-disable-next-line no-useless-return
    if (validateAmount()) return;

    setIsReadyStack(true);
    if (isReadyStack && !!contract) {
      setIsLoading(false);
      try {
        await StakingServices.simpleCreate({amount: +watchAmount, planId: contract.id});
        await stakingContracts();

        if (contract.assetId === 'XRP') {
          myLogEvent(EVENT_NAMES.WEB_EARN_XRP, {amount: +watchAmount});
        }

        setIsSuccessful(true);
      } catch (error: any) {
        console.log('ERROR-handleSimpleStack', error);
        setRequestError(error.code || error.message);
      }
      setIsLoading(false);
    }
  };

  const itemComponent = (plan: SimpleStakingPlansResponse): ReactElement => {
    if (!plan) return <div className={styles.dropDownSelectedText}>Choose Token</div>;
    const assetComponent = assets.find(assetItem => assetItem.assetId === plan.assetId);
    return (
      <div
        key={plan.assetId}
        onClick={() => {
          setContract(plan);
        }}
        className={classNames(styles.dropDownSelectedText, styles.networksDropdownItem)}
      >
        {assetComponent && <img src={assetComponent.icon} className={styles.assetIcon} alt='' />}
        {plan.assetId}
      </div>
    );
  };

  if (isSuccessful) {
    return <EarningSuccessfully text='Earning Successfully Setup' />;
  }

  return (
    <div ref={containerRef} className={styles.container} id='withdrawContainer'>
      <div className={styles.closeWrap} onClick={hideModal}>
        <img className={styles.closeIcon} alt='' src={close} />
      </div>
      <FormProvider {...methods}>
        <form className={styles.mobileForm} onSubmit={handleSubmit(handleConfirm)}>
          <div className={styles.content}>
            {isReadyStack ? (
              <>
                <div className={styles.title}>New Earning Summary</div>
                <SummaryBlockStake
                  amount={watchAmount}
                  assetRate={assetRate}
                  targetPlan={contract}
                  currentStakingApyPercent={contract?.stakingApyPercent}
                  maxOtherApiPercent={contract?.stakingApyPercent}
                />
                <div className={styles.attentionText}>
                  <img className={styles.attentionIcon} alt='' src={dangerOrange} />
                  <div>By confirming a new lock group will be created under your current Tier.</div>
                </div>
              </>
            ) : (
              <>
                <div className={styles.title}>New Earning</div>
                <div className={styles.enterAmount}>Choose Token</div>
                <CommonDropDown
                  data={simplePlans.filter(plan => plan.assetId !== contract?.assetId)}
                  selectedData={contract}
                  itemComponent={itemComponent}
                  setSelectedData={setContract}
                  containerRef={containerRef}
                />
                <div style={{height: 29}} />

                {asset && (
                  <InputAmount
                    errors={errors}
                    asset={asset}
                    register={register}
                    assetRate={assetRate}
                    setValue={setValue}
                    watchAmount={watchAmount}
                    isCurrency={isCurrency}
                    setIsCurrency={setIsCurrency}
                    clearErrors={clearErrors}
                  />
                )}
              </>
            )}

            {!asset && <div style={{height: 171}} />}

            {!isReadyStack && (
              <div className={styles.tierRateWrap}>
                <div className={styles.tierRateTitle}>Your Tier Rate</div>
                <div className={styles.tierRateApy}>{Number(contract?.stakingApyPercent) || '-'}% APY</div>
              </div>
            )}

            {asset && !isReadyStack && (
              <div className={styles.errorBlock}>
                <div style={{marginBottom: 22}}>
                  {validateAmount() ? 'Not enough balance. Get coin to be able to earn interest.' : ''}
                </div>
              </div>
            )}

            {asset && (
              <button
                type='submit'
                className='btn btn-primary'
                style={validateAmount() ? {backgroundColor: 'red'} : {}}
                onClick={() => {
                  if (validateAmount()) {
                    hideModal();
                    navigate(APP_ROUTES[Pages.Base]);
                  }
                }}
              >
                {/* eslint-disable-next-line no-nested-ternary */}
                {isLoading ? (
                  <span className='spinner-border' />
                ) : // eslint-disable-next-line no-nested-ternary
                isReadyStack ? (
                  'Lock'
                ) : validateAmount() ? (
                  'Get Coin'
                ) : (
                  'Continue'
                )}
              </button>
            )}

            {isReadyStack && (
              <button
                className={classNames('btn', 'btn-primary', styles.backBtn)}
                onClick={e => {
                  e.preventDefault();
                  setIsReadyStack(false);
                  setRequestError('');
                }}
              >
                Back
              </button>
            )}

            {requestError ? <RequestError requestError={requestError} /> : null}

            <div style={{height: 20}} />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default NewEarningModal;
