import React, {createContext, useContext, useState} from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import {MODAL_COMPONENTS} from './modalConstants';

const RightModals = ['trade-asset', 'top-up-banking', 'withdraw-banking', 'transactions'];

type ContextType = {
  showModal: (modalType: string, modalProps?: any) => void;
  hideModal: () => void;
  store: any;
  setModalStore: (modalProps?: any) => void;
};

const initialState: ContextType = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  showModal: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  hideModal: () => {},
  store: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setModalStore: () => {},
};

const GlobalModalContext = createContext(initialState);
export const useGlobalModalContext = (): ContextType => useContext(GlobalModalContext);

type Props = {
  children: JSX.Element;
};

const GlobalModals: React.FC<Props> = ({children}) => {
  const [store, setStore] = useState<Record<string, any>>();
  const {modalType, modalProps} = store || {};

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const showModal = (modalType: string, modalProps: any = {}): void => {
    setStore({
      ...store,
      modalType,
      modalProps,
    });
  };

  const hideModal = (): void => {
    setStore({
      ...store,
      modalType: null,
      modalProps: {},
    });
  };

  const setModalStore = (modalNavProps: any = {}): void => {
    setStore({
      ...store,
      modalProps: modalNavProps,
    });
  };

  const renderComponent = (): JSX.Element | null => {
    const ModalComponent = MODAL_COMPONENTS[modalType];

    return (
      <div
        onClick={hideModal}
        className={classNames(
          styles.container,
          modalType ? styles.openModal : {},
          RightModals.includes(modalType) ? styles.desktopRightModal : {}
        )}
      >
        <div
          className={classNames(styles.content, RightModals.includes(modalType) ? styles.contentRightModal : {})}
          onClick={event => event.stopPropagation()}
        >
          {!modalType ? null : <ModalComponent />}
        </div>
      </div>
    );
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <GlobalModalContext.Provider value={{store, showModal, hideModal, setModalStore}}>
      {renderComponent()}
      {children}
    </GlobalModalContext.Provider>
  );
};

export default GlobalModals;
