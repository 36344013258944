/* eslint-disable max-len */
import * as React from 'react';

type Props = {
  fill: string;
};

const SvgComponent: React.FC<Props> = ({fill}) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='62' height='62' fill='none' viewBox='0 0 62 62'>
    <rect width='62' height='62' fill={fill || '#3D5CF5'} fillOpacity='0.1' rx='31' />
    <path
      fill={fill || '#3D5CF5'}
      d='M40.131 33.66c-.409.397-.596.97-.503 1.532l1.403 7.767a1.705 1.705 0 01-.71 1.705 1.738 1.738 0 01-1.847.126l-6.992-3.647a1.786 1.786 0 00-.79-.206h-.428c-.148.022-.293.07-.426.142l-6.994 3.664c-.345.173-.737.235-1.12.173a1.755 1.755 0 01-1.405-2.006l1.405-7.767a1.766 1.766 0 00-.504-1.546l-5.7-5.525a1.704 1.704 0 01-.425-1.784 1.773 1.773 0 011.403-1.184l7.846-1.139a1.756 1.756 0 001.39-.96l3.457-7.09c.082-.157.188-.302.316-.426l.142-.11c.074-.082.16-.15.254-.205l.172-.063.268-.111h.665a1.766 1.766 0 011.39.947l3.502 7.057c.253.516.744.875 1.31.961l7.847 1.139a1.79 1.79 0 011.436 1.184 1.715 1.715 0 01-.457 1.784L40.13 33.66z'
    />
  </svg>
);
export default SvgComponent;
