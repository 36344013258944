import React from 'react';
import CompleteIconSvgComponent2 from '../../../../assets/icons/CompleteIconSvgComponent2';
import styles from './styles.module.scss';

const CompleteIconBlurComponent: React.FC = () => {
  return (
    <div className={styles.completeIconBlurWrap}>
      <div className={styles.completeIconBlur}>
        <CompleteIconSvgComponent2 fill='var(--mainBlue)' />
      </div>
      <div className={styles.blurEffect} />
    </div>
  );
};

export default CompleteIconBlurComponent;
