import React, {useState} from 'react';
import classNames from 'classnames';
import i18n from '../../../../localize';
import {Currencies, useStores} from '../../../../stores';
import {getBalanceString, roundingBalance} from '../../../../utils/formats';
import styles from '../WithdrawModal/styles.module.scss';
import ChangeIcon from '../../../../assets/icons/ChangeIcon';

const InputAmount: React.FC<any> = ({
  errors,
  asset,
  register,
  assetRate,
  setValue,
  watchAmount,
  isCurrency,
  setIsCurrency,
  clearErrors,
}) => {
  const {t} = i18n;
  const {currency} = useStores();

  const [isFocusAmount, setIsFocusAmount] = useState(false);

  const validateAmountCurrency = (data: string): boolean | string => {
    if (+data <= +asset.availableBalance * (assetRate?.data.currentPrice || 0)) return true;
    return 'Not Enough Balance';
  };

  const validateAmountCrypto = (data: string): boolean | string => {
    if (+data <= +asset.availableBalance) return true;
    return 'Not Enough Balance';
  };

  const handleMaxBtn = (): void => {
    clearErrors();
    const newAmount = isCurrency
      ? (assetRate?.data.currentPrice || 0) * +roundingBalance(asset.availableBalance, 8)
      : roundingBalance(asset.availableBalance, 8);
    setValue('amount', newAmount.toString());
  };

  const changeAmountCurrency = (): void => {
    // eslint-disable-next-line no-nested-ternary
    const newAmount = isCurrency
      ? assetRate
        ? +watchAmount / assetRate.data.currentPrice
        : '0'
      : assetRate
      ? +watchAmount * assetRate.data.currentPrice
      : '0';
    setValue('amount', newAmount.toString());

    setIsCurrency((prev: any) => !prev);
  };

  return (
    <>
      <div className={styles.enterAmount} style={errors.amount ? {color: 'red'} : {}}>
        {errors.amount && errors.amount.type === 'required' ? t('inputError.required') : ''}
        {errors.amount && errors.amount.type === 'validate' ? errors.amount.message : ''}
        {!errors.amount && 'Enter Amount'}
      </div>
      <div
        className={classNames(styles.withdrawInput, isFocusAmount ? styles.focusAmount : '')}
        style={errors.amount ? {border: '1px solid red'} : {}}
      >
        <div className={styles.assetNameWrap}>
          <img src={asset.icon} className={styles.assetIcon} alt='' />
          <div className={styles.assetName}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {isCurrency ? (currency === Currencies.USD ? 'USD' : 'EUR') : asset.name}
          </div>
        </div>
        <div className={styles.inputBlock}>
          {isCurrency ? (
            <>
              <div onClick={changeAmountCurrency} className={styles.currencyBlock}>
                ≈ {asset.id}{' '}
                {assetRate && watchAmount ? getBalanceString(+watchAmount / assetRate.data.currentPrice, 8) : '00'}
                &nbsp;
                <ChangeIcon fill='var(--mainBlue)' width={9} height={9} />{' '}
              </div>
              <input
                placeholder='00.00'
                className={styles.input}
                onFocus={() => setIsFocusAmount(true)}
                {...register('amount', {
                  required: true,
                  validate: validateAmountCurrency,
                  onBlur: () => setIsFocusAmount(false),
                })}
              />
            </>
          ) : (
            <>
              <input
                placeholder='00.00'
                className={styles.input}
                onFocus={() => setIsFocusAmount(true)}
                {...register('amount', {
                  required: true,
                  validate: validateAmountCrypto,
                  onBlur: () => setIsFocusAmount(false),
                })}
              />
              <div onClick={changeAmountCurrency} className={styles.currencyBlock}>
                ≈ {currency}{' '}
                {assetRate && watchAmount ? getBalanceString(+watchAmount * assetRate.data.currentPrice, 2) : '00'}
                &nbsp;
                <ChangeIcon fill='var(--mainBlue)' width={9} height={9} />{' '}
              </div>
            </>
          )}
        </div>
      </div>
      <div className={styles.balanceWrap}>
        <div className={styles.balance}>
          Balance: {getBalanceString(+asset.availableBalance, 8)} {asset.id}
        </div>
        <div onClick={handleMaxBtn} className={styles.maxBtn}>
          MAX
        </div>
      </div>
    </>
  );
};

export default InputAmount;
