import React from 'react';
import styles from './styles.module.scss';

const steps = [
  {
    label: 'Deposit',
    step: 1,
  },
  {
    label: 'Country',
    step: 2,
  },
  {
    label: 'Address',
    step: 3,
  },
  {
    label: 'Mobile',
    step: 4,
  },
  {
    label: 'Terms',
    step: 5,
  },
];

type ModalProps = {
  activeStep: number;
};

const StepContainer: React.FC<ModalProps> = ({activeStep}) => {
  const totalSteps = steps.length;

  const width = `${(99 / (totalSteps - 1)) * (activeStep - 1)}%`;
  return (
    <div className={styles.mainStepContainer}>
      <div className={styles.stepContainer}>
        <div className={styles.activeLine} style={{width}} />
        {steps.map(({step, label}) => (
          <div className={styles.stepWrapper} key={step}>
            <div
              className={styles.stepStyle}
              style={{
                backgroundColor: `${activeStep >= step ? 'var(--mainBlue)' : '#EBEAF5'}`,
              }}
            />
            <div className={styles.stepsLabelContainer}>
              <div className={activeStep >= step ? styles.stepLabelActive : styles.stepLabel} key={step}>
                {label}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StepContainer;
