import React from 'react';
import {useStores} from '../../../../stores';
import styles from '../LockBbankModal/styles.module.scss';
import {getBalanceString} from '../../../../utils/formats';

const SummaryBlockStake: React.FC<any> = ({
  amount,
  assetRate,
  targetPlan,
  currentStakingApyPercent,
  maxOtherApiPercent,
}) => {
  const {currency} = useStores();

  return (
    <>
      <div className={styles.summaryBlockTitle}>Amount</div>
      <div className={styles.cryptoAmount}>
        {assetRate.fromAssetId} {amount}
      </div>
      <div className={styles.currencyAmount}>
        {currency} {getBalanceString(+amount * (assetRate?.data.currentPrice || 1), 2)}
      </div>
      <div className={styles.conversion}>
        Conversion Rate: 1 {assetRate.fromAssetId} = {currency} {assetRate?.data.currentPrice}
      </div>
      <div className={styles.divider} />
      <div className={styles.summaryBlockTitle}>Duration</div>
      <div className={styles.cryptoAmount}>{targetPlan?.stakingPeriod || '184'} Days</div>
      <div className={styles.divider} />
      <div className={styles.summaryBlockTitle}>Tier</div>
      <div className={styles.cryptoAmount}>
        <div>
          {Number(targetPlan?.stakingApyPercent || currentStakingApyPercent)}% APY {assetRate.fromAssetId}
        </div>
        {assetRate.fromAssetId === 'BBANK' && (
          <>
            <div className={styles.dot2} />
            <div>{maxOtherApiPercent}% APY Other</div>
          </>
        )}
      </div>
    </>
  );
};

export default SummaryBlockStake;
