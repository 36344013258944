import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import {AssetsServices} from '../../../../services/AssetsService';
import checked from '../../../../assets/icons/checked.svg';
import CopySvgComponent from '../../../../assets/icons/CopySvgComponent';

const Referral: React.FC = () => {
  const [codeForInvitation, setCodeForInvitation] = useState('');
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    AssetsServices.getReferralCode()
      .then(res => {
        setCodeForInvitation(res.code);
      })
      .catch(() => setCodeForInvitation(''));
  }, []);
  return (
    <div className={styles.settingsItem}>
      <div className={styles.settingsItemContent}>
        <div className={styles.twoFaTitleWrap} style={{height: 32}}>
          <div className={styles.settingsItemTitle}>Referral</div>
          {isCopied && (
            <div
              style={{
                backgroundColor: '#262832',
                color: '#FFFFFF',
                height: 32,
                borderRadius: 100,
                display: 'flex',
                alignItems: 'center',
                padding: '0 11px',
              }}
            >
              <div style={{marginRight: 6, marginBottom: 4}}>Copied</div>
              <img alt='' src={checked} />
            </div>
          )}
        </div>

        <div className={styles.settingsItemDescription}>
          You are automatically enrolled into our referral program. Share your referral code to start earning.
        </div>

        <div
          onClick={() => {
            navigator.clipboard.writeText(codeForInvitation).then(() => {
              setIsCopied(true);
              setTimeout(() => {
                setIsCopied(false);
              }, 2000);
            });
          }}
          className={classNames(styles.disableBtn, styles.copyBtn)}
        >
          {codeForInvitation}
          <div style={{margin: 6}} />
          <CopySvgComponent fill='var(--mainBlue)' />
        </div>
      </div>
    </div>
  );
};

export default Referral;
