import React, {useEffect} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import styles from './styles.module.scss';
import BackArrowSVG from '../../../../assets/icons/BackArrowSVG';
import menu from '../../../../assets/icons/menu.svg';
import {useStores} from '../../../../stores';
import {APP_ROUTES} from '../../../Router/constants';
import {Pages} from '../../../Router/types';
import {MODAL_COMPONENTS} from '../../Modals/modalConstants';
import {useGlobalModalContext} from '../../Modals';

const MobileModals: React.FC = () => {
  const {isMobileNavPanel, setIsMobileNavPanel} = useStores();
  const {params} = useParams();
  const navigate = useNavigate();
  const {store} = useGlobalModalContext();

  useEffect(() => {
    if (!store) navigate(APP_ROUTES[Pages.Base]);
  }, []);

  if (!store) return null;

  const ModalComponent = params && MODAL_COMPONENTS[params];

  const backAction = (): void => {
    if (store?.modalProps?.backAction) {
      store.modalProps.backAction();
    } else {
      navigate(-1);
    }
  };

  return (
    <div className={styles.modalMobileContainer}>
      <div className={styles.headerTitle}>
        <div style={{height: 30}} onClick={() => backAction()}>
          <BackArrowSVG fill='var(--mainBlue)' />
        </div>
        <div>{store?.modalProps?.title || 'Back'}</div>
        <div onClick={() => setIsMobileNavPanel(!isMobileNavPanel)}>
          <img src={menu} alt='' />
        </div>
      </div>
      <div className={styles.contentContainerWrap}>
        <div className={styles.contentContainer}>{ModalComponent && <ModalComponent />}</div>
      </div>
    </div>
  );
};

export default MobileModals;
