import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import BackBtn from '../../Auth/common/BackBtn';
import Exchange from './Exchange/Exchange';
import Buy from './Buy';
import Sell from './Sell';
import {EVENT_NAMES, useAnalytics} from '../../../../services/Analytics';
import {useStores} from '../../../../stores';
import {useGlobalModalContext} from '../index';

const navItems = [{name: 'Exchange'}, {name: 'Buy'}, {name: 'Sell'}];

const TradeModal: React.FC = () => {
  const {myLogEvent} = useAnalytics();
  const {ratesCeFi} = useStores();
  const {hideModal, store} = useGlobalModalContext();
  const {asset} = store.modalProps;

  const eurAssetRate = ratesCeFi.find(rate => rate.fromAssetId === 'EUR' && rate.toAssetId === asset.assetId);
  const assetRateToEUR = ratesCeFi.find(
    assetRaw => assetRaw.toAssetId === 'EUR' && assetRaw.fromAssetId === asset.assetId
  );
  // TODO: off exchange list ??
  const assetRateToAny = ratesCeFi.filter(assetRaw => {
    return assetRaw.toAssetId !== 'EUR' && assetRaw.fromAssetId === asset.assetId;
  });

  const [activePanel, setActivePanel] = useState('Exchange');

  useEffect(() => {
    myLogEvent(EVENT_NAMES.WEB_TRADE_OPENED, {asset: asset.assetId});
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <BackBtn backFn={hideModal} />
      </div>
      <div className={styles.main}>
        <div className={styles.navBlock}>
          {navItems.map(navItem => {
            if (!eurAssetRate && navItem.name === 'Buy') return null;
            if (!assetRateToEUR && navItem.name === 'Sell') return null;
            return (
              <div
                key={navItem.name}
                onClick={() => setActivePanel(navItem.name)}
                className={classNames(styles.navItem, activePanel === navItem.name ? styles.navItemActive : '')}
              >
                {navItem.name}
              </div>
            );
          })}
        </div>
        <div className={styles.formWrap}>
          {activePanel === 'Exchange' && <Exchange asset={asset} />}
          {activePanel === 'Buy' && <Buy asset={asset} />}
          {activePanel === 'Sell' && <Sell asset={asset} />}
        </div>
      </div>
      <div className={styles.footer} />
    </div>
  );
};

export default TradeModal;
