import {request} from './index';

interface CreateClaimData {
  contractId: string;
}

export interface StakingPlansResponse {
  simplePlans: SimpleStakingPlansResponse[];
  rollingPlans: RollingStakingPlansResponse[];
  rollingLeveledPlans: RollingLeveledStakingPlansResponse[];
}

export interface SimpleStakingPlansResponse {
  // isRollingPlans: boolean;
  id: number;
  assetId: string;
  stakingPeriod: number;
  minimalStakingAmount: number;
  stakingApyPercent: number;
}

export interface RollingStakingPlansResponse {
  id: number;
  assetId: string;
  stakingPeriod: number;
  minimalStakingAmount: number;
  stakingApyPercent: number;
  minimalPlanAmount: number;
}

export interface RollingLeveledStakingPlansResponse {
  id: number;
  assetId: string;
  stakingPeriod: number;
  minimalStakingAmount: number;
  stakingApyPercent: number;
  minimalPlanAmount: number;
  minimalTargetPlanAmount: string;
}

export interface StakingContractsResponse {
  simpleContracts: StakingContract[];
  rollingResponses: StakingContract[];
  rollingLeveledContracts: StakingContract[];
  posContracts: StakingContract[];
}

export interface StakingContract {
  isRollingLeveled?: boolean;
  isSimple?: boolean;
  isRollingResponses?: boolean;
  id: number;
  assetId: string;
  planId: number;
  openDate: string;
  expectedCloseDate: string;
  stakingApyPercent: number;
  amount: number;
  payedRewardAmount: number;
  expectedRewardAmount: number;
}

export interface CreateStakingRequest {
  planId: number;
  amount: number;
}

export interface RollingLeveledStakingContractResponse {
  id: number;
  assetId: string;
  planId: number;
  openDate: string;
  expectedCloseDate: string;
  stakingApyPercent: number;
  amount: number;
  payedRewardAmount: number;
  expectedRewardAmount: number;
}

const contracts = (): Promise<StakingContractsResponse> => request({url: '/staking/contracts', method: 'GET'});

const simpleCreate = (data: CreateStakingRequest): Promise<RollingLeveledStakingContractResponse> =>
  request({url: '/staking/contracts/simple/create', data});

const rollingCreate = (data: CreateStakingRequest): Promise<RollingLeveledStakingContractResponse> =>
  request({url: '/staking/contracts/rolling-leveled/create', data});

const createRollingClaim = (data: CreateClaimData): Promise<RollingLeveledStakingContractResponse> =>
  request({url: `/staking/contracts/rolling-leveled/${data.contractId}/claim`, data});

const createRollingClaimOld = (data: CreateClaimData): Promise<RollingLeveledStakingContractResponse> =>
  request({url: `/staking/contracts/rolling/${data.contractId}/claim`, data});

const createSimpleClaim = (data: CreateClaimData): Promise<RollingLeveledStakingContractResponse> =>
  request({url: `/staking/contracts/simple/${data.contractId}/claim`, data});

const stakingPlans = (): Promise<StakingPlansResponse> => request({url: '/staking/plans', method: 'GET'});

const tierLevel = (): Promise<number> => request({url: '/user-level', method: 'GET'});

export const StakingServices = {
  contracts,
  createRollingClaim,
  createSimpleClaim,
  createRollingClaimOld,
  stakingPlans,
  simpleCreate,
  rollingCreate,
  tierLevel,
};
