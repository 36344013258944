import React from 'react';
import classNames from 'classnames';
import I18n from '../../../../common/I18n';
import styles from './styles.module.scss';

type Props = {
  title: string;
  description: string;
};

const AuthTitle: React.FC<Props> = ({title, description}) => {
  return (
    <div className={styles.titleWrap}>
      <div className={classNames(styles.title, 'text-center')}>
        <I18n tKey={title} />
      </div>
      <div className={classNames(styles.description, 'text-center')}>
        <I18n tKey={description} />
      </div>
    </div>
  );
};

export default AuthTitle;
