import React from 'react';
import Modal from 'react-modal';
import classNames from 'classnames';
import {theme} from '../../../../globalConstants';
import styles from './styles.module.scss';
import close from '../../../../assets/icons/close.svg';

const defaultStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    borderRadius: 10,
    backgroundColor: '#FAFAFA',
  },
  overlay: {
    backgroundColor: 'rgba(38, 40, 50, 0.3)',
  },
};

type ModalProps = {
  modalIsOpen: boolean;
  closeModal: () => void;
  goNext: () => void;
};

const CardOrderInfoModal: React.FC<ModalProps> = ({modalIsOpen, closeModal, goNext}) => {
  return (
    <Modal
      data={{theme}}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={defaultStyles}
      contentLabel='CardOrderInfoModal'
    >
      <div className={styles.container}>
        <div className={styles.closeWrap} onClick={closeModal}>
          <img className={styles.closeIcon} alt='' src={close} />
        </div>
        <div className={styles.title}>Fuel Up Your HODL Card</div>
        <div className={styles.description}>
          To kickstart your application, let&#39;s start by depositing 50 EUR into your card balance. Think of it as
          priming the pump – it’s not a fee, but funds you&#39;ll be able to spend as soon as you receive the card.
        </div>

        <div className={styles.description}>
          The deposit will be drawn from your available fiat balance, so no extra steps needed.
        </div>

        <div className={styles.btnContainer}>
          <button
            onClick={closeModal}
            className={classNames('btn', 'btn-primary')}
            style={{
              backgroundColor: '#FAFAFA',
              color: 'var(--mainBlue)',
              border: '1.5px solid var(--mainBlue)',
              marginRight: 9,
            }}
          >
            <div>Cancel</div>
          </button>
          <button onClick={goNext} className={classNames('btn', 'btn-primary')}>
            <div>Continue</div>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CardOrderInfoModal;
