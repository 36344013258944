import React, {useState} from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import PhoneService from '../../../../services/PhoneService';
import ErrorRedBlock from '../../../common/ErrorRedBlock';
import CardServices from '../../../../services/CardServices';

type Props = {
  nextStep: () => void;
  phone: string;
  setPhone: React.Dispatch<string>;
  blockPhone: boolean;
};

const StepFour: React.FC<Props> = ({nextStep, phone, setPhone, blockPhone}) => {
  const [code, setCode] = useState('');
  const [isSent, setIsSent] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestError, setRequestError] = useState('');

  const nextStepToTerms = async (): Promise<void> => {
    try {
      await CardServices.submitOrderStatus({
        step: 'PHONE',
        data: {},
      });
      nextStep();
    } catch (error: any) {
      setRequestError(error.code || 'Phone Service Error');
    }
  };

  return (
    <div className={styles.stepContentContainer}>
      <div className={styles.freeHistoryDescription} style={{textAlign: 'center', marginTop: 90}}>
        {isSent
          ? 'Just type in the code we sent you. This helps us keep your account safe and secure.'
          : "A quick step for added security. We'll send you a verification code to ensure it's really you."}
      </div>

      <div className='input-item-wrap'>
        <label htmlFor='phone' className='input-label'>
          {isSent ? 'Enter Code Received' : 'Phone Number'}
        </label>
        <input
          id='phone'
          type='text'
          className='input-form'
          style={phoneError ? {outline: '1px solid red'} : {}}
          placeholder={isSent ? 'Enter Code Received' : 'Phone Number'}
          value={isSent ? code : phone}
          disabled={blockPhone}
          onChange={value => {
            if (phoneError) setPhoneError(false);
            if (isSent) {
              setCode(value.target.value);
            } else {
              setPhone(value.target.value);
            }
          }}
        />

        {isSent ? (
          <div style={{marginLeft: '.5rem', marginTop: 11}} className={styles.stepLabel}>
            Didn’t receive the code?
            <span
              className={classNames('input-label form-link')}
              style={{fontSize: 12}}
              onClick={() => console.log('123')}
            >
              Send Again
            </span>
          </div>
        ) : (
          <div style={{marginLeft: '.5rem', marginTop: 11}} className={styles.stepLabel}>
            Include “+” in the beginning, country code and number without spaces.
          </div>
        )}
      </div>

      <div className={styles.stepFourBtnWrap}>
        <button
          onClick={async e => {
            e.preventDefault();
            setLoading(true);
            if (isSent) {
              if (code) {
                // check code
                await PhoneService.confirmPhoneCode({
                  phone,
                  code,
                });
                await nextStepToTerms();
              } else {
                setPhoneError(true);
              }
            } else if (blockPhone) {
              await nextStepToTerms();
            } else if (phone) {
              try {
                // send phone code
                await PhoneService.addUserPhone({phone});
                setIsSent(true);
              } catch (error: any) {
                console.log('ERROR-PhoneService', error);
                setRequestError(error.code || 'Phone Service Error');
              }
            } else {
              setPhoneError(true);
            }

            setLoading(false);
          }}
          className='btn btn-primary'
        >
          {loading ? <span className='spinner-border' /> : <div>Next</div>}
        </button>
      </div>

      {requestError ? (
        <ErrorRedBlock requestError={requestError} />
      ) : (
        <div style={{height: 'auto', margin: '24px 0'}} />
      )}
    </div>
  );
};

export default StepFour;
