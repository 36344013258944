import React from 'react';
import danger from '../../../../assets/icons/danger.svg';
import styles from './styles.module.scss';

type Props = {
  requestError: string;
};

const RequestError: React.FC<Props> = ({requestError}) => {
  return (
    <div className={styles.requestErrorWrap}>
      <img className={styles.requestErrorIcon} alt='' src={danger} />
      <div className={styles.requestErrorInfo}>{requestError}</div>
    </div>
  );
};

export default RequestError;
