import React, {memo} from 'react';
import classNames from 'classnames';
import {WithTranslation, withTranslation} from 'react-i18next';

interface IProps extends WithTranslation {
  tKey: string;
  onClick?: () => void;
  classname?: string;
  isHTMLContent?: boolean;
}

const I18n: React.FC<IProps> = ({isHTMLContent = false, tKey, onClick, classname, t}) => (
  <div>
    {!isHTMLContent ? (
      <span className={classNames(classname)} onClick={onClick}>
        {t(tKey)}
      </span>
    ) : (
      <div>---</div>
      // <div dangerouslySetInnerHTML={{__html: t(tKey)}} />
    )}
  </div>
);

export default withTranslation()(memo(I18n));
