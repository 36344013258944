import IndividualEarn from './IndividualEarn';
import LockBbankModal from './LockBbankModal';
import NewEarningModal from './NewEarningModal';
import TwoFactorAuthenticationModal from './TwoFactorAuthenticationModal';
import WithdrawModal from './WithdrawModal';
import DepositModal from './DepositModal/DepositModal';
import TradeModal from './TradeModal';
import ListUDModal from './ListUDModal';
import GetUDModal from './GetUDModal';
import TopUpModal from './TopUpModal';
import BankingWithdrawModal from './BankingWithdrawModal';
import TransactionsModal from './TransactionsModal';
import ChangePasswordModal from './ChangePasswordModal';

export const MODAL_TYPES = {
  INDIVIDUAL_EARN_MODAL: 'individual-earn',
  LOCK_BBANK_MODAL: 'lock-BBANK',
  NEW_EARNING_MODAL: 'new-earning',
  TWO_FACTOR_AUTHENTICATION_MODAL: 'two-factor-authentication',
  WITHDRAW_ASSET: 'withdraw-asset',
  DEPOSIT_ASSET: 'deposit-asset',
  TRADE_ASSET: 'trade-asset',
  LIST_UD_MODAL: 'list-UD',
  GET_UD_MODAL: 'get-UD',
  TOP_UP_BANKING: 'top-up-banking',
  WITHDRAW_BANKING: 'withdraw-banking',
  TRANSACTIONS_MODAL: 'transactions',
  CHANGE_PASSWORD_MODAL: 'change-password',
};

export const MODAL_COMPONENTS: any = {
  [MODAL_TYPES.INDIVIDUAL_EARN_MODAL]: IndividualEarn,
  [MODAL_TYPES.LOCK_BBANK_MODAL]: LockBbankModal,
  [MODAL_TYPES.NEW_EARNING_MODAL]: NewEarningModal,
  [MODAL_TYPES.TWO_FACTOR_AUTHENTICATION_MODAL]: TwoFactorAuthenticationModal,
  [MODAL_TYPES.WITHDRAW_ASSET]: WithdrawModal,
  [MODAL_TYPES.DEPOSIT_ASSET]: DepositModal,
  [MODAL_TYPES.TRADE_ASSET]: TradeModal,
  [MODAL_TYPES.LIST_UD_MODAL]: ListUDModal,
  [MODAL_TYPES.GET_UD_MODAL]: GetUDModal,
  [MODAL_TYPES.TOP_UP_BANKING]: TopUpModal,
  [MODAL_TYPES.WITHDRAW_BANKING]: BankingWithdrawModal,
  [MODAL_TYPES.TRANSACTIONS_MODAL]: TransactionsModal,
  [MODAL_TYPES.CHANGE_PASSWORD_MODAL]: ChangePasswordModal,
};
