import {request} from './index';

export interface DepositOperation {
  assetId: string;
  amount: number;
  time: string;
}

export interface ExchangeOperation {
  fromAssetId: string;
  fromAmount: number;
  toAssetId: string;
  toAmount: number;
  rate: number;
  time: string;
}

type WithdrawStatus = 'PENDING' | 'COMPLETED' | 'FAILED';

export interface WithdrawOperation {
  assetId: string;
  amount: number;
  status: WithdrawStatus;
  time: string;
}

export interface RewardOperation {
  rewardName: string;
  assetId: string;
  amount: number;
  rewardTime: string;
}

const getHistoryDeposit = (): Promise<DepositOperation[]> =>
  request({url: '/v3/assets/my/history/deposit', method: 'GET'});

const getHistoryExchange = (): Promise<ExchangeOperation[]> =>
  request({url: '/v3/assets/my/history/exchange', method: 'GET'});

const getHistoryWithdraw = (): Promise<WithdrawOperation[]> =>
  request({url: '/v3/assets/my/history/withdraw', method: 'GET'});

const getHistoryReward = (): Promise<RewardOperation[]> =>
  request({url: '/v3/assets/my/history/reward', method: 'GET'});

export const TransactionsServices = {
  getHistoryDeposit,
  getHistoryExchange,
  getHistoryWithdraw,
  getHistoryReward,
};
