import React from 'react';
import {theme} from '../../../../globalConstants';
import HeaderTitle from '../common/HeaderTitle';

const Support: React.FC = () => {
  const helpUrl = (): string => {
    if (theme === 'fideum') {
      return 'https://forms.helpdesk.com/?licenseID=1962211529&contactFormID=bced4a7e-f57e-4dec-81b3-872e385a366f';
    }
    if (theme === 'kaizen') {
      return 'https://forms.helpdesk.com/?licenseID=1962211529&contactFormID=b54aae54-b7b4-4411-ba59-19039f2394b0';
    }
    return 'https://forms.helpdesk.com?licenseID=1962211529&contactFormID=8745731d-0948-415a-af9f-0e5b7ffb820d';
  };

  return (
    <div
      style={{
        width: '100%',
        padding: 12,
      }}
    >
      <div style={{margin: '0 -12px'}}>
        <HeaderTitle hideUd headerTitle='Support' />
      </div>
      <iframe
        title='Support'
        sandbox='allow-scripts allow-popups allow-forms allow-same-origin'
        width='100%'
        height='100%'
        style={{border: 0}}
        src={helpUrl()}
      >
        Your browser does not allow embedded content.
      </iframe>
    </div>
  );
};

export default Support;
