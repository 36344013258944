import moment from 'moment';
import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss';
import Spinner from '../../../common/Spinner';
import {getBalanceString} from '../../../../utils/formats';
import RewardsSVG from '../common/InfoCards/RewardsSVG';
import {AssetsServices} from '../../../../services/AssetsService';
import {EVENT_NAMES} from '../../../../services/Analytics';
import {TransactionsServices} from '../../../../services/TransactionsService';
import {IData} from '../../Modals/TransactionsModal';
import depositIcon from '../../../../assets/icons/deposit-icon.svg';
import withdrawalIcon from '../../../../assets/icons/withdrawal-icon.svg';

const FiatTransactions: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [historyData, setHistoryData] = useState<IData[] | null>(null);

  const getHistoryData = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const deposits = await TransactionsServices.getHistoryDeposit();
      const preparedDeposit: IData[] = deposits.map(deposit => ({...deposit, icon: depositIcon, title: 'Deposit'}));
      const withdraws = await TransactionsServices.getHistoryWithdraw();
      const preparedWithdraws: IData[] = withdraws.map(withdraw => ({
        ...withdraw,
        icon: withdrawalIcon,
        title: 'Withdrawal',
      }));
      // TODO set filter fiat
      const resData = [...preparedDeposit, ...preparedWithdraws].sort((a, b) => {
        // @ts-ignore
        return moment(b.time) - moment(a.time);
      });
      setHistoryData(resData.filter(item => item.assetId === 'EUR'));
    } catch (e) {
      console.log('getHistoryData-ERROR', e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getHistoryData().catch(e => console.log('getHistoryData-ERROR', e));
  }, []);

  return (
    <div
      className={styles.fiatBalanceCard}
      style={{marginTop: 24, flexGrow: 1, height: 688, overflow: 'auto', padding: 0}}
    >
      <div className={styles.balanceTitle} style={{marginBottom: 24, padding: '24px 24px 0 24px'}}>
        Recent Transactions
      </div>
      <div className={styles.divider} />
      {/* eslint-disable-next-line no-nested-ternary */}
      {isLoading ? (
        <div className='justify-row-center'>
          <Spinner />
        </div>
      ) : historyData && historyData.length ? (
        historyData.map((item, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={item.title + item.assetId + index} className={styles.item}>
              <img src={item.icon} className={styles.icon} alt='' />
              <div>
                <div className={styles.itemTitle}>
                  {item.title} {item.fromAssetId ? `${item.fromAssetId} to ${item.toAssetId}` : ''}
                </div>
                <div className={styles.itemDate}>{moment(item.time).format('MMMM DD, YYYY')}</div>
              </div>
              <div className={styles.itemAmount}>
                {getBalanceString(Number(item.fromAmount || item.amount || 0), 6)} {item.assetId || item.fromAssetId}
              </div>
            </div>
          );
        })
      ) : (
        <div className={styles.freeHistoryContainer}>
          <RewardsSVG fill='var(--mainBlue)' />
          <div className={styles.freeHistory}>
            Your Transactions <br /> History will appear here
          </div>
          <div className={styles.freeHistoryDescription}>
            Once you start using the app, all recent transaction will be displayed here for your convenience.
          </div>
        </div>
      )}
    </div>
  );
};

export default FiatTransactions;
