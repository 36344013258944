import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import classNames from 'classnames';
import styles from './styles.module.scss';
import {Pages} from '../../../Router/types';
import soon from '../../../../assets/icons/soon.svg';
import {useStores} from '../../../../stores';
import {APP_ROUTES} from '../../../Router/constants';
import {MODAL_TYPES} from '../../Modals/modalConstants';
import {useGlobalModalContext} from '../../Modals';

type Props = {
  path: string;
  title: string;
  Icon: React.FC<{fill: string}>;
  isSoon?: boolean;
};

const NavItem: React.FC<Props> = ({path, title, Icon, isSoon}) => {
  const {isMobileNavPanel, setIsMobileNavPanel} = useStores();
  const {setModalStore} = useGlobalModalContext();

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div
      onClick={() => {
        if (title === Pages.TRANSACTIONS) {
          setModalStore({title: Pages.TRANSACTIONS});
          navigate(`${APP_ROUTES[Pages.TRANSACTIONS]}/${MODAL_TYPES.TRANSACTIONS_MODAL}`);
        } else if (isSoon) {
          console.log('isSoon');
        } else {
          navigate(path);
        }
        setIsMobileNavPanel(!isMobileNavPanel);
      }}
      className={classNames(styles.linkItem, location.pathname === path ? styles.active : '')}
    >
      <div className={styles.hideMobile}>
        <Icon fill={location.pathname === path ? 'white' : 'var(--iconBackground)'} />
      </div>

      <p className={styles.title} style={{color: location.pathname === path ? 'white' : '#445374'}}>
        {title}
      </p>
      {isSoon && <img src={soon} className={styles.soonImg} alt='' />}
    </div>
  );
};

export default NavItem;
