import React, {useState} from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import TriangleSvgComponent from '../../../../assets/icons/TriangleSvgComponent';

type AssetsDropdownProps = {
  assets: any;
  selectedData: any;
  setSelectedData: React.Dispatch<any>;
};

const AssetsDropdown: React.FC<AssetsDropdownProps> = ({assets, selectedData, setSelectedData}) => {
  const [isShowContent, setIsShowContent] = useState(false);

  return (
    <>
      <div
        className={styles.networksDropdownMain}
        style={isShowContent ? {borderColor: 'var(--mainBlue)'} : {}}
        onClick={() => setIsShowContent(prev => !prev)}
      >
        <div
          className={styles.dropDownSelectedText}
          style={{justifyContent: 'center', alignItems: 'center', display: 'flex', marginLeft: 6}}
        >
          <img className={styles.assetIcon} src={selectedData.icon} alt='' />
          {selectedData?.name}
        </div>
        <div className={styles.networksDropdownTriangle} style={isShowContent ? {transform: 'rotate(+90deg)'} : {}}>
          <TriangleSvgComponent fill='var(--mainBlue)' />
        </div>
      </div>

      {isShowContent && (
        <div className={classNames(styles.networksDropdownContainer, styles.networksDropdownContainerTrade)}>
          {assets
            .filter((asset: any) => !asset.isFiat)
            .map((asset: any) => {
              return (
                <div
                  key={asset.id}
                  onClick={() => {
                    setSelectedData(asset);
                    setIsShowContent(false);
                  }}
                  className={classNames(styles.dropDownSelectedText, styles.networksDropdownItem)}
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    marginLeft: 0,
                    paddingLeft: 6,
                    paddingRight: 6,
                  }}
                >
                  <img className={styles.assetIcon} src={asset.icon} alt='' />
                  {asset?.name}
                </div>
              );
            })}
        </div>
      )}
    </>
  );
};

export default AssetsDropdown;
