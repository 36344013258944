/* eslint-disable */
// @ts-ignore
import {sumBy} from 'lodash';

const getCefiPreviousTotalCash = (assets: any[]): number => {
  return assets.reduce((acc: number, asset: any) => {
    return (acc += ((+asset.rate?.value * 100) / (+asset.rate?.change24Percent + 100)) * +asset.balance.available);
  }, 0);
};

const getCefiTotalCash = (assets: any[]): number => {
  return sumBy(assets, 'balance.availableCash');
};

export const calculateCefiPortfolioValueChange = (assets: any[]): number => {
  return (getCefiTotalCash(assets) * 100) / getCefiPreviousTotalCash(assets) - 100;
};
