import React, {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {observer} from 'mobx-react';
import classNames from 'classnames';
import styles from '../styles.module.scss';
import AssetsDropdown from '../../CommonModalComponents/AssetsDropdown';
import AmountInput from '../AmountInput';
import {getBalanceString, roundingBalance} from '../../../../../utils/formats';
import {useStores} from '../../../../../stores';
import {AssetsServices, ExchangeInfo} from '../../../../../services/AssetsService';
import TradeSummaryInfo from '../TradeSummaryInfo';
import Successfully from '../Successfull';
import {initApp} from '../../../../../stores/actions/init';
import {EVENT_NAMES, useAnalytics} from '../../../../../services/Analytics';
import ChangeIcon from '../../../../../assets/icons/ChangeIcon';
import RequestError from '../../CommonModalComponents/RequestError';

const defaultValues = {
  fromAmountCrypto: '',
  fromAmountCurrency: '',
  toAmountCrypto: '',
  toAmountCurrency: '',
};

export type ExchangeInputs = {
  fromAmountCrypto: string;
  fromAmountCurrency: string;
  toAmountCrypto: string;
  toAmountCurrency: string;
};

type Props = {
  asset: any;
};

const Exchange: React.FC<Props> = ({asset}) => {
  const {assets, ratesCeFi} = useStores();
  const {myLogEvent} = useAnalytics();

  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm<ExchangeInputs>({defaultValues});
  const {
    handleSubmit,
    formState: {errors},
    watch,
    setValue,
    reset,
    clearErrors,
  } = methods;

  const watchFromAmountCrypto = watch('fromAmountCrypto');
  const watchFromAmountCurrency = watch('fromAmountCurrency');
  const watchToAmountCrypto = watch('toAmountCrypto');
  const watchToAmountCurrency = watch('toAmountCurrency');

  const assetsToList = (selectedFromAsset: any): string[] => {
    return ratesCeFi
      .filter(assetRate => assetRate.fromAssetId === selectedFromAsset.assetId)
      .map(assetRate => assetRate.toAssetId);
  };
  const assetsFromList = (selectedFromAsset: any): string[] => {
    return ratesCeFi
      .filter(assetRate => assetRate.toAssetId === selectedFromAsset.assetId)
      .map(assetRate => assetRate.fromAssetId);
  };

  const [selectedFromAsset, setSelectedFromAsset] = useState<any | null>(null);
  const [selectedToAsset, setSelectedToAsset] = useState<any | null>(null);
  const [isCurrency, setIsCurrency] = useState(false);
  const [focusName, setFocusName] = useState('');
  const [exchangeInfo, setExchangeInfo] = useState<ExchangeInfo | null>(null);

  const [requestError, setRequestError] = useState('');
  const [isSuccessfully, setIsSuccessfully] = useState(false);

  const fromToRate = ratesCeFi.find(
    rateCeFi => rateCeFi.fromAssetId === selectedFromAsset?.assetId && rateCeFi.toAssetId === selectedToAsset?.assetId
  );

  const toFromRate = ratesCeFi.find(
    rateCeFi => rateCeFi.fromAssetId === selectedToAsset?.assetId && rateCeFi.toAssetId === selectedFromAsset?.assetId
  );

  useEffect(() => {
    if (!assetsToList(asset)?.length) {
      setSelectedFromAsset(assets.find(assetItem => assetsFromList(asset).includes(assetItem.id)));
      setSelectedToAsset(asset);
    } else {
      setSelectedFromAsset(asset);
      setSelectedToAsset(
        assets.find(assetItem => assetItem.id !== asset.id && assetsToList(asset).includes(assetItem.id))
      );
    }
  }, [asset]);

  useEffect(() => {
    reset();
    setIsCurrency(false);
  }, [selectedFromAsset, selectedToAsset]);

  useEffect(() => {
    if (focusName === 'fromAmountCrypto') {
      setValue(
        'fromAmountCurrency',
        roundingBalance((+watchFromAmountCrypto * (selectedFromAsset.rate?.value || 0)).toString(), 2)
      );
      setValue(
        'toAmountCurrency',
        roundingBalance((+watchFromAmountCrypto * (selectedFromAsset.rate?.value || 0)).toString(), 2)
      );
      setValue('toAmountCrypto', roundingBalance((+watchFromAmountCrypto * (fromToRate?.rate || 0)).toString(), 8));
    }
    if (focusName === 'fromAmountCurrency') {
      setValue(
        'fromAmountCrypto',
        roundingBalance((+watchFromAmountCurrency / (selectedFromAsset.rate?.value || 0)).toString(), 8)
      );
      setValue('toAmountCurrency', roundingBalance(watchFromAmountCurrency, 2));
      setValue('toAmountCrypto', roundingBalance((+watchFromAmountCrypto * (fromToRate?.rate || 0)).toString(), 8));
    }
    if (focusName === 'toAmountCrypto') {
      setValue(
        'toAmountCurrency',
        roundingBalance((+watchToAmountCrypto * (selectedToAsset.rate?.value || 0)).toString(), 2)
      );
      setValue(
        'fromAmountCurrency',
        roundingBalance((+watchToAmountCrypto * (selectedToAsset.rate?.value || 0)).toString(), 2)
      );
      setValue('fromAmountCrypto', roundingBalance((+watchToAmountCrypto * (toFromRate?.rate || 0)).toString(), 2));
    }
    if (focusName === 'toAmountCurrency') {
      setValue(
        'toAmountCrypto',
        roundingBalance((+watchToAmountCurrency / (selectedToAsset.rate?.value || 0)).toString(), 8)
      );
      setValue('fromAmountCurrency', roundingBalance(watchToAmountCurrency, 2));
      setValue('fromAmountCrypto', roundingBalance((+watchToAmountCrypto * (toFromRate?.rate || 0)).toString(), 8));
    }

    clearErrors();
    setRequestError('');
  }, [watchFromAmountCurrency, watchFromAmountCrypto, watchToAmountCurrency, watchToAmountCrypto]);

  const changeAmountCurrency = (): void => {
    setIsCurrency(prev => !prev);
  };

  const handleMax = (): void => {
    if (isCurrency) {
      setValue(
        'fromAmountCurrency',
        roundingBalance((selectedFromAsset.totalBalance * selectedFromAsset.rate.value).toString(), 2)
      );
      setValue('fromAmountCrypto', roundingBalance(selectedFromAsset.totalBalance.toString(), 8));
      setValue(
        'toAmountCurrency',
        roundingBalance((selectedFromAsset.totalBalance * selectedFromAsset.rate.value).toString(), 2)
      );
      setValue('toAmountCrypto', (selectedFromAsset.totalBalance * (fromToRate?.rate || 0)).toString());
    } else {
      setValue('fromAmountCrypto', roundingBalance(selectedFromAsset.totalBalance, 8));
      setValue(
        'fromAmountCurrency',
        roundingBalance((selectedFromAsset.totalBalance * (selectedFromAsset.rate?.value || 0)).toString(), 2)
      );
      setValue(
        'toAmountCurrency',
        roundingBalance((selectedFromAsset.totalBalance * (selectedFromAsset.rate?.value || 0)).toString(), 2)
      );
      setValue(
        'toAmountCrypto',
        roundingBalance((selectedFromAsset.totalBalance * (fromToRate?.rate || 0)).toString(), 8)
      );
    }
  };

  const handleChange = (): void => {
    if (!assetsToList(selectedToAsset).includes(selectedFromAsset.assetId)) return;

    reset();
    setSelectedToAsset(selectedFromAsset);
    setSelectedFromAsset(selectedToAsset);
  };

  const changeFromAsset = (assetItem: any): void => {
    if (!assetsToList(assetItem).includes(selectedToAsset.id)) {
      setSelectedToAsset(assets.find(assetOrigin => assetsToList(assetItem).includes(assetOrigin.id)));
    }
    setSelectedFromAsset(assetItem);
  };

  const handleExchange = async (): Promise<void> => {
    setIsLoading(true);

    try {
      if (exchangeInfo) {
        await AssetsServices.exchangeAsset({
          amount: +watchFromAmountCrypto,
          info: exchangeInfo as ExchangeInfo,
        });

        myLogEvent(EVENT_NAMES.WEB_EXCHANGE, {...exchangeInfo});

        await initApp();

        setIsSuccessfully(true);
      } else {
        const exchangeInfoData = await AssetsServices.exchangeInfoAsset({
          amount: +watchFromAmountCrypto,
          from: selectedFromAsset.assetId,
          to: selectedToAsset.assetId,
        });
        setExchangeInfo(exchangeInfoData);
      }
    } catch (e: any) {
      console.log('ERROR-handleExchange', e);
      setRequestError(e.code);
    }
    setIsLoading(false);
  };

  const inputErrorHandler = (): string => {
    if (+watchFromAmountCrypto > +(selectedFromAsset.totalBalance || selectedFromAsset?.balance?.available || 0)) {
      return 'Not Enough Balance';
    }
    if (Object.keys(errors).some(error => ['fromAmountCrypto', 'fromAmountCurrency'].includes(error))) {
      return 'Required';
    }
    return '';
  };

  if (isSuccessfully) {
    return <Successfully />;
  }

  if (!selectedFromAsset) return null;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleExchange)}>
        {exchangeInfo && fromToRate ? (
          <TradeSummaryInfo
            exchangeInfo={exchangeInfo}
            fromToRate={fromToRate}
            selectedFromAsset={selectedFromAsset}
            selectedToAsset={selectedToAsset}
            watchFromAmountCrypto={watchFromAmountCrypto}
          />
        ) : (
          <>
            <div className={styles.title}>Exchange</div>
            <div className={styles.description}>
              Please enter the details for the <br />
              transaction to proceed.
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <div style={{width: '50%', position: 'relative'}}>
                <div className={styles.enterAmount}>From</div>
                <AssetsDropdown
                  assets={assets.filter(item => {
                    return item.assetId !== selectedFromAsset.assetId && assetsFromList(asset).includes(item.assetId);
                  })}
                  selectedData={selectedFromAsset}
                  setSelectedData={changeFromAsset}
                />
              </div>
              <div style={{width: 5}} />
              <div style={{width: '50%'}}>
                <div className={styles.enterAmount} style={inputErrorHandler() ? {color: 'red'} : {}}>
                  {inputErrorHandler() || 'Amount'}
                </div>
                <AmountInput
                  currencyAmount={watchFromAmountCurrency}
                  cryptoAmount={watchFromAmountCrypto}
                  asset={selectedFromAsset}
                  changeAmountCurrency={changeAmountCurrency}
                  isCurrency={isCurrency}
                  direction='from'
                  setFocusName={setFocusName}
                  methods={methods}
                />
              </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', height: 88}}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{display: 'flex', alignItems: 'center', marginTop: 13}}>
                  <div className={styles.balance}>
                    Balance: {getBalanceString(+selectedFromAsset.totalBalance, 8)} {selectedFromAsset.symbol}
                  </div>
                  <div onClick={handleMax} className={styles.max}>
                    MAX
                  </div>
                </div>
                <div className={styles.enterAmount}>To</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: 36,
                }}
              >
                <div style={{flexGrow: 1, borderLeft: '1px solid var(--mainBlue)', width: 1}} />
                <div
                  onClick={handleChange}
                  style={{
                    border: '1px solid var(--mainBlue)',
                    borderRadius: '100%',
                    height: 26,
                    width: 26,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    opacity: assetsToList(selectedToAsset).includes(selectedFromAsset.assetId) ? 1 : 0.2,
                  }}
                >
                  <ChangeIcon fill='var(--mainBlue)' />
                </div>
                <div style={{flexGrow: 1, borderRight: '1px solid var(--mainBlue)', width: 1}} />
              </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <div style={{width: '50%', position: 'relative'}}>
                <AssetsDropdown
                  assets={assets.filter((assetTo: any) => {
                    return (
                      assetTo.assetId !== selectedToAsset.assetId &&
                      assetsToList(selectedFromAsset).includes(assetTo.assetId)
                    );
                  })}
                  selectedData={selectedToAsset}
                  setSelectedData={setSelectedToAsset}
                />
              </div>
              <div style={{width: 5}} />
              <div style={{width: '50%'}}>
                <AmountInput
                  currencyAmount={watchToAmountCurrency}
                  cryptoAmount={watchToAmountCrypto}
                  asset={selectedToAsset}
                  changeAmountCurrency={changeAmountCurrency}
                  isCurrency={isCurrency}
                  direction='to'
                  setFocusName={setFocusName}
                  methods={methods}
                />
              </div>
            </div>
            <div style={{marginTop: 15}} className={styles.balance}>
              Balance: {getBalanceString(selectedToAsset ? +selectedToAsset.totalBalance : 0, 8)}{' '}
              {selectedToAsset?.symbol || ''}
            </div>

            <div style={{width: '100%', borderTop: '1px solid rgba(38, 40, 50, 0.1)', margin: '25px 0'}} />
            <div className={styles.conversionRate}>
              Conversion Rate: 1 {selectedFromAsset.assetId} = {getBalanceString(Number(fromToRate?.rate || 0), 8)}{' '}
              {selectedToAsset?.assetId || ''}
            </div>
          </>
        )}

        <div style={{height: 30}} />
        <button type='submit' className='btn btn-primary' disabled={isLoading || !!inputErrorHandler()}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {isLoading ? <span className='spinner-border' /> : exchangeInfo ? 'Confirm Exchange' : 'Exchange'}
        </button>

        {exchangeInfo && fromToRate && (
          <button
            className={classNames('btn', 'btn-primary', styles.backBtn)}
            onClick={e => {
              e.preventDefault();
              setExchangeInfo(null);
              setRequestError('');
            }}
          >
            Back
          </button>
        )}

        {requestError ? <RequestError requestError={requestError} /> : null}
      </form>
    </FormProvider>
  );
};

export default observer(Exchange);
