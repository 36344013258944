// eslint-disable-next-line import/no-extraneous-dependencies
import {validate} from 'bitcoin-address-validation';
// eslint-disable-next-line import/no-extraneous-dependencies
import {ethers} from 'ethers';
import {getUnstoppabledomainsAddress} from '../services/UdService';

/**
 * Validate eth or btc address
 */
// @ts-ignore
// eslint-disable-next-line consistent-return

// TODO: need check libs
const notCheckList = ['XRP', 'LTC', 'TRX', 'DOGE', 'AVAX', 'NEAR', 'DOT', 'XTZ', 'ADA'];

export const validateAddress = async (value: string, network: string): Promise<string> => {
  try {
    if (value.includes('.')) {
      const {records} = await getUnstoppabledomainsAddress(value);
      const unstoppableKey = Object.keys(records).find(record => record.includes(network.replace('BSC', 'BNB')));
      return unstoppableKey ? records[unstoppableKey] : '';
    }

    if (ethers.utils.isAddress(value)) {
      return value;
    }
    if (notCheckList.includes(network)) {
      return value;
    }
    if (validate(value)) {
      // BTC check address
      return value;
    }

    return '';
  } catch (error) {
    console.log('validateAddress-ERROR', error);
    return '';
  }
};
