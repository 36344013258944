import React from 'react';
import styles from '../styles.module.scss';
import {getBalanceString} from '../../../../../utils/formats';
import danger from '../../../../../assets/icons/danger.svg';
import {ExchangeInfo, ExchangeRate} from '../../../../../services/AssetsService';

type Props = {
  exchangeInfo: ExchangeInfo;
  fromToRate: ExchangeRate;
  selectedFromAsset: any;
  selectedToAsset: any;
  watchFromAmountCrypto: string;
};

const TradeSummaryInfo: React.FC<Props> = ({
  exchangeInfo,
  fromToRate,
  selectedFromAsset,
  selectedToAsset,
  watchFromAmountCrypto,
}) => {
  return (
    <div
      style={{
        flexGrow: 1,
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div className={styles.title}>Transaction Summary</div>
      <div>
        <div className={styles.enterAmount}>From</div>
        <div className={styles.titleAmount}>
          {exchangeInfo.fromAssetId} {watchFromAmountCrypto}
        </div>
        <div style={{height: 27}} />
        <div className={styles.enterAmount}>To</div>
        <div className={styles.titleAmount}>
          {exchangeInfo.toAssetId} {getBalanceString(+exchangeInfo.remainingAmount, 8)}
        </div>
        <div style={{width: '100%', borderTop: '1px solid rgba(38, 40, 50, 0.1)', margin: '25px 0'}} />
        <div className={styles.conversionRate}>
          Conversion Rate: 1 {selectedFromAsset.assetId} = {getBalanceString(Number(fromToRate?.rate || 0), 8)}{' '}
          {selectedToAsset.assetId}
        </div>
        <div style={{height: 5}} />
        <div className={styles.conversionRate}>
          Transaction Fee: {getBalanceString(+exchangeInfo.totalFeeAmount, 8)} {exchangeInfo.toAssetId}
        </div>
      </div>
    </div>
  );
};

export default TradeSummaryInfo;
