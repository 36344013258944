import React, {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import classNames from 'classnames';
import {useGlobalModalContext} from '../index';
import styles from './styles.module.scss';
import close from '../../../../assets/icons/close.svg';
import checked from '../../../../assets/icons/checked.svg';
import AuthService from '../../../../services/AuthService';
import {useStores} from '../../../../stores';
import i18n from '../../../../localize';
import QRCodeBlock from '../CommonModalComponents/QRCodeBlock';
import {save2faStatus} from '../../../../utils/localStorageUtils';
import {qrName} from '../../../../globalConstants';
import CompleteIconBlurComponent from '../CommonModalComponents/CompleteIconBlurComponent';
import RequestError from '../CommonModalComponents/RequestError';
import {MODAL_TYPES} from '../modalConstants';
import {APP_ROUTES} from '../../../Router/constants';
import {Pages} from '../../../Router/types';

type Inputs = {
  emailCode: string;
  twoFaCode: string;
};

const defaultValues = {
  emailCode: '',
  twoFaCode: '',
};

const TwoFactorAuthenticationModal: React.FC = (): JSX.Element => {
  const {user, isMobileScreens} = useStores();
  const {t} = i18n;
  const {hideModal, store, showModal} = useGlobalModalContext();
  const {isDeactivate, withdrawAction} = store.modalProps;

  const methods = useForm<Inputs>({defaultValues});
  const {
    register,
    handleSubmit,
    formState: {errors},
    getValues,
  } = methods;

  const getStep = (): number | null => {
    if (withdrawAction) return 3;
    if (isDeactivate) return null;
    return 0;
  };

  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(getStep);
  const [responseError, setResponseError] = useState('');
  const [secret, setSecret] = useState('');
  const [isCopied, setIsCopied] = useState(false);

  const getSecret = async (): Promise<void> => {
    const newSecret = isDeactivate ? null : await AuthService.generateTwoFASecret();
    setSecret(newSecret?.secret || '');
    setStep(isDeactivate ? 3 : 2);
    setResponseError('');
  };

  const sendMfaEmailCode = async (): Promise<void> => {
    try {
      await AuthService.sendMfaEmailCode();
      setStep(1);
    } catch (e: any) {
      if (e.code === 'USER_ALREADY_CONFIRMED') {
        await getSecret();
      } else {
        setResponseError(e.code);
      }
    }
  };

  useEffect(() => {
    if (isDeactivate) sendMfaEmailCode().then(() => null);
  }, [isDeactivate]);

  const handleBtn = async (): Promise<void> => {
    setIsLoading(true);
    try {
      if (step === 0) {
        await sendMfaEmailCode();
      }
      if (step === 1) {
        await AuthService.validateMfaEmailCode(getValues('emailCode'));

        await getSecret();
      }

      if (step === 2) {
        setStep(3);
      }

      if (step === 3) {
        if (isDeactivate) {
          await AuthService.deactivateTwoFA({authenticationCode: getValues('twoFaCode')});
          save2faStatus(false);
        } else if (withdrawAction) {
          const {isValid} = await AuthService.isValidTwoFACode(getValues('twoFaCode'));
          if (isValid) {
            await withdrawAction();
            if (!isMobileScreens) {
              const options = {
                success: true,
                title: Pages.PORTFOLIO,
              };
              showModal(MODAL_TYPES.WITHDRAW_ASSET, options);
              return;
            }
          } else {
            throw new Error('Invalid code');
          }

          hideModal();
        } else {
          await AuthService.activateTwoFA({authenticationCode: getValues('twoFaCode')});
          save2faStatus(true);
        }
        setStep(4);
      }
    } catch (e: any) {
      console.log('ERROR-TwoFactorAuthenticationModal', e);
      setResponseError(e.code || e.message);
    }
    setIsLoading(false);
  };

  const btnName = (): string => {
    if (step === 0) return 'Start Setup';
    if (step === 3) return 'Submit';

    return 'Next';
  };

  const handleCopy = (): void => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };

  if (step === null) return <div />;

  return (
    <div className={styles.container}>
      <div className={styles.closeWrap} onClick={hideModal}>
        <img className={styles.closeIcon} alt='' src={close} />
      </div>

      {step === 4 && (
        <div className={styles.completeIconWrap}>
          <CompleteIconBlurComponent />
        </div>
      )}

      <FormProvider {...methods}>
        <form
          className={styles.formMobileContent}
          style={step === 0 ? {alignSelf: 'center', height: '100%'} : {}}
          onSubmit={handleSubmit(handleBtn)}
        >
          <div className={styles.content}>
            <div className={classNames(styles.title, styles.titleMainMobile)}>Two Factor Authentication</div>
            {step === 0 && (
              <div className={classNames(styles.description, styles.descriptionStep1)}>
                To setup Two Factor Authentication please <br /> follow the instructions.
              </div>
            )}
            {step === 1 && (
              <>
                <div className={styles.description}>
                  We have sent a code to your email. <br />
                  Please enter the code below to continue.
                </div>

                <div style={{width: '100%'}} className='input-item-wrap'>
                  {/* <div style={{height: 120}} /> */}
                  <label
                    htmlFor='email'
                    className={`input-label ${errors.emailCode || responseError ? 'text-error' : ''}`}
                  >
                    Code {errors.emailCode && errors.emailCode.type === 'required' ? t('inputError.required') : ''}
                    {responseError ? 'Incorrect' : ''}
                  </label>
                  <input
                    type='text'
                    className='input-form'
                    style={errors.emailCode || responseError ? {outline: '1px solid red'} : {}}
                    placeholder='Enter your code here'
                    {...register('emailCode', {required: true, onChange: () => setResponseError('')})}
                  />
                  <div style={{height: 5}} />
                  <p className='input-label'>
                    Have not received?
                    <span
                      onClick={async () => {
                        setIsLoading(true);
                        await AuthService.sendMfaEmailCode();
                        setIsLoading(false);
                      }}
                      className='input-label form-link'
                    >
                      {t('ConfirmationCode.sendAgain')}
                    </span>
                  </p>
                </div>
                <div style={{height: 150, flexGrow: 1}} />
              </>
            )}

            {step === 2 && (
              <>
                <div className={styles.description} style={{marginBottom: 10}}>
                  Please enter the following address to an <br />
                  authenticator of your choice.
                </div>
                <QRCodeBlock
                  stringCode={secret}
                  dataString={`otpauth://totp/${user?.credentials[0].value}?secret=${secret}&issuer=${qrName()}`}
                  copiedAction={handleCopy}
                />
                <div style={{height: 54}} />
              </>
            )}

            {step === 3 && (
              <>
                <div className={styles.description}>Please enter the code from your authenticator.</div>
                <div style={{width: '100%'}} className='input-item-wrap'>
                  {/* <div style={{height: 120}} /> */}
                  <label
                    htmlFor='email'
                    className={`input-label ${errors.twoFaCode || responseError ? 'text-error' : ''}`}
                  >
                    2FA Code {errors.twoFaCode && errors.twoFaCode.type === 'required' ? t('inputError.required') : ''}
                    {responseError ? 'Incorrect' : ''}
                  </label>
                  <input
                    type='text'
                    className='input-form'
                    style={errors.twoFaCode || responseError ? {outline: '1px solid red'} : {}}
                    placeholder='Enter your code here'
                    {...register('twoFaCode', {required: true, onChange: () => setResponseError('')})}
                  />
                </div>
                <div style={{height: 150, flexGrow: 1}} />
              </>
            )}

            {step === 4 && (
              <div className={styles.title}>
                {isDeactivate ? 'Turning off the 2FA successfully' : 'Setup Successfully'}
              </div>
            )}

            {step !== 4 && (
              <button type='submit' className='btn btn-primary' disabled={isLoading}>
                {isLoading ? <span className='spinner-border' /> : btnName()}
              </button>
            )}

            {isCopied && (
              <div
                style={{
                  backgroundColor: '#262832',
                  color: '#FFFFFF',
                  height: 32,
                  marginTop: 19,
                  borderRadius: 100,
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0 11px',
                }}
              >
                <div style={{marginRight: 6}}>Copied</div>
                <img alt='' src={checked} />
              </div>
            )}
          </div>
        </form>
      </FormProvider>

      {responseError ? <RequestError requestError={responseError} /> : <div style={{height: 78, margin: '24px 0'}} />}
    </div>
  );
};

export default TwoFactorAuthenticationModal;
