import {request} from './index';

interface PhoneResponse {
  phone: string;
}

interface PhoneRequest {
  phone: string;
}

interface ConfirmPhoneBody {
  phone: string;
  code: string;
}

const getUserPhone = (): Promise<PhoneResponse> => request({url: `/v3/phone`, method: 'GET'});

const addUserPhone = (data: PhoneRequest): Promise<PhoneResponse> => request({url: `/v3/phone`, data});

const sendPhoneCode = (data: PhoneRequest): Promise<PhoneResponse> => request({url: `/v3/phone/send-code`, data});

const confirmPhoneCode = (data: ConfirmPhoneBody): Promise<PhoneResponse> => request({url: `/v3/phone/confirm`, data});

export default {
  getUserPhone,
  addUserPhone,
  sendPhoneCode,
  confirmPhoneCode,
};
