import React from 'react';
import styles from '../styles.module.scss';
import {theme, themeOnboardingLogo} from '../../../../globalConstants';
import {useStores} from '../../../../stores';
import bg from '../../../../assets/images/bg.png';
import kaizenBg from '../../../../assets/images/bg_kaizen.png';

type Props = {
  children: JSX.Element;
};

const authBackground = theme === 'kaizen' ? 'inherit' : 'var(--mainBlue)';

const AuthLayout: React.FC<Props> = ({children}) => {
  const {isMobileScreens} = useStores();
  return (
    <div
      className={styles.main}
      style={{
        backgroundImage: `url(${theme === 'kaizen' ? kaizenBg : bg})`,
      }}
    >
      {themeOnboardingLogo() ? (
        <div
          style={{
            // backgroundColor: 'var(--mainBlue)',
            backgroundColor: authBackground,
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          {isMobileScreens ? null : <img width={302} src={themeOnboardingLogo()} alt='' />}
        </div>
      ) : (
        <div />
      )}
      {children}
    </div>
  );
};

export default AuthLayout;
