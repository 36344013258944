import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import classNames from 'classnames';
import styles from './styles.module.scss';
import arrow from '../../../../../assets/icons/arrow.svg';
import {roundingBalance} from '../../../../../utils/formats';
import soon from '../../../../../assets/icons/soon.svg';
import {useStores} from '../../../../../stores';

type Props = {
  title: string;
  amount: string;
  changeRate: string;
  isSoon?: boolean;
  path?: string;
  icon?: JSX.Element;
};

const CardItem: React.FC<Props> = ({title, amount, changeRate, isSoon, path, icon}) => {
  const {currency} = useStores();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className={styles.cardWrap}>
      <div className={styles.cardIcon}>{icon}</div>
      <div className={styles.cardTitleAmount}>
        <div className={styles.cardAmount}>
          {currency}
          {roundingBalance(amount, 2)}
        </div>
        <div className={styles.cardTitle}>{title}</div>
      </div>

      <div className={styles.cardChangeRate}>
        <div className={classNames(styles.cardChangeRateWrap, +changeRate >= 0 ? {} : styles.cardChangeRateMinus)}>
          {+changeRate >= 0 ? '+' : ''}
          {roundingBalance(changeRate, 2)} %{isSoon && <img src={soon} style={{marginLeft: 'auto'}} alt='' />}
        </div>

        {path && !isSoon && location.pathname !== path && (
          <div className={styles.arrow} onClick={() => navigate(path)}>
            <img className={styles.arrowIcon} src={arrow} alt='' />
          </div>
        )}
      </div>
    </div>
  );
};

export default CardItem;
