import React, {useEffect, useState} from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import {APP_ROUTES} from './constants';
import {Pages} from './types';
import ProtectedRoute from './ProtectedRoute';
import SignIn from '../Pages/Auth/SignIn';
import SignUp from '../Pages/Auth/SignUp';
import 'styles/global.scss';
import Main from '../Pages/Main';
import ForgotPassword from '../Pages/Auth/ForgotPassword';
import ConfirmationCode from '../Pages/Auth/ConfirmationCode';
import TwoFactorAuthentication from '../Pages/Auth/TwoFactorAuthentication';
import KYCScreen from '../Pages/Auth/KYC';
import Portfolio from '../Pages/Main/Portfolio';
import Settings from '../Pages/Main/Settings';
import Banking from '../Pages/Main/Banking';
import Earn from '../Pages/Main/Earn';
import IndividualToken from '../Pages/Main/IndividualToken';
import Support from '../Pages/Main/Support';
import DeleteAccount from '../Pages/Main/DeleteAccount';
import MobileModals from '../Pages/Main/MobileModals';

const withNavigation = (Component: JSX.Element): JSX.Element => {
  return <Main component={Component} />;
};

export const Router: React.FC = () => {
  // const location = useLocation();
  // // console.log('location', location);
  //
  // const [displayLocation, setDisplayLocation] = useState(location);
  // const [transitionStage, setTransitionStage] = useState('fadeIn');
  //
  // useEffect(() => {
  //   if (location !== displayLocation) setTransitionStage('fadeOut');
  // }, [location, displayLocation]);

  return (
    <div className='app'>
      {/* <div */}
      {/*   className={`app ${transitionStage}`} */}
      {/*   onAnimationEnd={() => { */}
      {/*     if (transitionStage === 'fadeOut') { */}
      {/*       setTransitionStage('fadeIn'); */}
      {/*       setDisplayLocation(location); */}
      {/*     } */}
      {/*   }} */}
      {/* > */}
      <Routes
      // location={displayLocation}
      >
        <Route
          path={APP_ROUTES[Pages.Base]}
          element={<ProtectedRoute protectionType='user' element={withNavigation(<Portfolio />)} />}
        />

        <Route path={APP_ROUTES[Pages.PORTFOLIO]}>
          <Route
            path=':params'
            element={<ProtectedRoute protectionType='user' element={withNavigation(<MobileModals />)} />}
          />
        </Route>

        <Route
          path={APP_ROUTES[Pages.EARN]}
          element={<ProtectedRoute protectionType='user' element={withNavigation(<Earn />)} />}
        />
        <Route
          path={APP_ROUTES[Pages.BANKING]}
          element={<ProtectedRoute protectionType='user' element={withNavigation(<Banking />)} />}
        />
        <Route path={APP_ROUTES[Pages.BANKING]}>
          <Route
            path=':params'
            element={<ProtectedRoute protectionType='user' element={withNavigation(<MobileModals />)} />}
          />
        </Route>
        <Route path={APP_ROUTES[Pages.ASSET]}>
          <Route
            path=':assetId'
            element={<ProtectedRoute protectionType='user' element={withNavigation(<IndividualToken />)} />}
          />
        </Route>

        <Route path={APP_ROUTES[Pages.EARN]}>
          <Route
            path=':params'
            element={<ProtectedRoute protectionType='user' element={withNavigation(<MobileModals />)} />}
          />
        </Route>

        <Route path={APP_ROUTES[Pages.TRANSACTIONS]}>
          <Route
            path=':params'
            element={<ProtectedRoute protectionType='user' element={withNavigation(<MobileModals />)} />}
          />
        </Route>
        <Route
          path={APP_ROUTES[Pages.SUPPORT]}
          element={<ProtectedRoute protectionType='user' element={withNavigation(<Support />)} />}
        />
        <Route
          path={APP_ROUTES[Pages.DELETE_ACCOUNT]}
          element={<ProtectedRoute protectionType='user' element={withNavigation(<DeleteAccount />)} />}
        />
        <Route
          path={APP_ROUTES[Pages.SETTINGS]}
          element={<ProtectedRoute protectionType='user' element={withNavigation(<Settings />)} />}
        />

        <Route path={APP_ROUTES[Pages.SETTINGS]}>
          <Route
            path=':params'
            element={<ProtectedRoute protectionType='user' element={withNavigation(<MobileModals />)} />}
          />
        </Route>

        <Route
          path={APP_ROUTES[Pages.SignIn]}
          element={<ProtectedRoute protectionType='guest' element={<SignIn />} />}
        />
        <Route
          path={APP_ROUTES[Pages.SignUp]}
          element={<ProtectedRoute protectionType='guest' element={<SignUp />} />}
        />
        <Route
          path={APP_ROUTES[Pages.ForgotPassword]}
          element={<ProtectedRoute protectionType='guest' element={<ForgotPassword />} />}
        />
        <Route
          path={APP_ROUTES[Pages.ConfirmationCode]}
          element={<ProtectedRoute protectionType='guest' element={<ConfirmationCode />} />}
        />
        <Route
          path={APP_ROUTES[Pages.TwoFactorAuthentication]}
          element={<ProtectedRoute protectionType='guest' element={<TwoFactorAuthentication />} />}
        />
        <Route
          path={APP_ROUTES[Pages.KYC]}
          element={<ProtectedRoute protectionType='guest' element={<KYCScreen />} />}
        />
      </Routes>
    </div>
  );
};

export default Router;
