import React, {ReactElement, useCallback, useEffect, useRef, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import classNames from 'classnames';
import styles from './styles.module.scss';
import close from '../../../../assets/icons/close.svg';
import Spinner from '../../../common/Spinner';
import {AssetsServices, DepositInfo, WithdrawalInfo} from '../../../../services/AssetsService';
import {Currencies, useStores} from '../../../../stores';
import {getBalanceString, roundingBalance} from '../../../../utils/formats';
import {validateAddress} from '../../../../utils/validateAddress';
import i18n from '../../../../localize';
import danger from '../../../../assets/icons/danger.svg';
import {initApp} from '../../../../stores/actions/init';
import {get2faStatus} from '../../../../utils/localStorageUtils';
import {APP_ROUTES} from '../../../Router/constants';
import {Pages} from '../../../Router/types';
import InputAmount from '../CommonModalComponents/InputAmount';
import {EVENT_NAMES, useAnalytics} from '../../../../services/Analytics';
import CommonDropDown from '../CommonModalComponents/CommonDropDown';
import {useGlobalModalContext} from '../index';
import EarningSuccessfully from '../CommonModalComponents/EarningSuccessfully';
import {MODAL_TYPES} from '../modalConstants';

type Inputs = {
  amount: string;
  address: string;
};

const defaultValues = {
  amount: '',
  address: '',
};

const WithdrawModal: React.FC = (): JSX.Element => {
  const {ratesRaw, currency, isMobileScreens, user} = useStores();
  const userEmail = user?.credentials[0]?.value;
  const {myLogEvent} = useAnalytics();
  const navigate = useNavigate();
  const containerRef = useRef<HTMLInputElement>(null);
  const {t} = i18n;
  const methods = useForm<Inputs>({defaultValues});
  const {
    register,
    handleSubmit,
    formState: {errors},
    watch,
    setValue,
    setError,
    clearErrors,
  } = methods;

  const {hideModal, store, setModalStore, showModal} = useGlobalModalContext();
  const {asset, success} = store.modalProps;

  const currencySymbol = currency === Currencies.USD ? 'USD' : 'EUR';
  const assetRate = ratesRaw.find(
    assetRateRaw => assetRateRaw.fromAssetId === asset?.id && assetRateRaw.toAssetId === currencySymbol
  );

  const [isLoading, setIsLoading] = useState(true);
  const [availableNetworks, setAvailableNetworks] = useState<DepositInfo[]>([]);
  const [selectedNetwork, setSelectedNetwork] = useState<DepositInfo | null>(null);
  const [isCurrency, setIsCurrency] = useState(false);
  const [summaryInfo, setSummaryInfo] = useState<WithdrawalInfo | null>(null);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [address, setAddress] = useState('');
  const [requestError, setRequestError] = useState('');

  const watchAmount = watch('amount');
  const watchAddress = watch('address');

  const twoFaStatus = get2faStatus();
  const [is2FaOff, setIs2FaOff] = useState(false);

  const getCoinAddress = useCallback(
    async (assetId: string) => {
      setIsLoading(true);

      try {
        const depositInfo = await AssetsServices.getDepositInfo(assetId);

        /* error for an empty array of addresses and networks */
        if (!depositInfo.length) throw new Error('backendErrors:FAILED_CODE_GENERATION');

        // const [startedAddress] = depositInfo.filter(deposit => deposit.depositAddress);

        setSelectedNetwork(depositInfo[0]);
        setAvailableNetworks(depositInfo);
        setRequestError('');
      } catch (error: any) {
        console.log('ERROR-getCoinAddress', error);
        setRequestError(error.code);
      }
      setIsLoading(false);
    },
    [asset]
  );

  useEffect(() => {
    if (asset) {
      getCoinAddress(asset.assetId).then(() => null);

      myLogEvent(EVENT_NAMES.WEB_WITHDRAW_OPENED, {asset: asset.assetId});
    }
  }, [asset]);

  const getWithdrawalInfo = async (isValidAddress: string): Promise<void> => {
    // Check deposit wallet for withdrawal
    const depositInfo = await AssetsServices.getDepositInfo(asset.assetId);
    const [{depositAddress}] = depositInfo.filter(info => info.networkId === selectedNetwork?.networkId);

    if (!depositAddress && selectedNetwork)
      await AssetsServices.createDepositAddress(asset.assetId, selectedNetwork?.networkId);

    const withdrawalInfo = await AssetsServices.getWithdrawalInfo({
      address: isValidAddress,
      networkId: selectedNetwork?.networkId || '',
      amount: isCurrency ? +watchAmount / (assetRate?.data.currentPrice || 1) : +watchAmount,
      assetId: asset.assetId,
    });
    setSummaryInfo(withdrawalInfo);
  };

  const withdraw = async (): Promise<void> => {
    await AssetsServices.withdrawalAsset({
      address,
      networkId: selectedNetwork?.networkId || '',
      amount: isCurrency ? +watchAmount / (assetRate?.data.currentPrice || 1) : +watchAmount,
      info: summaryInfo as WithdrawalInfo,
    });
    await myLogEvent(EVENT_NAMES.WEB_CEFI_WITHDRAWAL, {amount: watchAmount, assetId: asset.assetId});
    await initApp();

    setIsSuccessful(true);
  };

  const handleTwoFa = (isDeactivate: boolean): void => {
    const options = {
      isDeactivate,
      withdrawAction: withdraw,
      backAction: navigate(APP_ROUTES[Pages.Base]),
      title: Pages.PORTFOLIO,
    };
    if (isMobileScreens) {
      setModalStore(options);
      navigate(`${APP_ROUTES[Pages.SETTINGS]}/${MODAL_TYPES.TWO_FACTOR_AUTHENTICATION_MODAL}`);
    } else {
      showModal(MODAL_TYPES.TWO_FACTOR_AUTHENTICATION_MODAL, options);
    }
  };

  const handleWithdraw = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const isValidAddress = selectedNetwork ? await validateAddress(watchAddress, selectedNetwork?.networkId) : '';
      if (!isValidAddress) {
        setError('address', {
          type: 'validate',
          message: 'Invalid',
        });
        throw new Error('Invalid');
      } else {
        setAddress(isValidAddress);
      }

      if (summaryInfo) {
        if (userEmail === 'demo@fideum.com') {
          await withdraw();
        } else if (twoFaStatus) {
          handleTwoFa(false);
        } else {
          setIs2FaOff(true);
        }
      } else {
        await getWithdrawalInfo(isValidAddress);
      }
      setRequestError('');
    } catch (error: any) {
      console.log('handleWithdraw-ERROR', error);
      setRequestError(error.code);
    }
    setIsLoading(false);
  };

  if (isSuccessful || success) {
    return <EarningSuccessfully text='Withdrawal Successfully Requested' />;
  }

  if (is2FaOff && !twoFaStatus) {
    return (
      <div className={styles.container}>
        <div className={styles.closeWrap} onClick={hideModal}>
          <img className={styles.closeIcon} alt='' src={close} />
        </div>
        <div className={styles.container} style={{justifyContent: 'center'}}>
          <div className={styles.title}>Two Factor Authentication</div>
          <div className={styles.description} style={{maxWidth: 440}}>
            For security reasons, a 2FA setup is required. Please follow the instructions.
          </div>
          <button
            onClick={() => {
              navigate(APP_ROUTES[Pages.SETTINGS]);
              hideModal();
            }}
            className='btn btn-primary'
            style={{maxWidth: 440}}
          >
            Go to Settings
          </button>
        </div>
      </div>
    );
  }

  const itemComponentAddresses = (selectedItemAddress: DepositInfo): ReactElement => {
    return (
      <div>
        <div className={styles.bankName}>{selectedItemAddress.networkId}</div>
      </div>
    );
  };

  return (
    <div ref={containerRef} className={styles.container} id='withdrawContainer'>
      <div className={styles.closeWrap} onClick={hideModal}>
        <img className={styles.closeIcon} alt='' src={close} />
      </div>
      <div className={styles.title}>{summaryInfo ? 'Summary' : `Withdraw ${asset.name}`}</div>
      {isLoading && !selectedNetwork ? (
        <Spinner />
      ) : (
        <div className={styles.content}>
          <FormProvider {...methods}>
            <form style={{position: 'relative'}} onSubmit={handleSubmit(handleWithdraw)}>
              {summaryInfo ? (
                <>
                  <div className={styles.summaryBlockTitle}>Amount</div>
                  <div className={styles.cryptoAmount}>
                    {`${asset.symbol} ${
                      isCurrency
                        ? getBalanceString(+watchAmount / (assetRate?.data.currentPrice || 1), 8)
                        : roundingBalance(watchAmount, 8)
                    }`}
                  </div>
                  <div className={styles.currencyAmount}>
                    {`${currency} ${
                      isCurrency ? watchAmount : getBalanceString(+watchAmount * (assetRate?.data.currentPrice || 1), 2)
                    }`}
                  </div>
                  <div className={styles.conversion}>
                    Conversion Rate: 1 {asset.symbol} = {currency} {assetRate?.data.currentPrice}
                  </div>
                  <div className={styles.conversion}>
                    Transaction Fee: {roundingBalance(summaryInfo?.totalFeeAmount.toString())} {asset.symbol}
                  </div>
                  <div className={styles.divider} />
                  <div className={styles.summaryBlockTitle}>Network</div>
                  <div className={styles.cryptoAmount}>{selectedNetwork?.networkId}</div>
                  <div className={styles.divider} />
                  <div className={styles.summaryBlockTitle}>Address</div>
                  <div className={styles.cryptoAddress}>{watchAddress}</div>
                </>
              ) : (
                <>
                  <InputAmount
                    errors={errors}
                    asset={asset}
                    register={register}
                    assetRate={assetRate}
                    setValue={setValue}
                    watchAmount={watchAmount}
                    isCurrency={isCurrency}
                    setIsCurrency={setIsCurrency}
                    clearErrors={clearErrors}
                  />

                  {availableNetworks.length > 1 && (
                    <>
                      <div className={styles.enterAmount}>Choose Network</div>
                      <CommonDropDown
                        containerRef={containerRef}
                        data={availableNetworks}
                        itemComponent={itemComponentAddresses}
                        setSelectedData={setSelectedNetwork}
                        selectedData={selectedNetwork}
                      />
                    </>
                  )}

                  <div className='input-item-wrap' style={{marginTop: 22}}>
                    <label
                      htmlFor='address'
                      className={`input-label ${errors.address ? 'text-error' : ''}`}
                      // style={{fontSize: '1rem'}}
                    >
                      Enter Wallet Address{' '}
                      {errors.address && errors.address?.type === 'required' ? t('inputError.required') : ''}
                      {errors.address && errors.address?.type === 'validate' ? t('inputError.invalid') : ''}
                    </label>
                    <input
                      style={errors.address ? {outline: '1px solid red'} : {}}
                      id='address'
                      type='text'
                      className='input-form'
                      placeholder='Enter wallet address here'
                      {...register('address', {required: true})}
                    />
                  </div>
                  <div className={styles.note}>
                    <div style={{fontWeight: 700}}>Note</div>: Please ensure you are depositing asset using{' '}
                    {selectedNetwork ? selectedNetwork?.networkId : ''} network.
                  </div>
                </>
              )}

              {requestError ? (
                <div
                  style={{
                    height: 78,
                    backgroundColor: 'rgba(255, 0, 0, 0.1)',
                    margin: '24px 0',
                    borderRadius: 10,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <img style={{height: 24, margin: '0 24px'}} alt='' src={danger} />
                  <div style={{marginRight: 24}}>{requestError}</div>
                </div>
              ) : (
                <div style={{height: 78, margin: '24px 0'}} />
              )}

              <button type='submit' className='btn btn-primary' disabled={isLoading}>
                {/* eslint-disable-next-line no-nested-ternary */}
                {isLoading ? <span className='spinner-border' /> : summaryInfo ? 'Confirm' : 'Next'}
              </button>

              {summaryInfo && (
                <button
                  className={classNames('btn', 'btn-primary', styles.backBtn)}
                  onClick={e => {
                    e.preventDefault();
                    setSummaryInfo(null);
                    setRequestError('');
                  }}
                >
                  Back
                </button>
              )}
            </form>
          </FormProvider>
        </div>
      )}
    </div>
  );
};

export default WithdrawModal;
