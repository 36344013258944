import React from 'react';
import styles from './styles.module.scss';
import InfoCards from '../InfoCards';
import HeaderTitle from '../HeaderTitle';

type Props = {
  headerTitle: string;
  hideUd?: boolean;
};

const HeaderCards: React.FC<Props> = ({headerTitle, hideUd = false}) => {
  return (
    <div className={styles.headerCards}>
      <HeaderTitle headerTitle={headerTitle} hideUd={hideUd} />
      <InfoCards />
    </div>
  );
};

export default HeaderCards;
