import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import styles from './styles.module.scss';
import BackBtn from '../../Auth/common/BackBtn';
import WithdrawalNotAvailable from './WithdrawalNotAvailable';
import FiatService, {BankAddressResponse} from '../../../../services/FiatService';
import Spinner from '../../../common/Spinner';
import WithdrawalFiat from './WithdrawalFiat';
import {useStores} from '../../../../stores';
import {useGlobalModalContext} from '../index';

const BankingWithdrawModal: React.FC = () => {
  const {store, hideModal} = useGlobalModalContext();

  const {openDeposit} = store.modalProps;

  const [isLoading, setIsLoading] = useState(true);
  const [bankAddresses, setBankAddresses] = useState<BankAddressResponse[]>([]);

  const containerRef = useRef<HTMLInputElement>(null);

  const getBankAddress = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const address = await FiatService.getAllBankAddress();
      if (address.content && address.content.length) {
        setBankAddresses(address.content);
      }
    } catch (e) {
      console.log('getAllBankAddress-ERROR', e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getBankAddress().catch(e => console.log('getAllBankAddress-ERROR', e));
  }, []);

  return (
    <div ref={containerRef} id='withdrawContainer' className={styles.container}>
      <div className={styles.header}>
        <BackBtn backFn={hideModal} />
      </div>
      {/* eslint-disable-next-line no-nested-ternary */}
      {isLoading ? (
        <div className='justify-row-center'>
          <Spinner />
        </div>
      ) : bankAddresses.length ? (
        <WithdrawalFiat containerRef={containerRef} bankAddresses={bankAddresses} />
      ) : (
        <WithdrawalNotAvailable openDeposit={openDeposit} closeModal={hideModal} />
      )}
    </div>
  );
};

export default BankingWithdrawModal;
