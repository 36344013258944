import React from 'react';
import checked from '../../../assets/icons/checked.svg';
import styles from './styles.module.scss';

const Copied: React.FC = () => {
  return (
    <div className={styles.copiedWrap}>
      <div style={{marginRight: 6}}>Address Copied</div>
      <img alt='' src={checked} />
    </div>
  );
};

export default Copied;
