import React, {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useForm, FormProvider, SubmitHandler} from 'react-hook-form';
import styles from '../styles.module.scss';
import BackBtn from '../common/BackBtn';
import AuthTitle from '../common/AuthTitle';
import I18n from '../../../common/I18n';
import AuthService, {TSignInResponse} from '../../../../services/AuthService';
import i18n from '../../../../localize';
import {save2faStatus, saveToken} from '../../../../utils/localStorageUtils';
import {APP_ROUTES} from '../../../Router/constants';
import {Pages} from '../../../Router/types';
import {useStores} from '../../../../stores';
import AuthSnackComponent from '../common/AuthSnackComponent';
import {errorDescriptionHandler} from '../authUtils/errorDescriptionHandler';
import AuthLayout from '../AuthLayout';
import MessageSendSvgComponent from '../../../../assets/icons/MessageSendSvgComponent';

type Inputs = {
  confirmationCode: string;
};

const defaultValues = {
  confirmationCode: '',
};

const ConfirmationCode: React.FC = () => {
  const {setUser, setSnackComponent} = useStores();
  const {t} = i18n;
  const location = useLocation();
  const navigate = useNavigate();
  const methods = useForm<Inputs>({defaultValues});
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = methods;

  const [loading, setLoading] = useState<boolean>(false);

  const handleConfirmation: SubmitHandler<Inputs> = async data => {
    setLoading(true);
    try {
      await AuthService.confirm({
        confirmationCode: data.confirmationCode,
        email: location.state?.email,
        passwordHash: location.state?.passwordHash,
      });
      const signInRes: TSignInResponse = await AuthService.signIn({
        passwordHash: location.state?.passwordHash,
        username: location.state?.email,
        deviceType: 'web',
        appVersion: '0.01',
      });
      saveToken(signInRes.token);
      save2faStatus(signInRes['2fa']);

      const userSelf = await AuthService.getSelf();
      await setUser(userSelf);

      navigate(APP_ROUTES[Pages.Base]);
    } catch (error: any) {
      console.log('ERROR-handleConfirmation', error);
      // TODO: title ?
      setSnackComponent(
        <AuthSnackComponent title='Code is Incorrect.' description={errorDescriptionHandler(error.code || '')} />
      );
    }
    setLoading(false);
  };

  return (
    <AuthLayout>
      <div className={styles.rightModule}>
        <BackBtn backFn={() => navigate(APP_ROUTES[Pages.SignIn])} />

        <div className={styles.formWrap}>
          <div className={styles.iconMessage}>
            <MessageSendSvgComponent fill='var(--mainBlue)' />
          </div>
          <AuthTitle title='ConfirmationCode.confirm.title' description='ConfirmationCode.confirm.description' />
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleConfirmation)}>
              <div style={{marginTop: '-2rem'}} className='input-item-wrap'>
                <input
                  type='text'
                  className='input-form'
                  style={errors.confirmationCode ? {outline: '1px solid red'} : {}}
                  placeholder={t('ConfirmationCode.confirmationCode') || ''}
                  {...register('confirmationCode', {required: true})}
                />
                <div style={{height: '2rem'}} />
                <button type='submit' className='btn btn-primary' disabled={loading}>
                  {loading ? <span className='spinner-border' /> : <I18n tKey='ConfirmationCode.confirm' />}
                </button>

                <div className='input-item-wrap text-under-button'>
                  <p className='input-label text-center'>
                    {t('ConfirmationCode.notReceive')}
                    <span
                      onClick={async () => {
                        await AuthService.resendConfirmationCode();
                      }}
                      className='input-label form-link'
                    >
                      {t('ConfirmationCode.sendAgain')}
                    </span>
                  </p>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </AuthLayout>
  );
};

export default ConfirmationCode;
