import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';
import {observer} from 'mobx-react';
import {APP_ROUTES} from '../../../Router/constants';
import {Pages} from '../../../Router/types';
import {Currencies, useStores} from '../../../../stores';
import styles from './styles.module.scss';
import HeaderTitle from '../common/HeaderTitle';
import {getBalanceString} from '../../../../utils/formats';
import {CoingeckoService} from '../../../../services/coingeckoService';
import PriceChart from './PriceChart';
import Spinner from '../../../common/Spinner';
import {theme} from '../../../../globalConstants';
import BackArrowSVG from '../../../../assets/icons/BackArrowSVG';
import DepositIconSVG from '../../../../assets/icons/DepositIconSVG';
import ExchangeIconSVG from '../../../../assets/icons/ExchangeIconSVG';
import LatestTransactions from './LatestTransactions';
import {MODAL_TYPES} from '../../Modals/modalConstants';
import {useGlobalModalContext} from '../../Modals';

const unixTimestampNow = moment().unix();
const unixTimestampDay = moment().subtract(1, 'day').unix();
const unixTimestampWeak = moment().subtract(7, 'day').unix();
const unixTimestampMonth = moment().subtract(1, 'month').unix();
const unixTimestampYear = moment().subtract(1, 'year').unix();

const IndividualToken: React.FC = () => {
  const {assets, currency, ratesCeFi, isMobileScreens} = useStores();
  const navigate = useNavigate();
  const {assetId} = useParams();
  const asset = assets.find(assetItem => assetItem.assetId === assetId);

  const {showModal, setModalStore} = useGlobalModalContext();

  const [priceData, setPriceData] = useState([]);
  const [timeLine, setTimeline] = useState(unixTimestampDay);
  const [isLoading, setIsLoading] = useState(false);

  const getAssetRatesData = async (): Promise<void> => {
    const vsCurrency = currency === Currencies.USD ? 'usd' : 'eur';
    setIsLoading(true);
    try {
      const pricesChart =
        asset &&
        (await CoingeckoService.rangeData({
          assetName: asset.coinGeckoId,
          currency: vsCurrency,
          from: timeLine,
          to: unixTimestampNow,
        }));
      setPriceData(pricesChart.prices);
    } catch (e) {
      console.log('ERROR-rangeData', e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!asset) {
      navigate(APP_ROUTES[Pages.Base]);
    }
    getAssetRatesData().then(() => null);
  }, [asset, timeLine]);

  const goToModal = (type: string): void => {
    const options = {
      asset,
      title: Pages.PORTFOLIO,
    };
    if (isMobileScreens) {
      setModalStore(options);
      navigate(`${APP_ROUTES[Pages.PORTFOLIO]}/${type}`);
    } else {
      showModal(type, options);
    }
  };

  if (!assetId || !asset) return null;

  // TODO: change theme state
  const isHideBlock = ['kaizen'].includes(theme);

  const tradeEnabled = ratesCeFi.filter(assetItem => {
    if (assetItem.toAssetId === asset.assetId) return true;
    if (assetItem.fromAssetId === asset.assetId) return true;
    return false;
  });

  return (
    <div className={styles.individualTokenWrap}>
      <div className={styles.headerTitle}>
        <HeaderTitle headerTitle={assetId} showBackBtn />
      </div>
      <div className={styles.mobBackTitle}>
        <div onClick={() => navigate(-1)} className={styles.mobBack}>
          <BackArrowSVG fill='var(--mainBlue)' />
        </div>
        <div className={styles.mobTitle}>
          <img style={{width: 32, height: 32, borderRadius: 5, marginRight: 16}} src={asset.icon} alt='' />
          {asset.assetName}
        </div>
        <div style={{width: 32}} />
      </div>
      <div className={styles.headerLine} />
      <div className={styles.chartActionBlock}>
        <div className={styles.chartBlock}>
          <div className={styles.chartBlockWrap}>
            <div className={styles.assetInfoWrap}>
              <div className={styles.graphIcon}>
                <img style={{width: 64, height: 64, borderRadius: 5, marginRight: 22}} src={asset.icon} alt='' />
                <div>
                  <div className={styles.assetName}>{asset.name}</div>
                  <div className={styles.assetId}>{asset.assetId}</div>
                </div>
              </div>
              <div className={styles.assetChangeWrap}>
                <div className={styles.assetName}>
                  {currency}
                  {getBalanceString(+asset.rate.value, 6)}
                </div>
                <div
                  className={styles.assetChange}
                  style={{
                    color: asset.rate.change24Percent.toString()[0] === '-' ? '#FF0000' : '#2B9A18',
                  }}
                >
                  {asset.rate.change24Percent.toString()[0] === '-' ? '' : '+'}
                  {asset.rate?.change24Percent === 0 || asset.rate?.change24Percent
                    ? getBalanceString(+asset.rate.change24Percent, 2)
                    : '--'}
                  %
                </div>
              </div>
            </div>
            <div className={styles.priceChartWrap}>
              {priceData.length && !isLoading ? (
                <PriceChart data={priceData} />
              ) : (
                <div className={styles.spinnerWrap}>
                  <Spinner />
                </div>
              )}
            </div>
            <div className={styles.timeLine}>
              <div
                onClick={() => {
                  if (!isLoading) setTimeline(unixTimestampDay);
                }}
                className={classNames(
                  styles.timeLineItem,
                  timeLine === unixTimestampDay ? styles.timeLineItemActive : ''
                )}
              >
                1D
              </div>
              <div
                onClick={() => {
                  if (!isLoading) setTimeline(unixTimestampWeak);
                }}
                className={classNames(
                  styles.timeLineItem,
                  timeLine === unixTimestampWeak ? styles.timeLineItemActive : ''
                )}
              >
                1W
              </div>
              <div
                onClick={() => {
                  if (!isLoading) setTimeline(unixTimestampMonth);
                }}
                className={classNames(
                  styles.timeLineItem,
                  timeLine === unixTimestampMonth ? styles.timeLineItemActive : ''
                )}
              >
                1M
              </div>
              <div
                onClick={() => {
                  if (!isLoading) setTimeline(unixTimestampYear);
                }}
                className={classNames(
                  styles.timeLineItem,
                  timeLine === unixTimestampYear ? styles.timeLineItemActive : ''
                )}
              >
                1Y
              </div>
            </div>

            <div className={styles.mobileBtnBlock}>
              <div className={styles.totalBalanceTitle}>Total Balance</div>
              <div className={styles.totalBalanceAmount}>
                {getBalanceString(+asset.availableBalance, 8)} {asset.assetId}
              </div>
              <div className={styles.totalBalanceTitle}>
                {currency}
                {asset.rate?.value ? getBalanceString(asset.rate.value * +asset.availableBalance, 2) : '--'}
              </div>

              <div style={{marginTop: 48}}>
                <div style={{display: 'flex'}}>
                  <button
                    onClick={() => goToModal(MODAL_TYPES.DEPOSIT_ASSET)}
                    className='btn btn-primary'
                    style={{backgroundColor: '#F5F4FA', color: 'black', height: 55}}
                  >
                    <DepositIconSVG arrowColor='black' circleBg='#F5F4FA' fill='var(--mainBlue)' />
                    Deposit
                  </button>
                  <div style={{minWidth: 12}} />
                  <button
                    onClick={() => goToModal(MODAL_TYPES.WITHDRAW_ASSET)}
                    className='btn btn-primary'
                    style={{backgroundColor: '#F5F4FA', color: 'black'}}
                  >
                    <div style={{transform: 'rotate(+180deg)'}}>
                      <DepositIconSVG arrowColor='black' circleBg='#F5F4FA' fill='var(--mainBlue)' />
                    </div>
                    Withdraw
                  </button>
                </div>
                <div style={{height: 12}} />
                <button
                  onClick={() => goToModal(MODAL_TYPES.TRADE_ASSET)}
                  className={classNames('btn', 'btn-primary', styles.btnTradeMobile)}
                >
                  <ExchangeIconSVG arrowColor='white' fill='var(--mainBlue)' />
                  Trade
                </button>
              </div>
            </div>

            <div className={styles.graphText}>
              The graph and the price shown are indicative only. The execution price may vary at the moment of the
              transaction.
            </div>
          </div>
        </div>
        <div style={{width: 24}} />
        <div className={styles.actionsWrap}>
          <div style={{padding: 36, display: 'flex', flexDirection: 'column', width: '100%'}}>
            <div className={styles.totalBalanceTitle}>Total Balance</div>
            <div className={styles.totalBalanceAmount}>
              {currency} {asset.rate?.value ? getBalanceString(asset.rate.value * +asset.availableBalance, 2) : '--'}
            </div>
            <div className={styles.totalBalanceTitle}>
              {getBalanceString(+asset.availableBalance, 8)} {asset.assetId}
            </div>
            <div className={styles.divider} />
            <button
              style={asset.depositEnabled ? {} : {cursor: 'default', opacity: 0.2}}
              onClick={() => {
                if (!asset.depositEnabled) return;
                goToModal(MODAL_TYPES.DEPOSIT_ASSET);
              }}
              className='btn btn-primary'
            >
              Deposit
            </button>
            <div style={{height: 20}} />
            <button
              style={tradeEnabled?.length ? {} : {cursor: 'default', opacity: 0.2}}
              onClick={() => {
                if (!tradeEnabled?.length) return;
                goToModal(MODAL_TYPES.TRADE_ASSET);
              }}
              className='btn btn-primary'
            >
              Trade
            </button>
            <div style={{height: 20}} />
            {isHideBlock ? null : (
              <button
                onClick={() => navigate(APP_ROUTES[Pages.EARN])}
                className='btn btn-primary'
                style={{backgroundColor: 'black'}}
              >
                Earn
              </button>
            )}

            <div style={{height: 20}} />
            <div style={{display: 'flex'}}>
              <button
                style={{
                  backgroundColor: '#FFF',
                  color: 'rgba(154, 149, 206, 0.5)',
                  border: '1px solid rgba(154, 149, 206, 0.5)',
                  cursor: 'default',
                }}
                className='btn btn-primary'
              >
                Setup A Recurring Buy
              </button>
              <div style={{width: 12}} />
              <button
                onClick={() => {
                  if (!asset.withdrawalEnabled) return;
                  goToModal(MODAL_TYPES.WITHDRAW_ASSET);
                }}
                className='btn btn-primary'
                style={
                  asset.withdrawalEnabled
                    ? {backgroundColor: '#FFF', color: '#262832', border: '1px solid #262832'}
                    : {cursor: 'default', opacity: 0.2}
                }
              >
                Withdraw
              </button>
            </div>

            <LatestTransactions />
          </div>
        </div>
        <div className={styles.latestTransaction}>
          <LatestTransactions />
        </div>
      </div>
    </div>
  );
};

export default observer(IndividualToken);
