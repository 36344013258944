const LOCAL_STORAGE = {
  USER: '@bb_cefi_web_user',
  TOKEN_KEY: '@bb_cefi_web_storage_token',
  twoFaStatus: '@bb_cefi_web_twoFa_Status',
  currency: '@bb_cefi_web_currency',
};

export const getToken = (): null | string => {
  return localStorage.getItem(LOCAL_STORAGE.TOKEN_KEY);
};

export const saveToken = (token: string): void => {
  localStorage.setItem(LOCAL_STORAGE.TOKEN_KEY, token);
};

export const clearToken = (): void => {
  localStorage.removeItem(LOCAL_STORAGE.TOKEN_KEY);
};

export const save2faStatus = (status: boolean): void => {
  const stringStatus = status?.toString() || 'false';
  localStorage.setItem(LOCAL_STORAGE.twoFaStatus, stringStatus);
};

export const get2faStatus = (): boolean => {
  const stringStatus = localStorage.getItem(LOCAL_STORAGE.twoFaStatus) || 'false';
  return stringStatus === 'true';
};

export const saveCurrency = (currency: string): void => {
  localStorage.setItem(LOCAL_STORAGE.currency, currency);
};

export const getCurrency = (): null | string => {
  return localStorage.getItem(LOCAL_STORAGE.currency);
};
