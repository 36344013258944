import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import CardItem from '../CardItem';
import {getBalanceString} from '../../../../../utils/formats';
import {APP_ROUTES} from '../../../../Router/constants';
import {Pages} from '../../../../Router/types';
import {Currencies, useStores} from '../../../../../stores';
import {calculateCefiPortfolioValueChange} from '../../../../../utils/getAssetsChange';
import TotalSVG from './TotalSVG';
import StakingSVG from './StakingSVG';
import FiatSVG from './FiatSVG';
import RewardsSVG from './RewardsSVG';
import {theme} from '../../../../../globalConstants';

const InfoCards: React.FC = () => {
  const {assets, stackingList, ratesRaw, currency} = useStores();

  const totalCurrencyAmount = assets.reduce((acc, asset) => {
    return acc + asset.balance.availableCash;
  }, 0);

  const fiatAsset = assets.find(asset => asset.isFiat);

  const assetsWithChange = assets?.filter((item: any) => item.balance.available !== 0);
  const avgAssetChange = calculateCefiPortfolioValueChange(assetsWithChange);

  const currencySymbol = currency === Currencies.USD ? 'USD' : 'EUR';

  const allLocked = stackingList.reduce((acc, stackItem) => {
    const stackItemRate = ratesRaw.find(
      rate => rate.toAssetId === currencySymbol && rate.fromAssetId === stackItem.assetId
    );
    return acc + stackItem.amount * Number(stackItemRate?.data?.currentPrice || 0);
  }, 0);

  const allPayedRewardAmount = stackingList.reduce((acc, stackItem) => {
    const stackItemRate = ratesRaw.find(
      rate => rate.toAssetId === currencySymbol && rate.fromAssetId === stackItem.assetId
    );
    return acc + stackItem.payedRewardAmount * Number(stackItemRate?.data?.currentPrice || 0);
  }, 0);

  const avgEarnChange = (): number => {
    const stacksChange = stackingList
      .filter(stack => +stack.amount)
      .map(stack => {
        const stackItemRate = ratesRaw.find(
          rate => rate.toAssetId === currencySymbol && rate.fromAssetId === stack.assetId
        );
        return {
          ...stack,
          balance: {
            availableCash: stack.amount ? +stack.amount * (stackItemRate?.data.currentPrice || 0) : 0,
            available: stack.amount,
          },
          rate: {
            change24Percent: stackItemRate?.data.priceChangePercentage24h || 0,
            value: stackItemRate?.data.currentPrice || 0,
          },
        };
      });

    return calculateCefiPortfolioValueChange(stacksChange) || 0;
  };

  const isHideBlock = ['fideum', 'kaizen'].includes(theme);

  return (
    <div className={styles.infoCards}>
      <div className={styles.infoCardsItemWrap}>
        <CardItem
          amount={getBalanceString(totalCurrencyAmount, 2) || '0'}
          changeRate={avgAssetChange ? avgAssetChange?.toString() : '0'}
          title='Total Portfolio'
          path={APP_ROUTES[Pages.Base]}
          icon={<TotalSVG fill='var(--mainBlue)' />}
        />
      </div>
      <div className={styles.infoCardsItemWrap}>
        <CardItem
          amount={fiatAsset?.balance?.availableCash.toString() || '0'}
          changeRate={fiatAsset?.rate?.change24Percent.toString() || '0'}
          title='Fiat Balance'
          path={APP_ROUTES[Pages.BANKING]}
          icon={<FiatSVG fill='var(--mainBlue)' />}
        />
      </div>
      <div
        className={classNames(styles.infoCardsItemWrap, isHideBlock ? styles.hideBlock : {})}
        style={{visibility: isHideBlock ? 'hidden' : 'visible'}}
      >
        <CardItem
          amount={getBalanceString(allLocked, 2)}
          changeRate={avgEarnChange().toString()}
          title='Locked'
          path={APP_ROUTES[Pages.EARN]}
          icon={<StakingSVG fill='var(--mainBlue)' />}
        />
      </div>
      <div
        className={classNames(styles.infoCardsItemWrap, isHideBlock ? styles.hideBlock : {})}
        style={{visibility: isHideBlock ? 'hidden' : 'visible', marginRight: 0}}
      >
        <CardItem
          amount={allPayedRewardAmount.toString()}
          changeRate={avgEarnChange().toString()}
          title='Earning Rewards'
          path={APP_ROUTES[Pages.EARN]}
          icon={<RewardsSVG fill='var(--mainBlue)' />}
        />
      </div>
    </div>
  );
};

export default InfoCards;
