import React, {useState} from 'react';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import styles from '../styles.module.scss';
import BackBtn from '../common/BackBtn';
import AuthTitle from '../common/AuthTitle';
import I18n from '../../../common/I18n';
import {REGEX} from '../../../../globalConstants';
import i18n from '../../../../localize';
import AuthApiService from '../../../../services/AuthService';
import AuthLayout from '../AuthLayout';
import MessageSendSvgComponent from '../../../../assets/icons/MessageSendSvgComponent';

type Inputs = {
  email: string;
};

const defaultValues = {
  email: '',
};

const ForgotPassword: React.FC = () => {
  const methods = useForm<Inputs>({defaultValues});

  const {t} = i18n;

  const [isSend, setIsSend] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = methods;

  const onSubmit: SubmitHandler<Inputs> = async data => {
    setLoading(true);

    try {
      await AuthApiService.sendRestorePasswordInstruction(data.email);
      setIsSend(true);
    } catch (error) {
      console.log('ERROR-sendRestorePasswordInstruction', error);
    }

    setLoading(false);
  };

  return (
    <AuthLayout>
      <div className={styles.rightModule}>
        <BackBtn />

        <div className={styles.formWrap}>
          {isSend ? (
            <>
              <div className={styles.iconMessage}>
                <MessageSendSvgComponent fill='var(--mainBlue)' />
              </div>
              <AuthTitle title='forgotPassword.checkEmail' description='forgotPassword.instructions' />
            </>
          ) : (
            <>
              <AuthTitle title='forgotPassword.title' description='forgotPassword.description' />
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='input-item-wrap'>
                    <label htmlFor='email' className={`input-label ${errors.email ? 'text-error' : ''}`}>
                      {t('signIn.email')}{' '}
                      {errors.email && errors.email.type === 'pattern' ? t('inputError.invalid') : ''}
                      {errors.email && errors.email.type === 'required' ? t('inputError.required') : ''}
                    </label>
                    <input
                      style={errors.email ? {outline: '1px solid red'} : {}}
                      id='email'
                      type='text'
                      aria-invalid={!!errors.email}
                      className='input-form'
                      placeholder={t('signIn.email.placeholder') || ''}
                      {...register('email', {
                        required: true,
                        pattern: {
                          value: REGEX.EMAIL_VALIDATION,
                          message: t('signIn.invalidEmail'),
                        },
                      })}
                    />
                  </div>

                  <button type='submit' className='btn btn-primary' disabled={loading}>
                    {loading ? <span className='spinner-border' /> : <I18n tKey='forgotPassword.send' />}
                  </button>
                </form>
              </FormProvider>
            </>
          )}
        </div>
      </div>
    </AuthLayout>
  );
};

export default ForgotPassword;
