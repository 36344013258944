import React from 'react';
import {useParams} from 'react-router-dom';
import styles from './styles.module.scss';
import TransactionsList from '../Transactions/TransactionsList';

const LatestTransactions: React.FC = () => {
  const {assetId} = useParams();

  return (
    <>
      <div className={styles.transactionsTitle}>Latest Transactions</div>
      <div className={styles.transactionsBlock}>
        <TransactionsList filterAssetId={assetId} />
      </div>
    </>
  );
};

export default LatestTransactions;
