import REGEX from './regex';
import fideumOnboardingLogo from '../assets/icons/fideumOnboardingLogo.svg';
import kaizenOnboardingLogo from '../assets/icons/kaizenOnboardingLogo.svg';
import fideumLogo from '../assets/icons/fideumLogo.svg';
import kaizenLogo from '../assets/icons/kaizenLogo.svg';
import bbank from '../assets/icons/bb-name.svg';
// eslint-disable-next-line import/extensions
import {env} from '../env';
import fideumIco from '../assets/favicons/fideum64.png';
import kaizenIco from '../assets/favicons/kaizen64.png';

const domain = window.location.hostname;
console.log('domain1', domain);

// REACT_APP_CEFI_WHITE_LABEL=fideum  npx react-inject-env set
// REACT_APP_BACKEND_URL
// PROD is 'https://api2.blockbank.ai';   https://api.fideum.com/  https://api.kaizencx.com
// DEV is 'https://api.blockbank.dev'

// FIDEUM
// PROD https://api.fideum.com

const API_BASE_URL = env.REACT_APP_BACKEND_URL || 'https://api2.blockbank.ai';

// TODO: index theme state for change UI
const whiteLabel = env.REACT_APP_CEFI_WHITE_LABEL || '';
const theme = whiteLabel;

const kycLevel = (): string => {
  if (theme === 'kaizen') {
    return 'KaizenCX';
  }
  return 'basic-kyc-level';
};

const favicon = (): string | null => {
  // @ts-ignore
  if (theme === 'fideum') {
    return fideumIco;
  }
  // @ts-ignore
  if (theme === 'kaizen') {
    return kaizenIco;
  }

  return null;
};

const documentTitle = (): string => {
  // @ts-ignore
  if (theme === 'fideum') {
    return 'Fideum - Web App';
  }
  // @ts-ignore
  if (theme === 'kaizen') {
    return 'Kaizencx - Web App';
  }

  return 'blockbank - Web App';
};

const qrName = (): string => {
  // @ts-ignore
  if (theme === 'fideum') {
    return 'Fideum';
  }
  // @ts-ignore
  if (theme === 'kaizen') {
    return 'KaizenCX';
  }

  return 'BlockBank';
};

const fideumMainColor = '#3D5CF5';
const kaizenMainColor = '#003EF5';
const bbMainColor = '#9A95CE';

const themeLogo = (): string | undefined => {
  // @ts-ignore
  if (theme === 'fideum') {
    return fideumLogo;
  }
  // @ts-ignore
  if (theme === 'kaizen') {
    return kaizenLogo;
  }
  return bbank;
};

const themeOnboardingLogo = (): string | undefined => {
  // @ts-ignore
  if (theme === 'fideum') {
    return fideumOnboardingLogo;
  }
  // @ts-ignore
  if (theme === 'kaizen') {
    return kaizenOnboardingLogo;
  }
  return undefined;
};

const themeGlobalColor = (): string => {
  // @ts-ignore
  if (theme === 'fideum') {
    return fideumMainColor;
  }
  // @ts-ignore
  if (theme === 'kaizen') {
    return kaizenMainColor;
  }
  return bbMainColor;
};

const termsOfUseLink = (): string => {
  // @ts-ignore
  if (theme === 'kaizen') {
    return 'https://files.kaizencx.com/TERMS%20&%20CONDITIONS%20-%20KAIZENCX.pdf';
  }
  return 'https://blockbank.ai/terms-of-use/';
};

export {
  REGEX,
  theme,
  themeGlobalColor,
  themeOnboardingLogo,
  themeLogo,
  favicon,
  documentTitle,
  API_BASE_URL,
  qrName,
  termsOfUseLink,
  kycLevel,
};
