import React from 'react';
import styles from '../styles.module.scss';
import CompleteIconBlurComponent from '../../CommonModalComponents/CompleteIconBlurComponent';

const Successfully: React.FC = () => {
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 50,
      }}
    >
      <CompleteIconBlurComponent />
      <div style={{height: 50}} />
      <div className={styles.completedText}>Exchange Successfully Completed</div>
    </div>
  );
};

export default Successfully;
