import React, {useEffect} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {observer} from 'mobx-react';
import Modal from 'react-modal';
import Router from './Router';
import {useStores} from '../stores';
import MainLoader from './common/MainLoader';
import MainSnackBar from './common/MainSnackBar';
import Layout from './Layout';
import {initApp} from '../stores/actions/init';
import {favicon, theme} from '../globalConstants';
// eslint-disable-next-line import/extensions
import {env} from '../env';
import {updateFavicon} from '../utils/updateFavicon';
import GlobalModals from './Pages/Modals';

Modal.setAppElement('#root');

const App = (): JSX.Element => {
  const {mainLoader, snackComponent, setIsMobileScreens} = useStores();
  console.log('env', env);

  const updateData = (): void => {
    console.log('---updateData---');
    initApp()
      .then(() => {
        setTimeout(() => {
          updateData();
        }, 40000);
      })
      .catch(error => console.log('ERROR-initApp', error));
  };

  useEffect(() => {
    if (favicon()) updateFavicon();

    updateData();
    setIsMobileScreens(window.innerWidth <= 767);

    function handleResize(): void {
      setIsMobileScreens(window.innerWidth <= 767);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='app' data-theme={theme}>
      <BrowserRouter>
        {snackComponent ? <MainSnackBar>{snackComponent}</MainSnackBar> : null}

        {mainLoader ? (
          <MainLoader />
        ) : (
          <GlobalModals>
            <Layout>
              <Router />
            </Layout>
          </GlobalModals>
        )}
      </BrowserRouter>
    </div>
  );
};

export default observer(App);
