import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

type Props = {
  children: JSX.Element;
};

const MainSnackBar: React.FC<Props> = ({children}) => {
  const [outClassName, setOutClassName] = useState(false);

  useEffect(() => {
    setOutClassName(false);

    const timeoutId = setTimeout(() => {
      setOutClassName(true);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [children]);

  return (
    <div
      className={classNames(styles.mainSnackBarWrap, children ? styles.fadeIn : '', outClassName ? styles.fadeOut : '')}
    >
      {children}
    </div>
  );
};

export default MainSnackBar;
