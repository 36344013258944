import classNames from 'classnames';
import React, {useState} from 'react';
import styles from './styles.module.scss';
import ErrorRedBlock from '../../../common/ErrorRedBlock';
import CardServices from '../../../../services/CardServices';
import {countriesFormatted} from '../../../../globalConstants/countries';

type Props = {
  submittedData: any;
  setActiveStep: React.Dispatch<any>;
  setIsSubmitted: React.Dispatch<any>;
};

const StepFive: React.FC<Props> = ({submittedData, setActiveStep, setIsSubmitted}) => {
  const errors = {checkBox: false};

  const [terms, setTerms] = useState([
    {
      title: 'I am not a politically exposed person',
      isChecked: false,
    },
    {
      title: 'I am not represented by someone else',
      isChecked: false,
    },
    {
      title: 'I am the beneficial owner of the card',
      isChecked: false,
    },
  ]);

  const [isSummary, setIsSummary] = useState(submittedData);
  const [loading, setLoading] = useState(false);
  const [requestError, setRequestError] = useState('');

  const allTermsIsTrue = !!submittedData || terms.every(term => term.isChecked);

  return (
    <div className={styles.stepContentContainer}>
      <div className={classNames(styles.freeHistoryDescription, styles.freeHistoryDescriptionFive)}>
        {isSummary
          ? 'Please review your details and submit your application.'
          : "We're all about transparency. Please review and agree to our terms before we proceed."}
      </div>

      {isSummary ? (
        <>
          <div className={styles.summaryBlock}>
            <div className={styles.summaryTitle}>Deposit</div>
            <div className={styles.summaryData}>
              {isSummary.amount} {isSummary.assetId} - <span className={styles.summaryConfirmed}>Confirmed</span>
            </div>
          </div>
          <div className={styles.summaryBlock}>
            <div className={styles.summaryTitle}>Country</div>
            <div className={styles.summaryData}>{countriesFormatted[isSummary.countryCode]}</div>
          </div>
          <div className={styles.summaryBlock}>
            <div className={styles.summaryTitle}>Address</div>
            <div className={styles.summaryData}>
              {isSummary.address1}
              {isSummary.address2 ? `${isSummary.address2}, ` : ', '}
              {isSummary.city}, {isSummary.postalCode}
            </div>
          </div>
          <div className={styles.summaryBlock}>
            <div className={styles.summaryTitle}>Mobile</div>
            <div className={styles.summaryData}>{isSummary.phone}</div>
          </div>
          <div className={styles.summaryBlock}>
            <div className={styles.summaryTitle}>Self-Check</div>
            <div className={styles.summaryData}>Completed</div>
          </div>
        </>
      ) : (
        terms.map(term => {
          return (
            <label key={term.title} htmlFor={term.title} className={classNames(styles.container)}>
              {term.title}
              <input
                checked={term.isChecked}
                id={term.title}
                type='checkbox'
                onChange={() => {
                  const newState = terms.map(item => {
                    if (item.title === term.title) {
                      return {...item, isChecked: !term.isChecked};
                    }
                    return item;
                  });
                  setTerms(newState);
                }}
              />
              <span style={errors.checkBox ? {outline: '1px solid red'} : {}} className={styles.checkmark} />
            </label>
          );
        })
      )}

      <button
        style={{marginTop: 80, opacity: allTermsIsTrue ? 1 : 0.5}}
        onClick={async e => {
          e.preventDefault();
          setLoading(true);
          if (isSummary) {
            try {
              await CardServices.submitOrderStatus({
                step: 'SUBMITTED',
                data: {},
              });
              setIsSubmitted(true);
              setActiveStep(0);
            } catch (error: any) {
              setRequestError(error.code || 'SUBMITTED Error');
              console.log('submitOrderStatus(TERMS)-SUBMITTED', error);
            }
          } else {
            try {
              const step5 = await CardServices.submitOrderStatus({
                step: 'TERMS',
                data: {
                  isRepresentedBySomeoneElse: false,
                  isBeneficialOwner: true,
                  isPoliticallyExposedPerson: false,
                },
              });
              setIsSummary(step5.additionalInfo);
            } catch (error: any) {
              setRequestError(error.code || 'TERMS Error');
              console.log('submitOrderStatus(TERMS)-ERROR', error);
            }
          }

          setLoading(false);
        }}
        className='btn btn-primary'
        disabled={!allTermsIsTrue}
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        {loading ? <span className='spinner-border' /> : isSummary ? 'Submit' : <div>Next</div>}
      </button>
      {requestError ? <ErrorRedBlock requestError={requestError} /> : null}
    </div>
  );
};

export default StepFive;
