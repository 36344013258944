import React, {useEffect, useState} from 'react';
import moment from 'moment';
import Spinner from '../../../common/Spinner';
import styles from './styles.module.scss';
import {getBalanceString} from '../../../../utils/formats';
import {IData} from '../../Modals/TransactionsModal';
import {TransactionsServices} from '../../../../services/TransactionsService';
import depositIcon from '../../../../assets/icons/deposit-icon.svg';
import withdrawalIcon from '../../../../assets/icons/withdrawal-icon.svg';
import exchangeIcon from '../../../../assets/icons/exchange-icon.svg';
import earningRewardIcon from '../../../../assets/icons/earning-reward-icon.svg';
import RewardsSVG from '../common/InfoCards/RewardsSVG';

type Props = {
  filterAssetId?: string;
};

const TransactionsList: React.FC<Props> = ({filterAssetId}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [historyData, setHistoryData] = useState<IData[] | null>(null);

  const getHistoryData = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const deposits = await TransactionsServices.getHistoryDeposit();
      const preparedDeposit: IData[] = deposits.map(deposit => ({...deposit, icon: depositIcon, title: 'Deposit'}));
      const withdraws = await TransactionsServices.getHistoryWithdraw();
      const preparedWithdraws: IData[] = withdraws.map(withdraw => ({
        ...withdraw,
        icon: withdrawalIcon,
        title: 'Withdrawal',
      }));
      const exchanges = await TransactionsServices.getHistoryExchange();
      const preparedExchanges: IData[] = exchanges.map(exchange => ({
        ...exchange,
        icon: exchangeIcon,
        title: 'Exchange',
      }));

      const rewards = await TransactionsServices.getHistoryReward();
      const preparedReward: IData[] = rewards
        .filter(reward => Number(reward?.amount) !== 0)
        .map(reward => {
          return {...reward, time: reward.rewardTime, icon: earningRewardIcon, title: 'Earning Reward'};
        });
      const resData = [...preparedDeposit, ...preparedWithdraws, ...preparedReward, ...preparedExchanges].sort(
        (a, b) => {
          // @ts-ignore
          return moment(b.time) - moment(a.time);
        }
      );

      setHistoryData(
        resData.filter(historyAsset => {
          return filterAssetId
            ? historyAsset.assetId === filterAssetId ||
                historyAsset.fromAssetId === filterAssetId ||
                historyAsset.toAssetId === filterAssetId
            : true;
        })
      );
    } catch (e) {
      console.log('ERROR-historyData', e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getHistoryData().catch(e => console.log('ERROR-getHistoryData', e));
  }, []);

  if (isLoading) {
    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={styles.transactionsListWrap}>
      {historyData && historyData.length ? (
        historyData.map(item => {
          return (
            <div className={styles.item}>
              <img src={item.icon} className={styles.icon} alt='' />
              <div>
                <div className={styles.itemTitle}>
                  {item.title} {item.fromAssetId ? `${item.fromAssetId} to ${item.toAssetId}` : ''}
                </div>
                <div className={styles.itemDate}>{moment(item.time).format('MMMM DD, YYYY')}</div>
              </div>
              <div className={styles.itemAmount}>
                {getBalanceString(Number(item.fromAmount || item.amount || 0), 6)} {item.assetId || item.fromAssetId}
              </div>
            </div>
          );
        })
      ) : (
        <div className={styles.transactionsBlock} style={{textAlign: 'center'}}>
          {/* Token Transaction’s is clear */}
          <div className={styles.freeHistoryContainer}>
            <RewardsSVG fill='var(--mainBlue)' />
            <div className={styles.freeHistory}>
              Your Transactions <br /> History will appear here
            </div>
            <div className={styles.freeHistoryDescription}>
              Once you start using the app, all recent transaction will be displayed here for your convenience.
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TransactionsList;
