import React from 'react';
import styles from './styles.module.scss';
import BackBtn from '../../Auth/common/BackBtn';
import TransactionsList from '../../Main/Transactions/TransactionsList';
import {useGlobalModalContext} from '../index';

export interface IData {
  amount?: number;
  assetId?: string;
  status?: string;
  time?: string;
  icon: string;
  title: string;

  fromAssetId?: string;
  fromAmount?: number;
  toAssetId?: string;
  toAmount?: number;
  rate?: number;

  rewardName?: string;
  rewardTime?: string;
}

const TransactionsModal: React.FC = () => {
  const {hideModal} = useGlobalModalContext();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <BackBtn backFn={hideModal} />
      </div>
      <div className={styles.main}>
        <div className={styles.title}>Recent Transactions</div>
        <TransactionsList />
        <div style={{minHeight: 50}}> </div>
      </div>
      <div className={styles.footer} />
    </div>
  );
};

export default TransactionsModal;
