/* eslint-disable no-unused-vars */
export enum Pages {
  Base = '/',
  SignIn = 'SignIn',
  SignUp = 'SignUp',
  ForgotPassword = 'ForgotPassword',
  ConfirmationCode = 'ConfirmationCode',
  TwoFactorAuthentication = 'TwoFactorAuthentication',
  KYC = 'KYC',
  EARN = 'Earn',
  BANKING = 'Banking',
  PORTFOLIO = 'Portfolio',
  SUPPORT = 'Support',
  SETTINGS = 'Settings',
  LOGOUT = 'Log Out',
  ISMOBILE = 'IsMobile',
  ASSET = 'Asset',
  OTC = 'OTC',
  STAKING = 'Staking',
  DELETE_ACCOUNT = 'Delete Account',
  TRANSACTIONS = 'Transactions',
}
