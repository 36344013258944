import React, {useEffect, useState} from 'react';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {NavLink, useNavigate} from 'react-router-dom';
import i18n from '../../../../localize';
import styles from '../styles.module.scss';
import {useStores} from '../../../../stores';
import I18n from '../../../common/I18n';
import {REGEX, theme} from '../../../../globalConstants';
import eye from '../../../../assets/icons/eye.svg';
import eyeOff from '../../../../assets/icons/eye-off.svg';
import AuthTitle from '../common/AuthTitle';
import {APP_ROUTES} from '../../../Router/constants';
import {Pages} from '../../../Router/types';
import {clearToken, save2faStatus, saveToken} from '../../../../utils/localStorageUtils';
import AuthService, {UserStatus} from '../../../../services/AuthService';
import {hash} from '../../../../utils/hash';
import AuthSnackComponent from '../common/AuthSnackComponent';
import {errorDescriptionHandler} from '../authUtils/errorDescriptionHandler';
import {initApp} from '../../../../stores/actions/init';
import {stakingContracts} from '../../../../stores/actions/stacking';
import {EVENT_NAMES, useAnalytics} from '../../../../services/Analytics';
import AuthLayout from '../AuthLayout';

type Inputs = {
  email: string;
  password: string;
};

const defaultValues = {
  email: '',
  password: '',
};

const SignIn: React.FC = () => {
  const {setUser, setSnackComponent, setIsBlockUpdate} = useStores();
  const {myLogEvent} = useAnalytics();

  useEffect(() => {
    setUser(null);
    clearToken();

    myLogEvent(EVENT_NAMES.WEB_SIGN_IN_FOCUSED);
  }, []);

  const {t} = i18n;
  const navigate = useNavigate();
  const methods = useForm<Inputs>({defaultValues});

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = methods;

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const toggleShowPassword = (): void => {
    setShowPassword(prevState => !prevState);
  };

  const onSubmit: SubmitHandler<Inputs> = async data => {
    setLoading(true);

    try {
      const response = await AuthService.signIn({
        passwordHash: hash(data.password),
        username: data.email.trim(),
        deviceType: 'web',
        appVersion: '0.01',
      });

      saveToken(response.token);
      save2faStatus(response['2fa']);

      if (response['2fa']) {
        setIsBlockUpdate(true);
        navigate(APP_ROUTES[Pages.TwoFactorAuthentication]);
      } else if (response.temporaryPassword) {
        // TODO: temporaryPassword
      } else {
        const userSelf = await AuthService.getSelf();
        await setUser(userSelf);

        await initApp();
        await stakingContracts();

        myLogEvent(EVENT_NAMES.WEB_LOGIN_SUCCESS);

        if (userSelf.status === UserStatus.KYCConfirmed) {
          navigate(APP_ROUTES[Pages.Base]);
        } else if (userSelf.status === UserStatus.Active) {
          navigate(APP_ROUTES[Pages.KYC]);
        } else if (userSelf.status === null) {
          await AuthService.resendConfirmationCode();

          navigate(APP_ROUTES[Pages.ConfirmationCode], {
            state: {email: data.email.trim(), passwordHash: hash(data.password)},
          });
        }
      }
    } catch (error: any) {
      console.log('ERROR-signIn', error);
      // TODO: title ?
      setSnackComponent(
        <AuthSnackComponent
          title='Email or Password Incorrect.'
          description={errorDescriptionHandler(error.code || '')}
        />
      );
    }

    setLoading(false);
  };

  return (
    <AuthLayout>
      <div className={styles.rightModule}>
        <div className={styles.formWrap}>
          <AuthTitle title='signIn.title' description='signIn.description' />
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='input-item-wrap'>
                <label htmlFor='email' className={`input-label ${errors.email ? 'text-error' : ''}`}>
                  {t('signIn.email')} {errors.email && errors.email.type === 'pattern' ? t('inputError.invalid') : ''}
                  {errors.email && errors.email.type === 'required' ? t('inputError.required') : ''}
                </label>
                <input
                  id='email'
                  type='text'
                  className='input-form'
                  style={errors.email ? {border: '1px solid red'} : {}}
                  placeholder={t('signIn.email.placeholder') || ''}
                  {...register('email', {
                    required: true,
                    pattern: {
                      value: REGEX.EMAIL_VALIDATION,
                      message: 'Invalid email address',
                    },
                  })}
                />
              </div>

              <div className='input-item-wrap'>
                <label htmlFor='password' className={`input-label ${errors.password ? 'text-error' : ''}`}>
                  {t('signIn.password')}{' '}
                  {errors.password && errors.password.type === 'required' ? t('inputError.required') : ''}
                </label>
                <div className='input-password-wrap'>
                  <input
                    id='password'
                    type={showPassword ? 'text' : 'password'}
                    className='input-form'
                    style={errors.password ? {border: '1px solid red'} : {}}
                    placeholder={t('signIn.password.placeholder') || ''}
                    {...register('password', {
                      required: true,
                    })}
                  />
                  <div onClick={toggleShowPassword}>
                    <img src={showPassword ? eyeOff : eye} alt='' className='icon-eye' />
                  </div>
                </div>

                <NavLink to={APP_ROUTES[Pages.ForgotPassword]} className='input-label form-link'>
                  <I18n tKey='signIn.forgotPassword' />
                </NavLink>
              </div>

              <button type='submit' className='btn btn-primary' disabled={loading}>
                {loading ? <span className='spinner-border' /> : <I18n tKey='signIn.title' />}
              </button>

              {theme === 'fideum' ? null : (
                <div className='input-item-wrap text-under-button'>
                  <p className='input-label text-center' style={{display: 'inline'}}>
                    {t('signIn.notAccount')}
                    <NavLink to={APP_ROUTES[Pages.SignUp]} className='input-label form-link'>
                      {t('signUp.title')}
                    </NavLink>
                  </p>
                </div>
              )}
            </form>
          </FormProvider>
        </div>
      </div>
    </AuthLayout>
  );
};

export default SignIn;
