import React, {useState} from 'react';
import classNames from 'classnames';
import {UseFormReturn} from 'react-hook-form';
import styles from '../styles.module.scss';
import {ExchangeInputs} from '../Exchange/Exchange';
import {getBalanceString} from '../../../../../utils/formats';
import {Currencies, useStores} from '../../../../../stores';
import ChangeIcon from '../../../../../assets/icons/ChangeIcon';

type Props = {
  asset: any;
  changeAmountCurrency: () => void;
  isCurrency: boolean;
  currencyAmount: string;
  cryptoAmount: string;
  direction: 'from' | 'to';
  setFocusName: React.Dispatch<string>;
  methods: UseFormReturn<ExchangeInputs>;
  isEurFixed?: boolean;
};

const AmountInput: React.FC<Props> = ({
  asset,
  changeAmountCurrency,
  isCurrency,
  currencyAmount,
  cryptoAmount,
  direction,
  setFocusName,
  methods,
  isEurFixed,
}) => {
  const {currency} = useStores();

  const {
    register,
    formState: {errors},
    setValue,
  } = methods;

  const [isFocusAmount, setIsFocusAmount] = useState(false);

  const symbol = (): string => {
    if (isEurFixed && isCurrency) return 'EUR';
    if (isCurrency) {
      return currency === Currencies.USD ? 'USD' : 'EUR';
    }
    return asset.symbol;
  };

  const handleInputChange = (e: any, name: string): void => {
    const cleanedValue = e.target.value.replace(/[^0-9.,]/g, '');
    const sanitizedValue = cleanedValue.replace(',', '.').replace(/(\..*)\./g, '$1');
    // @ts-ignore
    setValue(name, sanitizedValue);
  };

  return (
    <div>
      <div
        className={classNames(styles.inputBlock, isFocusAmount ? styles.focusAmount : '')}
        style={
          errors[`${direction}AmountCurrency`] ||
          errors[`${direction}AmountCrypto`] ||
          (direction === 'from' && +cryptoAmount > +asset.totalBalance)
            ? {outlineColor: 'red'}
            : {}
        }
      >
        <div className={styles.assetName}>{symbol()}</div>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
          {isCurrency ? (
            <>
              <div onClick={changeAmountCurrency} className={styles.currencyBlock}>
                ≈ {asset.id} {cryptoAmount ? getBalanceString(+cryptoAmount, 8) : '0'}
                &nbsp;
                <ChangeIcon fill='var(--mainBlue)' width={9} height={9} />{' '}
              </div>
              <input
                placeholder='00.00'
                className={styles.input}
                onInput={e => handleInputChange(e, `${direction}AmountCurrency`)}
                onFocus={() => {
                  setIsFocusAmount(true);
                  setFocusName(`${direction}AmountCurrency`);
                }}
                {...register(`${direction}AmountCurrency`, {
                  required: true,
                  onBlur: () => {
                    setIsFocusAmount(false);
                    setFocusName('');
                  },
                })}
              />
            </>
          ) : (
            <>
              <input
                placeholder='00.00'
                className={styles.input}
                onInput={e => handleInputChange(e, `${direction}AmountCrypto`)}
                onFocus={() => {
                  setIsFocusAmount(true);
                  setFocusName(`${direction}AmountCrypto`);
                }}
                {...register(`${direction}AmountCrypto`, {
                  required: true,
                  onBlur: () => {
                    setIsFocusAmount(false);
                    setFocusName('');
                  },
                })}
              />
              <div onClick={changeAmountCurrency} className={styles.currencyBlock}>
                ≈ {isEurFixed ? Currencies.EUR : currency} {currencyAmount ? getBalanceString(+currencyAmount, 2) : '0'}
                &nbsp;
                <ChangeIcon fill='var(--mainBlue)' width={9} height={9} />{' '}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AmountInput;
