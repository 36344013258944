import moment from 'moment';
import classNames from 'classnames';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import styles from '../styles.module.scss';
import {getBalanceString} from '../../../../../utils/formats';
import ProgressBar from '../ProgressBar';
import Hint from '../Hint';
import {useStores} from '../../../../../stores';
import {StakingContract, StakingServices} from '../../../../../services/StakingService';
import {stakingContracts} from '../../../../../stores/actions/stacking';
import {useGlobalModalContext} from '../../../Modals';
import {MODAL_TYPES} from '../../../Modals/modalConstants';
import {APP_ROUTES} from '../../../../Router/constants';
import {Pages} from '../../../../Router/types';

type Props = {
  stake: StakingContract;
};

const EarnRow: React.FC<Props> = ({stake}) => {
  const {assets, currency, isMobileScreens} = useStores();

  const navigate = useNavigate();
  const {showModal, setModalStore} = useGlobalModalContext();

  const asset = assets.find(assetItem => assetItem.assetId === stake.assetId);
  const stakeDays = moment(stake.expectedCloseDate).diff(stake.openDate, 'days');
  const daysDiffToday = moment(stake.expectedCloseDate).diff(moment(), 'days');
  const daysLeft = daysDiffToday >= 0 ? daysDiffToday : 0;
  const daysLeftPercent = daysDiffToday >= 0 ? (daysDiffToday / stakeDays) * 100 : 0;

  const [isLoading, setIsLoading] = useState(false);

  const handleClaim = async (stakeItem: StakingContract): Promise<void> => {
    let claimService = StakingServices.createSimpleClaim;
    if (stakeItem.isRollingLeveled) claimService = StakingServices.createRollingClaim;
    if (stakeItem.isRollingResponses) claimService = StakingServices.createRollingClaimOld;
    setIsLoading(true);
    try {
      await claimService({contractId: stakeItem.id.toString()});
      await stakingContracts();
    } catch (e) {
      console.log('ERROR-handleClaim', e);
    }
    setIsLoading(false);
  };

  const [isOpenHint, setIsOpenHint] = useState(false);

  const handleOpenHint = (): void => {
    setIsOpenHint(true);
    setTimeout(() => {
      setIsOpenHint(false);
    }, 3000);
  };

  const goToEarnPage = (): void => {
    const options = {
      stake,
      daysLeftPercent,
      stakeDays,
      daysLeft,
      isLoading,
      handleClaim,
      title: Pages.EARN,
    };
    if (isMobileScreens) {
      setModalStore(options);
      navigate(`${APP_ROUTES[Pages.EARN]}/${MODAL_TYPES.INDIVIDUAL_EARN_MODAL}`);
    } else {
      showModal(MODAL_TYPES.INDIVIDUAL_EARN_MODAL, options);
    }
  };

  return (
    <>
      <div onClick={goToEarnPage} className={styles.rowMobile}>
        <div style={{display: 'flex', alignItems: 'center', flexGrow: 1}}>
          <img style={{width: 48, height: 48, borderRadius: 5, marginRight: 11}} src={asset?.icon} alt='' />
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              flexGrow: 1,
            }}
          >
            <div className={styles.assetName}>{stake.assetId}</div>
            <ProgressBar value={daysLeftPercent} />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'end',
            marginLeft: 10,
          }}
        >
          <div>{getBalanceString(+stake.amount, 8)}</div>
          <div style={{fontSize: 11, color: '#858EAA', fontWeight: 400}}>
            Interest Paid {getBalanceString(+stake.expectedRewardAmount, 2)}
          </div>
        </div>
      </div>
      <div className={classNames(styles.row)}>
        <div className={classNames(styles.cell, styles.cell3)}>
          <img style={{width: 46, height: 46, borderRadius: 5}} src={asset?.icon} alt='' />
          <div className={styles.assetName}>{stake.assetId}</div>
        </div>
        <div style={{flexGrow: 1}} />
        <div className={classNames(styles.cell, styles.cell1)}>
          {getBalanceString(+stake.amount, 8)} {stake.assetId}
        </div>
        <div style={{flexGrow: 1}} />
        <div className={classNames(styles.cell, styles.cell4)}>
          {currency}
          {asset && getBalanceString(+stake.amount * asset.rate.value, 2)}
        </div>
        <div style={{flexGrow: 1}} />
        <div className={classNames(styles.cell, styles.cell5)}>{getBalanceString(+stake.stakingApyPercent, 2)}%</div>
        <div style={{flexGrow: 1}} />
        <div className={classNames(styles.cell, styles.cell3)}>{getBalanceString(+stake.expectedRewardAmount, 8)}</div>
        <div style={{flexGrow: 1}} />
        <div className={classNames(styles.cell, styles.cell1)} style={{display: 'flex', alignItems: 'center'}}>
          <div>{stakeDays}&nbsp;Days</div>
          <ProgressBar value={daysLeftPercent} />
        </div>
        <div style={{flexGrow: 1}} />
        <div className={classNames(styles.cell, styles.cell4)}>{daysLeft} Days</div>
        <div style={{flexGrow: 1}} />
        <div className={classNames(styles.cell, styles.cell2)}>
          <div
            onMouseEnter={() => {
              return daysLeft ? handleOpenHint() : null;
            }}
            onMouseLeave={() => {
              return daysLeft ? setIsOpenHint(false) : null;
            }}
            className={styles.actionBtn}
            style={daysLeft ? {backgroundColor: '#ECECED', cursor: 'default'} : {}}
            onClick={daysLeft ? () => handleOpenHint() : () => handleClaim(stake)}
          >
            {isLoading ? <span className='spinner-border' /> : 'Unlock & Claim'}
            {isOpenHint ? <Hint /> : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default EarnRow;
