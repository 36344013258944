import React from 'react';
import QRCode from 'react-qr-code';
import styles from './styles.module.scss';
import Copied from './Copied';
import {useStores} from '../../../../stores';
import {DepositInfo} from '../../../../services/AssetsService';
import CopySvgComponent from '../../../../assets/icons/CopySvgComponent';

type QRCodeProps = {
  dataString: string;
  isDeposit?: DepositInfo;
  stringCode?: string;
  copiedAction?: () => void;
};

const QRCodeBlock: React.FC<QRCodeProps> = ({dataString, isDeposit, stringCode, copiedAction}) => {
  const {setSnackComponent} = useStores();

  return (
    <div className={styles.qrWrap}>
      <div className={styles.qrContent}>
        <QRCode
          size={256}
          style={{height: 'auto', maxWidth: '100%', width: '100%'}}
          value={dataString}
          viewBox='0 0 256 256'
        />
      </div>

      <div className={styles.qrText}>
        <div className={styles.addressText}>{stringCode || dataString}</div>
        <div
          style={{cursor: 'pointer'}}
          onClick={() => {
            navigator.clipboard.writeText(dataString).then(() => {
              if (copiedAction) {
                copiedAction();
              } else {
                setSnackComponent(<Copied />);
              }
            });
          }}
        >
          <div style={{marginRight: 15}}>
            <CopySvgComponent fill='var(--mainBlue)' />
          </div>
        </div>
      </div>
      {isDeposit && (
        <div className={styles.note}>
          <div style={{fontWeight: 700}}>Note</div>: Please ensure you are depositing asset using {isDeposit.networkId}{' '}
          network.
        </div>
      )}
    </div>
  );
};

export default QRCodeBlock;
