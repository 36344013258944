import React from 'react';
import styles from './styles.module.scss';
import Spinner from '../Spinner';

const MainLoader = (): JSX.Element => {
  return (
    <div className={styles.spinnerWrap}>
      <Spinner />
    </div>
  );
};

export default MainLoader;
